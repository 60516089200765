import React from 'react';
import empleados from '~/assets/images/empleados.webp';
import { TrabajoContainer } from './styled';
import { Button, Grid, Loading, TextField, TextFieldPhoneNumber, TextFieldSelect, Title, Typography } from '~/components';
import useTrabajo from '~/hooks/useTrabajo';
import { handleGoToVacantesDisponibles } from '~/utils';
import { DoneContainer } from '~/components/Done/styled';
import Correct from '~/assets/icons/correct.webp';
import { useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';

const Trabajo : React.FC = () => {
	const {
		handleNombres,
		nombres,
		nombresCheck,
		edad,
		handleEdad,
		numeroCelular,
		handleNumeroCelular,
		numeroCelularCheck,
		genero,
		dataGeneros,
		dataCiudades,
		edadCheck,
		ciudad,
		handleCiudad,
		handleGenero,
		generoCheck,
		disabledEnviar,
		handleEnviar,
		ciudadCheck,
		loading,
		done
	} = useTrabajo();

	useTitle(PAGINAS.TRABAJO);

	if(!dataCiudades){
		return <Loading></Loading>;
	}

	return (
		<TrabajoContainer>
			<img loading="lazy" draggable="false" className='imgEmpleado' alt='Imágen Empleados' src={empleados}></img>
			<div className='title'>
				<Title color='white' variant='h10'>ENCUENTRA LAS VACANTES AQUÍ</Title>
			</div>
			<Grid maxwidth='311px' container direction='column' width='100%'>

				<Button fullWidth onClick={handleGoToVacantesDisponibles} variant='solid'>
					Ver vacantes de empleo
				</Button>
			</Grid>

			{
				done ? <DoneContainer>
					<img loading="lazy" draggable="false" alt='Correcto' width='75px' src={Correct}></img>
					<Title color='darkGrey' padding="15px 0 10px 0" variant='h5'>{'¡Formulario enviado!'}</Title>
					<Typography padding="0 0 125px 0" color='disabledFontGrey' variant='subtitle2'>Pronto nos contactaremos contigo.</Typography>
				</DoneContainer> : <>
					<Typography padding='56px 0 15px 0' variant='subtitle1bold'>
						¿Te gustaría trabajar con nosotros?
					</Typography>

					<Typography variant='body2'>
						¡Llena este formulario para que podamos contactarte!
					</Typography>
            
					<Grid maxwidth='341px' container direction='column' width='100%'>
						<TextField placeholder='Ingresa tu nombre' margin="40px 0 3px 0"  {...nombresCheck} onChange={handleNombres} value={nombres} label='Nombre(s)'></TextField>

						<TextField {...edadCheck} placeholder='Ingresa tu edad' type='number' margin="0 0 3px 0"  onChange={handleEdad} value={edad} label='Edad'></TextField>

						<TextFieldPhoneNumber 
							edit={true}
							fullWidth
							padding='0 0 3px 0'
							{...numeroCelularCheck} 
							value={numeroCelular} 
							onChange={handleNumeroCelular} 
							label='Número celular'
							countries={['MX']}
						>
						</TextFieldPhoneNumber>

						<TextFieldSelect fullWidth
							padding='0 0 8px 0'
							{...generoCheck}
							value={genero}
							edit
							onChange={handleGenero}
							id="genero"
							items={dataGeneros}
            
							label='Género'></TextFieldSelect>

						<TextFieldSelect
							padding='0 0 8px 0'
							label='Elige la ciudad'
							{...ciudadCheck}
							id='sucursalSelect'
							items={dataCiudades || []} 
							value={ciudad || undefined}
							onChange={handleCiudad}
						/>

						<Button loading={loading} disabled={disabledEnviar} margin='22px 0 140px 0' onClick={handleEnviar} variant='solid' color='black'>
							Enviar
						</Button>
					</Grid>
				</>
			}

		</TrabajoContainer>
	);
};
export default (Trabajo);