// Responsive
import React, { useContext, useEffect } from 'react';
import { Button, CardMedia, DateInput, Grid, TextField, TextFieldPhoneNumber, TextFieldSelect, Typography, WarningModal } from '~/components';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useDialog, useRegistro, useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';
import { validateDateFechaNacimiento } from '~/utils/validations';
import moment from 'moment';
import { Badge } from '~/components/Badge';
import { ProfileNotVerified } from './styled';
import EscudoExclamacion from '~/assets/icons/shieldExclamacion.png';

const AjustesDeCuenta : React.FC = () => {

	const { handleShowBack, handleGoBack } = useContext(GeneralLayoutContext);

	useEffect(() => {
		handleShowBack('/', 'Volver a tienda', 'Mi usuario', true);
	}, []);

	const {
		handleGetUserInfo,
		nombresCheck,
		apellidoPaterno,
		handleNombres,
		loading,
		nombres,

		email,
		handleEmail,
		emailCheck,

		isEmailVerified,
		handleSendVerificationEmail,
		isSendingVerificationEmail,
		hasTouchedEmail,

		apellidoPaternoCheck,
		handleApellidoPaterno,
		apellidoMaterno,
		apellidoMaternoCheck,
		handleApellidoMaterno,
		fechaDeNacimientoCheck,
		handleFechaDeNacimiento,
		fechaDeNacimiento,
		generoCheck,
		genero,
		handleGenero,
		dataGeneros,
		numeroCelularCheck,
		numeroCelular,
		handleNumeroCelular,
		disabledModificar,
		handleModificarInfoUsuario,
	} = useRegistro(true);

	const {
		open: openEliminarCuenta,
		handleOpen: handleOpenEliminarCuenta,
		handleClose: handleCloseEliminarCuenta
	} = useDialog();

	useTitle(PAGINAS.PERFIL);

	useEffect(() => {
		handleGetUserInfo();
	}, [dataGeneros]);

	return (
		<>
			<WarningModal 
				width='341px' 
				height='auto'
				loading={loading} 
				title='Eliminar cuenta'
				description='¿Seguro que deseas eliminar tu cuenta?'
				handleAcept={()=>handleModificarInfoUsuario(true)} 
				open={openEliminarCuenta} 
				handleClose={handleCloseEliminarCuenta} 
			/>

			<form onSubmit={(e) => e.preventDefault()} autoComplete='on'>
				<Grid container justifyContent='center'>
					<Grid container direction='column' width='100%' maxwidth="395px">
						<Grid container direction='column' width='100%' padding='16px 24px' spacing='16px' borderbottom>
							<Typography variant='subtitle2regular'>Datos de contacto</Typography>
	
							<Grid container direction='column'>
								<TextFieldPhoneNumber 
									fullWidth
									padding='10px 0 0 0'
									{...{...numeroCelularCheck, check: false}} 
									value={numeroCelular} 
									countries={['MX']}
									onChange={handleNumeroCelular} 
									disabled
									label='Número celular'
								/>
	
								<TextField 
									fullWidth 
									edit
									padding='10px 0 0 0' 
									disabled={loading} 
									{...emailCheck} 
									onChange={handleEmail} 
									value={email} 
									label='Correo electrónico'
									customBadge={
										<Grid alignSelf='center'>
											{
												hasTouchedEmail ? <></> : isEmailVerified ? (
													<Badge colorPalette='green' variant='subtle' description='Correo verificado' />
												) : (
													<ProfileNotVerified>
														<CardMedia src={EscudoExclamacion} staticImg maxwidth='10px' />
	
														<Typography color='gray_600' variant='overline'>Verifica tu correo</Typography>
													</ProfileNotVerified>
												)
											}
										</Grid>
									}
								/>
	
								{(!isEmailVerified && !hasTouchedEmail) ? (
									<Button 
										variant='solid' 
										type='button' 
										disabled={emailCheck.error || hasTouchedEmail} 
										loading={isSendingVerificationEmail || loading} 
										onClick={(e) => {
											e?.preventDefault();

											handleSendVerificationEmail();
										}} 
										margin='24px 0 0 0'
									>
										Verificar
									</Button>
								) : <></>} 
							</Grid>
						</Grid>
	
						<Grid container direction='column' width='100%' padding='16px 24px' spacing='16px'>
							<Typography variant='subtitle2regular'>Datos personales</Typography>
	
							<TextField 
								fullWidth 
								edit
								noIcon
								disabled={loading}  
								{...nombresCheck} 
								onChange={handleNombres} 
								value={nombres} 
								label='Nombre(s)'
								inputId="first-name" 
								name="first-name"
								autoComplete={'given-name'}
							/>
	
							<TextField 
								fullWidth 
								edit 
								noIcon 
								disabled={loading} 
								{...apellidoPaternoCheck} 
								onChange={handleApellidoPaterno} 
								value={apellidoPaterno} 
								label='Apellido Paterno'
								inputId="last-name" 
								name="last-name" 
								autoComplete="family-name"
							/>
	
							<TextField 
								fullWidth 
								edit 
								noIcon 
								disabled={loading} 
								{...apellidoMaternoCheck}  
								value={apellidoMaterno} 
								label='Apellido Materno' 
								onChange={handleApellidoMaterno}
								id="second-last-name" 
								name="second-last-name" 
								autoComplete="family-name"
							/>
	
							<DateInput 
								edit
								value={fechaDeNacimiento}
								handleValue={handleFechaDeNacimiento}
								label='Fecha de nacimiento'
								validateDate={validateDateFechaNacimiento}
								dFechasAPartirDe={moment().subtract(13, 'year').format('YYYY-MM-DD')}
								disabled={loading}
								bDayAutoComplete
								{...fechaDeNacimientoCheck}
							/>
	
							<TextFieldSelect 
								fullWidth
								padding='0px'
								{...generoCheck}
								value={genero}
								edit
								onChange={handleGenero}
								id="genero"
								disabled={loading}
								items={dataGeneros}
								label='Género'
							/>
						</Grid>
	
						<Grid container direction='column' padding='16px 24px' spacing='32px'>
							<Grid container direction='row' maxheight='56px' flexWrap='nowrap' spacing='8px'>
								<Button 
									loading={loading} 
									type='submit' 
									onClick={(e)=> {
										e?.preventDefault(); 
										handleModificarInfoUsuario();
									}} 
									disabled={disabledModificar} 
									variant='solid' 
									fullWidth
								>
									Actualizar datos
								</Button>
	
								<Button onClick={handleGoBack} variant='outline' fullWidth>
									Cancelar
								</Button>
							</Grid>
	
							<Button onClick={handleOpenEliminarCuenta} variant='underline'>
								Eliminar mi cuenta
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</>
	);
};

export default (AjustesDeCuenta);