import React, {useContext, useEffect} from 'react';
import { FavoriteIcon, UnfavoriteIcon } from '~/assets/icons';
import { useCheck, useDialog, useFirstVisible } from '~/hooks';
import { ProductosProps } from '~/interfaces';
import { getCdnUrl, helperViewItem, pricePrettify } from '~/utils';
import CardMedia from '../CardMedia';
import Grid from '../Grid';
import IconButton from '../IconButton';
import Paragraph from '../Paragraph';
import Typography from '../Typography';
import { ProductCardContainer, ProductCardContainerMain, ViewIconContainer, PopularTag, DiscountTag, TagsContainer, YellowTag } from './styled';
import { CookiesContext } from '~/context/cookiesContext';
import WarningSobrePedido from '../WarningSobrePedido';

type Props = {
	item: ProductosProps,
	hide?: boolean,
	handleLike: (value: number, handleDone?: VoidFunction) => void,
	handleRemove?: (value: number) => void,
	handleClickItem: () => void,
	bAnticipacion: boolean
	item_list: string
};
const ProductCard : React.FC<Props> = ({ item, handleClickItem, hide, handleLike, handleRemove, bAnticipacion, item_list } : Props) => {
	const {
		check,
		handleCheck
	} = useCheck(!!(item.bFavorito));

	const {
		handleClose,
		handleOpen,
		open
	} = useDialog();

	const handleFavorite = () => {
		handleLike(item.nVProducto);
		handleCheck(!check);
		if(handleRemove){
			setTimeout(()=>{
				handleRemove(item.nVProducto);
				handleCheck(true);
			}, 500);
		}
	};

	const {
		userInfo,
	} = useContext(CookiesContext);
	
	const isPopular = item.bPopular;
	const disabled = !item.bDisponible;
	const textColor = disabled ? 'disabledFontGrey' : 'black';

	const { containerRef, hasBeenSeen } = useFirstVisible();

	useEffect(() => {
		if (hasBeenSeen && !hide) {
			helperViewItem(item, item_list);
		}
	}, [hasBeenSeen]);

	const showSobrePedidoTag = bAnticipacion && !disabled;
	const showDescuentoTag = !!item.bPrecioEspecial && !disabled;
	const showNuevoTag = !!item.bNuevo && !disabled;
	const showPopularTag = isPopular || disabled;

	return (
		<>
			<ProductCardContainerMain ref={containerRef} disabled={disabled} className='noselect' hide={hide ? !check : false}>
				<ProductCardContainer disabled={disabled} onClick={disabled ? undefined : bAnticipacion ? handleOpen : handleClickItem}>
					<Grid container height='172px' flexShrink={0} borderRadius='8px' overflow='hidden' position='relative'>
						<CardMedia 
							alt={item?.cDescripcion} 
							disabled={disabled} 
							alignItems="flex-start" 
							height="100%"
							src={Array.isArray(item.cImagenUrl) ? getCdnUrl(item?.cImagenUrl[0]) : item.cImagenUrl ? getCdnUrl(item.cImagenUrl) : ''} 
						/>
						
						{
							(!userInfo?.invitado) && <ViewIconContainer checked={check}>
								<IconButton onClick={handleFavorite} size="42px" padding='0px' containerPadding='0px' bgColor="whiteAlpha_500">
									<div className='icon'>
										<div className='check'>
											<FavoriteIcon color='primary'></FavoriteIcon>
										</div>
										<div className='uncheck'>
											<UnfavoriteIcon />						
										</div>
									</div>
								</IconButton>
							</ViewIconContainer>
						}

						<TagsContainer>
							{
								showSobrePedidoTag ? <Grid container>
									<YellowTag>Sobre pedido</YellowTag>
								</Grid> : null
							}

							{
								showDescuentoTag ? <Grid container><DiscountTag>Con descuento</DiscountTag></Grid> : null
							}

							<PopularTag show={!!showNuevoTag}>
								<Typography color='white' variant='caption'>
									Nuevo
								</Typography>
							</PopularTag>

							<PopularTag disabled={disabled} show={showPopularTag}>
								<Typography color='white' variant='caption'>
									{disabled ? 'No disponible' : 'Popular'}
								</Typography>
							</PopularTag>
						</TagsContainer>
					</Grid>

					<Grid margin='8px 0 0 0' container direction='column' flex height='100%' flexShrink={1}>
						<Grid item flex direction='column' container>
							<Typography color={textColor} variant='subtitle1bold'>
								{`${item.cDescripcion} `}
							</Typography>

							<Grid item xs>
								<Paragraph color={textColor} className='paragraphContainer' margin='0px' numLines={2} variant='caption'>
									{item.cDetalle}
								</Paragraph>
							</Grid>
						</Grid>
					</Grid>

					<Grid item margin='8px 0 0 0' container flexShrink={0} justifyContent='flex-end'>
						{
							!!(item?.bPrecioEspecial && item.nPrecio_Base_Original && item.nProductoSIP) && <Typography textAlign='right' padding='0 16px 0 0' variant='line' color={textColor}>
								{pricePrettify(item.nPrecio_Base_Original)}
							</Typography>
						}
						
						<Typography color={textColor} textAlign='right' variant='subtitle1bold'>{item.nPrecio_Base ? (`${pricePrettify(item.nPrecio_Base)} ${item.nProductoSIP || !item.nPrecio_Maximo ? '' : `- ${pricePrettify(item.nPrecio_Maximo)}`}`) : ''}</Typography>
					</Grid>
				</ProductCardContainer>
			</ProductCardContainerMain>

			{
				bAnticipacion && (
					<WarningSobrePedido 
						nDiasAnticipacion={item.nDiasAnticipacion} 
						cDiasDisponibles={item.cDiasDisponibles}
						open={open} 
						handleClose={handleClose} 
						handleClickItem={handleClickItem} 
					/>
				)
			}
		</>

	);
};
export default (ProductCard);