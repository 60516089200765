import styled from 'styled-components';
import colors from '~/styles/colors';

export interface TextFieldProps {
  open?: boolean;
  icon?: boolean;
  error?: boolean;
  check?: boolean;
}
export interface TextFieldContainerInputProps {

  padding?: string;
}

export const TextFieldInput = styled.input<TextFieldProps>`
    padding: 10px 12px 10px 12px;
    border: none !important;
    border-radius: ${props => props.icon ? '7px 0px 0px 7px' : '7px'};
    flex: auto;
    font-family: ${props =>props.theme.text.font.primary};
    font-size: ${props => props.theme.text.size.subtitle1};

    background-color: transparent;
    &:focus {
      outline: none;
    }
    width: 100%;
`;


export const IconContainer = styled.div<TextFieldProps>`
    border-radius: ${props => props.icon ? '7px 0px 0px 7px' : '7px'};
    padding: 10px 12px 10px 12px;


`;
const border = `1px solid ${colors.grey};`;
export const TextFieldContainerInput = styled.div<TextFieldProps>`
    display: flex;
    background-color: ${props => props.theme.colors.white};
    ${props => props.icon ? `
      border-top: ${border}
      border-bottom: ${border}
      border-right: ${border}
    ` : `
      border: ${border}
    `};
    border-radius: ${props => props.icon ? '7px 0px 0px 7px' : '7px'};

    ${props => `
      border: ${props.error || props.check ? '2px' : '1px'} solid ${colors[props.error ? 'red_500' : props.check ? 'green' : 'grey']};
    `};
`;

export const TextFieldContainer = styled.div<TextFieldContainerInputProps>`
    flex: 1;
    display: flex;
    flex-direction: column;
    

    padding: ${props => props.padding};



`;
