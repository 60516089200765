// responsive
import React, { Fragment, useContext, useState } from 'react';
import { Banners, Container, DetalleProductoModal, Grid, ListadoProductos, ListadoTitle, ListadoVerMasProductos, Loading, NivelesCard, OrdenesActivasCard } from '~/components';
import ListadoHorizontalProductos from '~/components/ListadoHorizontalProductos';
import { useDetalleProductoModal, useOrdenesActivas, useTitle } from '~/hooks';
import useMain from '~/hooks/useMain';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { getCdnUrl } from '~/utils';
import { PAGINAS } from '~/interfaces';

const Main : React.FC = () => {
	const {
		nivelesTienda,
		secciones,
		bannersData,
	} = useMain();

	const {
		openDetalleProducto,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleLike,
		handleClick,
		handleDataCarrito,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		handleNivelMainId,
		handleOrdenar,
		detalleEnvio,
		loadingCheckoutCarrito,
	} = useDetalleProductoModal();

	const {
		ordenesActivas
	} = useOrdenesActivas();

	const {
		xs,
	} = useContext(GeneralLayoutContext);

	useTitle(PAGINAS.INICIO);

	const [categoriaSelected, setCategoriaSelected] = useState('');

	if(!secciones || !bannersData || !ordenesActivas){
		return <Loading />;
	}

	return (
		<>

			{/* <InfoModal /> */}
			<Container responsivePadding paddingBottom="137px">
				<Grid container spacing={xs ? '1rem' : '6px'} padding='20px 0 20px 0'>
					{
						nivelesTienda?.map((nivel, i) => (
							<NivelesCard last={nivelesTienda && (nivelesTienda?.length % 2) === 1 && (i + 1) === nivelesTienda.length && !xs} xs={xs} nNivelTienda={nivel.nNivelTienda} bHorarioDisponible={nivel.bHorarioDisponible} key={i} src={getCdnUrl(nivel?.cImagenUrl)} label={nivel.cDescripcion}/>
						))
					}
				</Grid>

				<Banners bannersData={bannersData} />

				<OrdenesActivasCard xs={xs} ordenesActivas={ordenesActivas}/>

				{
					secciones && <>
						{
							secciones.map((seccion, i) => (
								<Fragment key={i}>
									{seccion.nTipoSeccion !== 1 && <ListadoTitle
										titleLabel={seccion.cDescripcion}
										subtitleLabel={seccion.cNivelTienda}
										xs={xs}
									/>}

									{
										seccion.nTipoSeccion === 1 ? (
											<ListadoHorizontalProductos 
												title={seccion.cDescripcion}
												subTitle={seccion.cNivelTienda}
												handleClick={(id)=>{
													setCategoriaSelected(seccion.cDescripcion);
													handleClick(id, seccion.nNivelTienda);
												}}
												productos={seccion.VProductos}
												item_list={seccion.cDescripcion}
											/> 
										) : seccion.nTipoSeccion === 2 ? (
											<ListadoProductos 
												key={i}
												handleLike={handleLike}
												handleClick={(id)=>{
													setCategoriaSelected(seccion.cDescripcion);
													handleClick(id, seccion.nNivelTienda);
												}}
												productos={seccion.VProductos} 
												item_list={seccion.cDescripcion}
											/>
										) : (
											<ListadoVerMasProductos 
												goTo={seccion.cUrlClick}
												handleClick={(id)=>{
													setCategoriaSelected(seccion.cDescripcion);
													handleClick(id, seccion.nNivelTienda);
												}}
												productos={seccion.VProductos} 
												item_list={seccion.cDescripcion}
											/>
										)
									}
								</Fragment>
							))
						}
						<DetalleProductoModal 
							openDetalleProducto={openDetalleProducto}
							productoId={productoId}
							handleOpenDetalleProductoModal={handleOpenDetalleProductoModal}
							handleProductoId={handleProductoId}
							handleClick={handleClick}
							handleDataCarrito={handleDataCarrito}
							handleModificaCarrito={handleModificaCarrito}
							carritoLoading={carritoLoading}
							nivelMainId={nivelMainId}
							handleNivelMainId={handleNivelMainId}
							handleOrdenar={handleOrdenar}
							detalleEnvio={detalleEnvio}
							loadingCheckoutCarrito={loadingCheckoutCarrito}
							itemList={categoriaSelected}
						/>  
					</>
				}

			</Container>
		</>
	);
};
export default (Main);