import styled from 'styled-components';

export interface CheckoutContainerProps {
  showSideBarIcon?: boolean;
  padding?: string;
}

export const CheckoutContainer = styled.div<CheckoutContainerProps>`
    display: flex;
    background-color: ${props => props.theme.colors.bgColor};
    justify-content: center;

    .leftSide {
      border-right: 1px solid ${props => props.theme.colors.gray_100};
    }
    
    .footer {
      width: 100%;
      display: flex;
    }

    @media (max-width: 952px) {
        height: auto;
        .leftSide {
          border-right: none;
        }
    }

    .item-checkout-container.leftSide {
      padding: 24px 14px 0px 29px;
      max-width: 398px;
    }


    .item-checkout-container {
      display: flex;
      padding: 32px 32px 0px 32px;
      flex-direction: column;
      max-width: 418px;
      width: 100%;
    }

    @media (max-width: 952px) {
      align-items: center;
      flex-direction: column;
      padding-bottom: 96px;
    }

    @media (max-width: 952px) {
      .item-checkout-container {
        padding: 8px 22px 0px 22px;
        max-width: 418px;
      }

      .item-checkout-container.leftSide {
        max-width: 418px;
        padding: 0px 22px;
      }
    }

    .pointer {
      cursor: pointer;
    }

`;

export interface RoundCardContainerProps {
  margin?: string;
  padding?: string;
  titlePadding?: string;
}

export const RoundCardContainer = styled.div<RoundCardContainerProps>`
    background: ${props => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    padding: ${props => props.padding || '16px 16px 8px 16px'};
    border: 1px solid ${props => props.theme.colors.borderLightGrey};
    border-radius: 12px;
    margin: ${props => props.margin};
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-origin: center;

    .titleContainer {
      font-weight: ${props => props.theme.text.weight.bold};
      font-size: ${props => props.theme.text.size.subtitle1};
      font-family: ${props =>props.theme.text.font.primary};
      padding: ${props => props.titlePadding || '4px 0px'};
      border-bottom: ${props => `1px solid ${props.theme.colors.gray_300}`};
    }
`;

export interface ProductoContainerProps {
  padding?: string;
}

export const ProductoContainer = styled.div<ProductoContainerProps>`
    border-bottom: ${props => `1px solid ${props.theme.colors.borderLightGrey}`};
    display: flex;
    flex-direction: column;
    

    &:hover {
        cursor: pointer;
    }
`;

