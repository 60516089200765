import styled, { css } from 'styled-components';

export interface Props {
    container?: boolean;
    item?: boolean;
    direction?: string;
    justifyContent?: string;
    alignItems?: string;
    alignSelf?: string;
    xs?: number | boolean;
    bgcolor?: string;
    padding?: string;
    margin?: string;
    zindex?: number
    width?: string;
    height?: string;
    border?: string;
    textAlign?: string;
    flex?: number | boolean;
    minwidth?: string;
    maxwidth?: string;
    maxheight?: string;
    spacing?: string;
    borderLeft?: string | boolean;
    borderbottom?: string | boolean;
    borderRight?: string | boolean;
    borderRadius ?: string;
    flexWrap?: string;
    position?: string;
    cursor?: string;
    bordertop?: string | boolean;
    bottom ?: string;
    top ?: string;
    left ?: string;
    right ?: string;
    boxShadow ?: string;
    overflow?: React.CSSProperties['overflow'];
    flexShrink?: number;
}

const Grid = styled.div<Props>`
  ${props => props.container ? css`
  display: flex;
  flex-wrap: ${props.flexWrap || 'wrap'};
  flex-direction: ${props.direction ? props.direction : 'row'};
  
  justify-content: ${props.justifyContent};
  gap: ${props.spacing};
  align-items: ${props.alignItems};
  ` : null};

  ${props => props.item ? `
  flex-grow: ${props.flex ? null : (props.xs === true ? '1' : props.xs || '0')};
  flex: ${props.flex  ? (props.flex === true ? '1' : props.flex || '0') : null};
  ` : null};
  
  ${props => props.cursor ? css`
    cursor: ${props.cursor};

    > * {
      cursor: inherit;
    }
  ` : null}
  
  
  background-color: ${props =>props.bgcolor ? props.theme.colors[props.bgcolor] : ''};
  position: ${props => props.position};
  bottom: ${props => props.bottom};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  padding: ${props =>props.padding};
  text-align: ${props =>props.textAlign};
  box-shadow: ${props => props.boxShadow};
  align-self: ${props => props.alignSelf};

  border: ${props =>props.border};
  margin: ${props =>props.margin};
  border-left: ${props => props.borderLeft ? `1px solid ${props.theme.colors[typeof props.borderLeft === 'boolean' ? 'gray_100' : props.borderLeft]}` : null};
  border-right: ${props => props.borderRight ? `1px solid ${props.theme.colors[typeof props.borderRight === 'boolean' ? 'gray_100' : props.borderRight]}` : null};
  border-bottom: ${props => props.borderbottom ? `1px solid ${props.theme.colors[typeof props.borderbottom === 'boolean' ? 'gray_100' : props.borderbottom]}` : null};
  border-top: ${props => props.bordertop ? `1px solid ${props.theme.colors[typeof props.bordertop === 'boolean' ? 'gray_100' : props.bordertop]}` : null};
  border-radius: ${props => props.borderRadius};
  width: ${props =>props.width};
  min-width: ${props =>props.minwidth};
  max-width: ${props =>props.maxwidth};
  max-height: ${props =>props.maxheight};
  height: ${props =>props.height};
  z-index: ${props =>props.zindex};
  overflow: ${props =>props.overflow};
  flex-shrink: ${props =>props.flexShrink};
`;
export default Grid;
