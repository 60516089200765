// Responsive
import React, { useContext, useEffect } from 'react';
import { Button, Container, FloatButton, Grid, Loading, Modal, Title, Typography } from '~/components';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import useDirecciones from '~/hooks/useDirecciones';
import DeleteDirecciones from './containers/deleteDirecciones';
import ListadoDirecciones from './containers/listadoDirecciones';
import NuevaDireccionContainer from './containers/nuevaDireccion';
import { LocationOnBigFilledIcon } from '~/assets/icons';
import { CookiesContext } from '~/context/cookiesContext';
import { useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';


const MisDirecciones : React.FC = () => {
	const { handleShowBack, xsCarrito } = useContext(GeneralLayoutContext);

	
	const {
		handleGoToMain,
		dataDirecciones,
		nuevaDireccion,
		nDireccion,
		nuevaDireccion: {
			errorGuardarDireccion,
			loading,
			handleGuardarDireccion,
		},
		direccionHooks: {
			domicilioSelected,
			handleDomicilioId,
			handleOpenDomicilio,
			handleBack,
			handleCloseDomicilio,
			openDomicilio,
		},
		deleteDireccion:{
			openDeleteDireccion,
			hadleOpenDeleteDireccion,
			hadleCloseDeleteDireccion,
			handleDeleteDireccion,
		},
		editarDireccion: {
			handleOpenEditarDireccion,
		}
	} = useDirecciones();
	useEffect(() => {
		handleShowBack('/', 'Volver a tienda', 'Mis direcciones', true);
	}, []);
	
	const {handleDoneInitialLoading} = useContext(CookiesContext);
	useEffect(() => {
		if(dataDirecciones){
			handleDoneInitialLoading();
		}
	},[dataDirecciones]);

	useTitle(PAGINAS.DIRECCIONES);
	
	if(!dataDirecciones) {
		return <Loading />;
	}

	const isEmpty = dataDirecciones.length === 0;
	return (
		<Container padding={xsCarrito ? '22px 0 0 0' : '0 16px 0 16px'}>
			{
				(isEmpty) ? (
					<Grid container textAlign='center' alignItems='center' direction='column'>
						<Typography padding='0 0 48px 0' variant='body1'>¿A dónde te lo enviamos?</Typography>

						<LocationOnBigFilledIcon />

						<Typography padding='48px 0 0 0' variant='subtitle1bold'>
							Agrega la dirección en la que <br/> quieres recibir tus pedidos
						</Typography>
					</Grid>
				) : (
					<Grid container justifyContent='center'>
						<ListadoDirecciones
							xs={xsCarrito}
							key={0}
							hadleOpenDeleteDireccion={hadleOpenDeleteDireccion}
							dataDirecciones={dataDirecciones}
							domicilioSelected={domicilioSelected}
							handleDomicilioId={handleDomicilioId}
							handleNext={handleOpenDomicilio}
							handleOpenEditarDireccion={handleOpenEditarDireccion}
						/>

						<DeleteDirecciones 
							loading={loading}
							openDeleteDireccion={openDeleteDireccion}
							hadleCloseDeleteDireccion={hadleCloseDeleteDireccion}
							handleDeleteDireccion={handleDeleteDireccion} 
						/>

						{/* <DireccionPredeterminadaContainer 
						openDireccionPredeterminada={openDireccionPredeterminada}
						hadleCloseDireccionPredeterminada={hadleCloseDireccionPredeterminada}
						handleDireccionPredeterminada={handleDireccionPredeterminada} /> */}
					</Grid>
				)
			}

			{
				isEmpty && <FloatButton onClick={() => handleOpenDomicilio(true)} bottom='100px' minWidth='342px' variant='solid'>
					Agregar dirección
				</FloatButton>
			}

			<FloatButton finalHeight='100px' onClick={handleGoToMain} minWidth='342px' variant={isEmpty ? 'outline' : 'solid'}>
				Volver a la tienda
			</FloatButton>

			<Modal
				responsiveFullSize
				height='90vh'
				padding={xsCarrito ? '0 40px 0 40px' : '0 16px 0 16px'} width='100%' maxWidth="438px"
				title={
					<Grid padding='0 0 16px 0' textAlign="center" container justifyContent="center" borderbottom>
						<Title variant="h8">Añadir dirección</Title>
					</Grid>
				}
				action={
					<Grid container spacing='10px' padding='20px 40px' bordertop>
						<Grid item flex>
							<Button 
								disabled={loading}
								fullWidth
								onClick={handleBack} 	
								variant='outline'
								size='md'
								color='black'
							>
								Cancelar
							</Button>
						</Grid>

						<Grid item flex>
							<Button 
								loading={loading}
								disabled={errorGuardarDireccion} 
								onClick={() => handleGuardarDireccion(handleBack)} 
								variant='solid'
								color='black'
								size='md'
								fullWidth
							>
								{xsCarrito ? 'Guardar dirección' : 'Gurardar'}
							</Button>
						</Grid>
					</Grid>
				}
				close 
				handleClose={handleCloseDomicilio} 
				open={openDomicilio}
			>
				<NuevaDireccionContainer
					edit={!!(nDireccion)}
					predeterminada
					{...nuevaDireccion}
				/>
			</Modal>
		</Container>
	);
};
export default (MisDirecciones);