import React from 'react';
import CardMedia from '../CardMedia';
import Grid from '../Grid';
import Typography from '../Typography';
import { CategoriasCardContainer, Container, YellowTag } from './styled';
type Props = {
    src: string,
    title: string,
	bAnticipacion: boolean,
    onClick: VoidFunction,
	margin?: string
};
const CategoriaCard : React.FC<Props> = ({ title, src, onClick, bAnticipacion, margin } : Props) => {
	return (
		<Container margin={margin}>
			{
				bAnticipacion && <Grid className='yellowContainer' container justifyContent='center'>
					<YellowTag>Sobre pedido</YellowTag>
				</Grid>
			}
			<CategoriasCardContainer  onClick={onClick} className='noselect'>
				<CardMedia alt={title} borderRadius="15px 15px 0 0" minHeight='93px' src={src} />

				<Grid item xs textAlign='center' padding='0 0 10px 0'>
					<Typography className='paragraphContainer' variant='subtitle2'>{title}</Typography>
				</Grid>
			</CategoriasCardContainer>
		</Container>
	);
};
export default (CategoriaCard);