import styled, { css } from 'styled-components';

export interface ContainerProps {
  loadingCat?: boolean;
  showSideBar?: boolean;
  padding?: string;
  showBack?: string | null;
}

export interface GeneralLayoutContainerProps {
  showSideBar?: boolean;
  showSideBarIcon?: boolean;
  responsiveHeight?: string | null | boolean;
  showBack?: string | null;
  initialLoading?: boolean
}
export const Container = styled.div<ContainerProps>`
    
    
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    .generalLayoutContainer {
      width: 100%;
      
      max-width: 1240px;    
      display: flex;
      flex-direction: column;
    }

    background-color: ${props => props.theme.colors.bgColor};
`;

export const GeneralLayoutContainer = styled.div<GeneralLayoutContainerProps>`
    padding-top: 72px;
    padding-left: ${props => props.showSideBar ? '258px' : null};
    display: flex;
    flex-direction: column;
    ${props => props.initialLoading ? css`
    overflow: hidden;
    height: calc(100vh - 72px);

    ` : css`
    min-height: calc(100vh - 72px);
    
    `}

    @media (max-width: 650px) {
        ${props => props.initialLoading ? css`
          overflow: hidden;
          height: ${props.responsiveHeight === true ? '72px' : (props.responsiveHeight || (props.showBack ? '72px' : '150px'))};

          ` : css`
          min-height: calc(100vh - ${props.responsiveHeight === true ? '72px' : (props.responsiveHeight || (props.showBack ? '72px' : '150px'))});
          
        `}
        padding-top: ${props => props.responsiveHeight === true ? '72px' : (props.responsiveHeight || '72px')};
    }

`;

export interface PayButtonContainerProps {
  bottom?: string;
}


export const PayButtonContainer = styled.div<PayButtonContainerProps>`
  position: fixed;
  bottom: ${props => props.bottom || '35px'};
  left: 50%;
  z-index: 98;
  transform: translate(-50%, -50%);
`;

/* 
    ${props => props.showSideBar ? css`
          ${props.loadingCat ? css`
              padding: 133px 30px 10px 30px;
              @media (max-width: 900px) {
                padding: 133px 5px 10px 5px;
              }
              @media (max-width: 340px) {
                padding: 133px 5px 10px 5px;
              }
          ` : css`
              padding: 133px 30px 10px 248px;
              @media (max-width: 900px) {
                padding: 133px 5px 10px 5px;
              }
              @media (max-width: 340px) {
                padding: 133px 5px 10px 5px;
              }
          `}
    ` : css`    
    padding: 0px 10% 10px 10%;
    
    transition: padding 0.3s ease;`
}
    

*/