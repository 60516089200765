import React, { useEffect } from 'react';
import { useDetalleProductoModal, useFavoritos, useTitle } from '~/hooks';
import Container from '../../components/Container';
import { DetalleProductoModal, ExpansionPanel, Grid, ListadoProductos, Loading, Typography } from '~/components';
import { ProductCardContainer } from '~/components/ProductCard/styled';
import { HeartIcon } from '~/assets/icons';
import { PAGINAS, ProductosProps } from '~/interfaces';

const Favoritos : React.FC = () => {
	const {
		secciones,
		handleRemove
	} = useFavoritos();

	const {
		openDetalleProducto,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleLike,
		handleClick,
		handleDataCarrito,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		handleNivelMainId,
		handleOrdenar,
		detalleEnvio,
		loadingCheckoutCarrito,
	} = useDetalleProductoModal();

	useTitle(PAGINAS.FAVORITOS);

	const empty = (
		<ProductCardContainer>
			<Grid container alignItems='center' justifyContent='center' padding='60px 0 84px 0'>
				<HeartIcon color='grey70' height='121px' width='121px'></HeartIcon>
		
			</Grid>
			<Grid container justifyContent='center' textAlign='center'>
				<Typography>
					Distingue tus productos favoritos <br/> seleccionando el ícono de corazón en <br/> la esquina de cada imagen.
				</Typography>
			</Grid>
		</ProductCardContainer>
	);

	const nivelTiendaInfo = secciones?.find((seccion) => seccion.nNivelTienda === nivelMainId);

	useEffect(() => {
		if (secciones) {
			let products: ProductosProps[] = [];
			for (const element of secciones) {
				products = [...products, ...element.VProductos];
			}

			window.fbq('track', 'ViewContent', {
				content_ids: products.map((product) => product.nVProducto.toString()),
				content: products.map((product) => ({ id: product.nVProducto.toString(), quantity: 1 })),
				content_name: 'Favoritos',
				content_type: 'product',
				value: 0,
				currency: 'MXN',
			});
		}
	}, [secciones]);

	if(!secciones){
		return <Loading />;
	}
	
	return (
		<Container paddingTop="15px">
			{
				secciones?.length > 0 ?
					secciones?.map((seccion, i) => {
						return <ExpansionPanel key={i} expansionTitle={seccion.cNivelTienda}>
							{
								seccion.VProductos.length === 0 ? empty : <ListadoProductos 
									hide
									handleLike={handleLike}
									key={i}
									handleRemove={handleRemove}
									handleClick={(id)=>handleClick(id, seccion.nNivelTienda)}
									productos={seccion.VProductos} 
									item_list={`Favoritos - ${seccion.cNivelTienda}`}
								/>
							}
					
						</ExpansionPanel>;
					}) : <Grid container justifyContent='center'>
						{empty}
					</Grid>
			}
			<DetalleProductoModal 
				openDetalleProducto={openDetalleProducto}
				productoId={productoId}
				handleOpenDetalleProductoModal={handleOpenDetalleProductoModal}
				handleProductoId={handleProductoId}
				handleClick={handleClick}
				handleDataCarrito={handleDataCarrito}
				handleModificaCarrito={handleModificaCarrito}
				carritoLoading={carritoLoading}
				nivelMainId={nivelMainId}
				handleNivelMainId={handleNivelMainId}
				handleOrdenar={handleOrdenar}
				detalleEnvio={detalleEnvio}
				loadingCheckoutCarrito={loadingCheckoutCarrito}
				itemList={`Favoritos - ${nivelTiendaInfo?.cNivelTienda}`}
			/>  
		</Container>
	);
};
export default (Favoritos);