import { useContext, useEffect, useState } from 'react';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { ISeccion } from '~/interfaces';

const useMain = () => {
	const {
		headersApi,
		handleDoneInitialLoading
	} = useContext(CookiesContext);

	const apiUrl = import.meta.env.VITE_API_URL;

	const {
		handleResetShowBack,
		nivelesTienda,
		nuevaOrdenModalOpen,
		handleReset,
		nuevaOrdenModalError
	} = useContext(GeneralLayoutContext);

	// Se activa para que se quite el botón de regresar de mi perfil
	useEffect(() => {
		handleResetShowBack();
		handleReset();
	}, []);


	const [secciones, setSecciones] = useState<ISeccion[] | undefined>(undefined);

	// Obtiene las categorías y los tipos de pedido 

	useEffect(() => {
		fetch(`${apiUrl}/APPWEB/ObtenerSecciones`, {
			method: 'GET',
			headers: headersApi
		}).then((res) => res.json()).then((responseCat) => {

			if (responseCat?.Result) {
				handleDoneInitialLoading();
				setSecciones(responseCat.Result);
			}

		}).catch((error) => {
			console.error(error, 'error');
		});

	
	}, [headersApi?.Authorization]);

	const [bannersData, setBannersData] = useState(undefined);

	useEffect(() => {
		fetch(`${apiUrl}/APPWEB/ObtenerBannersHome`, {
			method: 'GET',
			headers: headersApi
		}).then((res) => res.json()).then((responseBanners) => {

			if (responseBanners?.Result?.dataBanners
			) {
				
				setBannersData(responseBanners.Result.dataBanners);
			}

		}).catch((error) => {
			console.error(error, 'error');
		});

	
	}, [headersApi?.Authorization]);
	
	return {
		apiUrl,
		nivelesTienda,
		nuevaOrdenModalError,
		secciones,
		bannersData,
		nuevaOrdenModalOpen,
	};
};

export default useMain;