import styled, { css } from 'styled-components';

export interface CuponProps {
  margin?: string;
  color?: string;
  disabled?: boolean
  padding?: string
}

export const Cupon = styled.div<CuponProps>`
    min-height: 56px;
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    position: relative;

    border-bottom: 1px solid ${props => props.theme.colors.gray_100};

    .left {
        left: -11px;
    }

    .right {
        right: -11px;
    }
    overflow: hidden;

    .container {
        border: 1px solid ${props => props.theme.colors.yellow_600};
        background-color: ${props => props.theme.colors[props.color ? props.color : props.disabled ? 'gray_50' : 'orange_50']};
        width: 100%;
        height: 100%;
        flex: 1;
        padding: 8px 24px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
    }

    ${props => props.disabled ? css`
        span {
            color: ${props.theme.colors.disabledTextFieldGrey} !important;
        }
    ` : css`
        &:hover {
            cursor: pointer;
        }
    `}
`;


export const Circle = styled.div`
     height: 22px;
    width: 22px;
    border: 1px solid ${props => props.theme.colors.yellow_600};
    background-color: ${props => props.theme.colors.white};
    border-radius: 50%;
    display: inline-block;
    top: calc(50% - 11px);
    position: absolute;
    z-index: 10;
    
`;