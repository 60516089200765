import React from 'react';
import { SearchIcon } from '~/assets/icons';
import { IconButton, Typography } from '..';
import { TextFieldContainer } from '../TextField/styled';
import { TextFieldInput, TextFieldContainerInput, InputContainer } from './styled';
type Props = {
	label?: string,
	value?: string,
	padding?: string,
	type?: string,
	helperText?: string,
	error?: boolean,
	check?: boolean,
	onChange: React.ChangeEventHandler<HTMLInputElement> | undefined,
	handleBuscarInput: () => void,
	placeholder?: string,
	width?: string,
	margin?: string,
	fullWidth?: boolean,
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    onWheel?: React.WheelEventHandler<HTMLInputElement>;
};
const TextFieldSearch : React.FC<Props> = ({value, onChange, error, check, type, handleBuscarInput, placeholder, width, margin, padding, helperText, fullWidth, onKeyDown, onWheel} : Props) => {
	const icon = error || check;

	return (
		<TextFieldContainer fullWidth={fullWidth} margin={margin} width={width} padding={padding}>
			<TextFieldContainerInput fullWidth={fullWidth} error={error} check={check} >
			
				<InputContainer>
					<TextFieldInput
						id="searcher" 
						inputMode={type === 'number' ? 'numeric' : undefined}
						onWheel={onWheel}
						onKeyDown={(e) => {
							onKeyDown && onKeyDown(e);

							if (e.key === 'Enter') {
								handleBuscarInput();
							}
						}}
						placeholder={placeholder || 'Busca tu comida favorita' }autoComplete='off' type={type} icon={icon} onChange={onChange} value={value} />
				</InputContainer>
			
			
				<div className='icon'>
					<IconButton onClick={handleBuscarInput}>
						<SearchIcon color='grey' />
					</IconButton>
				</div>
				
			</TextFieldContainerInput>
			{
				helperText && <Typography padding='5px 0 0 0' variant='regular' color={error ? 'red_500' : 'lightGrey'}>{helperText}</Typography>
			}
			
		</TextFieldContainer>
	);
};
export default (TextFieldSearch);