import React from 'react';
import { Payment } from '@mercadopago/sdk-react';
import useMercadoPagoFormBricks from '~/hooks/useMercadoPagoFormBricks';
import { FormContainer, LoadingContainer } from './styled';
import Loading from '../Loading';

const MercadoPagoFormBricks : React.FC = () => {
	const {
		initialization,
		customization,
		onSubmit,
		onReady,
		isReady,
	} = useMercadoPagoFormBricks();
	
	return (
		<FormContainer>
			<LoadingContainer showGeneralLoading={!isReady}>
				<div className='container'>
					<Loading marginTop="40px"></Loading>
				</div>
			</LoadingContainer>
			
			<Payment
				initialization={initialization}
				customization={customization}
				onSubmit={onSubmit}
				onReady={onReady}
			/>
		</FormContainer>

	);
};
export default (MercadoPagoFormBricks);
