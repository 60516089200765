// responsive
import React, { Fragment, useContext, useState } from 'react';
import { Container, DetalleProductoModal, Empty, ListadoProductos, Loading, NivelesAppBar, OrdenesActivasCard, Title, WarningTimeOutModal } from '~/components';
import CarrouselCard from '~/components/CarrouselCard';
import CategoriaCard from '~/components/CategoriasCard';
import ListadoHorizontalProductos from '~/components/ListadoHorizontalProductos';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useDetalleProductoModal, useOrdenesActivas, useTiendaCategorias, useTitle } from '~/hooks';
import { getCdnUrl } from '~/utils';

const TiendaCategorias : React.FC = () => {
	const {
		nivelesTiendaId,
		handleNivel,
		loading,
		nivelTiendaDetalles,
		handleGotoCategorias,
	} = useTiendaCategorias();

	const {
		openDetalleProducto,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleLike,
		handleClick,
		handleDataCarrito,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		handleNivelMainId,
		handleOrdenar,
		detalleEnvio,
		loadingCheckoutCarrito,
	} = useDetalleProductoModal();

	const {
		xs,
	} = useContext(GeneralLayoutContext);

	const {
		ordenesActivas
	} = useOrdenesActivas();

	useTitle(nivelTiendaDetalles?.dataSubCategorias?.cDescripcion);

	const [categoriaSelected, setCategoriaSelected] = useState('');

	return (
		<>
			<NivelesAppBar xs={xs} activeTab={nivelesTiendaId} handleActiveTab={handleNivel} />

			<Container>
				{
					loading || !nivelTiendaDetalles || !ordenesActivas ? (
						<Loading />
					) : <>
						{
							nivelTiendaDetalles?.dataSubCategorias?.subCategorias && nivelTiendaDetalles?.dataSubCategorias?.subCategorias?.length > 0 && (
								<CarrouselCard arrowLocation='top' title='Categorías'>
									{
										nivelTiendaDetalles?.dataSubCategorias?.subCategorias.map((item, i) => (
											<CategoriaCard 
												bAnticipacion={!!(item.bAnticipacion)}
												onClick={() => handleGotoCategorias(item)}
												title={item.cDescripcion || ''}
												src={getCdnUrl(item?.cImagenUrl)}
												margin='16px 0 8px 0'
												key={i}
											/>
										))
									}
								</CarrouselCard>
							)
						}

						<OrdenesActivasCard xs={xs} ordenesActivas={ordenesActivas}/>

						{
							nivelTiendaDetalles?.dataSeccionesInteres?.length === 0 && nivelTiendaDetalles?.dataProductosCategoria?.length === 0 && <Empty emptyTitle='Sin productos.' />
						}

						{
							nivelTiendaDetalles?.dataSeccionesInteres && nivelTiendaDetalles.dataSeccionesInteres.map((seccionInteres, i) => (
								<Fragment key={i}>
									{
										seccionInteres.nTipoSeccion === 2 ? (
											<>
												<Title padding={xs ? '20px 0 15px 0' : '17px 0 15px 0'} variant={xs ? 'h8' : 'h5'}>
													{seccionInteres.cDescripcion}
												</Title>

												<ListadoProductos 
													handleLike={handleLike}
													handleClick={(id: number, nivelId?: number) => {
														setCategoriaSelected(seccionInteres.cDescripcion);
														return handleClick(id, nivelId);
													}}
													productos={seccionInteres.VProductos} 
													item_list={seccionInteres.cDescripcion}
												/>
											</>
										) : (
											<ListadoHorizontalProductos 
												handleClick={(id: number, nivelId?: number) => {
													setCategoriaSelected(seccionInteres.cDescripcion);
													return handleClick(id, nivelId);
												}}
												title={seccionInteres.cDescripcion}
												productos={seccionInteres.VProductos}
												item_list={seccionInteres.cDescripcion}
											/> 
										)
									}
								</Fragment>
							))
						}

						{
							nivelTiendaDetalles?.dataProductosCategoria && nivelTiendaDetalles.dataProductosCategoria.map((seccionInteres, i) => (
								<Fragment key={i}>
									<Title padding={xs ? '20px 0 15px 0' : '17px 0 15px 0'} variant={xs ? 'h8' : 'h5'}>
										{seccionInteres.cCategoria}
									</Title>
									<ListadoProductos 
										key={i}
										handleLike={handleLike}
										handleClick={handleClick}
										productos={seccionInteres.VProductos} 
										item_list={seccionInteres.cCategoria}
									/>
								</Fragment>
							))
						}

						{
							nivelTiendaDetalles.dataSubCategorias ? <WarningTimeOutModal nivelTienda={nivelTiendaDetalles.dataSubCategorias}  /> : <></>
						}
					</>
				}
			</Container>

			<DetalleProductoModal 
				openDetalleProducto={openDetalleProducto}
				productoId={productoId}
				handleOpenDetalleProductoModal={handleOpenDetalleProductoModal}
				handleProductoId={handleProductoId}
				handleClick={handleClick}
				handleDataCarrito={handleDataCarrito}
				handleModificaCarrito={handleModificaCarrito}
				carritoLoading={carritoLoading}
				nivelMainId={nivelMainId}
				handleNivelMainId={handleNivelMainId}
				handleOrdenar={handleOrdenar}
				detalleEnvio={detalleEnvio}
				loadingCheckoutCarrito={loadingCheckoutCarrito}
				itemList={categoriaSelected}
			/>
			
			{/* <InfoModal nNivelTienda={nivelesTiendaId} /> */}
		</>
	);
	
};
export default (TiendaCategorias);