import styled, { css } from 'styled-components';
import { toolTipFade } from '~/styles/keyframes';

export interface GeneralLoadingContainerProps {
    showGeneralLoading?: boolean;
}


export const GeneralLoadingContainer = styled.div<GeneralLoadingContainerProps>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    background-color: #FFFFFF;
    z-index: 100000;
    transition: all 0.3s ease-in-out;
    ${props => props.showGeneralLoading ? css`
        display: flex;
        opacity: 1;

        ` : css`
        display: none;

    `}


`;

export const ProgressBar = styled.div`
    height: 9px;
    width: 100%;
    max-width: 194px;
    margin-top: 10px;
    background-color: #FFFFFF;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
  
    div {
        position: absolute;
        background-color: #FFDA7F;
        height: 9px;
        border-radius: 10px;
        width: 50px;
        animation: ${toolTipFade} 0.8s infinite;
    }
`;