import React from 'react';
import WarningModal from '../WarningModal';

type Props = {
    handleClickItem: () => void, open: boolean,
	handleClose: () => void,
	nDiasAnticipacion?: number,
	cDiasDisponibles?: string,
	descripcion?: JSX.Element | JSX.Element[] | string,
};

const WarningSobrePedido : React.FC<Props> = ({ 
	handleClickItem, 
	open,
	handleClose, 
	nDiasAnticipacion, 
	cDiasDisponibles, 
	descripcion 
} : Props) => {
	return (
		<WarningModal 
			width='341px'
			height='auto'
			cancelLabel={descripcion ? 'Cancelar' : 'No, gracias'}
			aceptLabel={descripcion ? 'Aceptar' : 'Sí, entiendo'}
			acceptColor='black'
			title='Producto sobre pedido'
			description={descripcion || `Este producto es sobre pedido ${cDiasDisponibles 
				? `está disponible para las siguientes fechas ${cDiasDisponibles}${nDiasAnticipacion ? ` y tarda ${nDiasAnticipacion} en estar listo.` : ''}` 
				: `tarda ${nDiasAnticipacion} días en estar listo.`}`}
			handleAcept={() => {
				handleClose();
				handleClickItem();
			}}
			open={open} 
			handleClose={handleClose}
		/>
	);
};

export default (WarningSobrePedido);