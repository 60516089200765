import moment from 'moment';
import 'moment/locale/es';

import React, { useEffect, useRef } from 'react';
import { EditCalendarIcon, ExpandMoreIcon } from '~/assets/icons';
import { useToolTipSelect } from '~/hooks';
import Grid from '../Grid';
import { TextFieldLabel } from '../TextField/styled';
import { Popover, SelectContainer, SvgContainer, TextFieldContainerInput } from '../TextFieldSelect/styled';
import Typography from '../Typography';
import DatePicker from './datePicker';
import { FechasDisponiblesTypes } from '~/interfaces';
import { DatePickerSteps } from './datePicker/helpers';

interface Props {
	handleCLick: (value: string) => void;
	value: string | null;
	helperText?: string;
	error?: boolean;
	label?: string;
	padding?: string;
	nRangoLibreFechasDisponibles?: boolean;
	dFechasAPartirDe?: string;
	fullWidth ?: boolean;
	dFechasDisponibles?: FechasDisponiblesTypes[];
	validateDate?: ((val: string) => boolean) | ((dateValue: string, nRangoLibreFechasDisponibles?: boolean, dFechasAPartirDe?: string, dFechasDisponibles?: FechasDisponiblesTypes[]) => boolean);
	initialStepper?: DatePickerSteps
}

const DatePickerSelect: React.FC<Props> = ({ handleCLick, value, helperText, error, padding, label, nRangoLibreFechasDisponibles, dFechasAPartirDe, dFechasDisponibles, validateDate, fullWidth, initialStepper }: Props) => {
	const { 
		onMouseEnter, 
		onMouseLeave, 
		showTooltip, 
		button 
	} = useToolTipSelect();

	const dateRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickMouse = (event: MouseEvent) => {
			if (dateRef.current && !dateRef.current.contains(event.target as Node)) {
				onMouseLeave();
			}
		};

		document.addEventListener('click', handleClickMouse);
		return () => document.removeEventListener('click', handleClickMouse);
	}, []);

	const today = moment().format('YYYY-MM-DD');

	return (
		<Grid padding={padding || '0 0 5px 0'} flex item container maxwidth={ fullWidth ? '' : '358px'} direction="column">
			<Grid item padding="0 0 4px 0">
				<TextFieldLabel>{label}</TextFieldLabel>
			</Grid>
			<SelectContainer ref={dateRef}>
				<TextFieldContainerInput error={error} open={showTooltip} className="button" onClick={onMouseEnter} ref={button}>
					<EditCalendarIcon />
					<Grid item flex padding="0 0 0 10px">
						<Typography className="noselect">{value ? (value === today ? 'Hoy' : moment(value).format('LL')) : 'Elige una fecha'}</Typography>
					</Grid>
					<SvgContainer>
						<ExpandMoreIcon />
					</SvgContainer>
				</TextFieldContainerInput>
				<Popover zIndex={1001} open={showTooltip}>
					{
						showTooltip && (
							<DatePicker
								nRangoLibreFechasDisponibles={nRangoLibreFechasDisponibles}
								dFechasAPartirDe={dFechasAPartirDe}
								dFechasDisponibles={dFechasDisponibles}
								value={value}
								handleCLick={(e) => {
									handleCLick(e);
									onMouseLeave();
								}}
								validateDate={validateDate}
								initialStepper={initialStepper}
							/>
						)
					}
				</Popover>
			</SelectContainer>
			{helperText && (
				<Typography padding="5px 0 0 0" variant="regular" color={error ? 'red_500' : 'lightGrey'}>
					{helperText}
				</Typography>
			)}
		</Grid>
	);
};
export default DatePickerSelect;
