export const PATHS = {
	main: '/',
	login: '/inicia-sesion',
	recu_contra: '/recuperar-contrasenia',
	singin: '/registro',
	menu_dig: '/menu-digital',
	crear_cuenta: '/crear-cuenta',
	Dinam: '/dinamicas',
	Auth: '/autenticate',
	checkoutBase: '/checkout/',
	checkoutDes: '/checkout/1',
	checkoutCom: '/checkout/2',
	checkoutPast: '/checkout/3',
};