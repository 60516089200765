import React from 'react';
import { Button, Grid, Modal, Typography } from '..';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
	openWarningUserModal: boolean
};

const WarningUserModal : React.FC<Props> = ({ openWarningUserModal } : Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname;

	const handleClose = () => {
		navigate('/autenticate');
	};

	if(path.includes('/autenticate')){
		return <></>;
	}
	
	return (
		<Modal maxWidth='341px' open={openWarningUserModal} handleClose={handleClose} close>
			<Grid container direction='column' alignItems='center' textAlign='center' padding='0 22px 0 22px'>
				<Typography padding='0 0 15px 0' variant='body2'>
					Antes de continuar, debes completar tu información de contacto.
				</Typography>
			</Grid>

			<Grid container alignItems='center' spacing='5px' padding='25px 15px 25px 15px'>
				<Button onClick={handleClose} fullWidth variant='solid' >
                    Aceptar
				</Button>
			</Grid>
		</Modal>
	);
};
export default (WarningUserModal);