import { initMercadoPago } from '@mercadopago/sdk-react';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CheckoutContext } from '~/context/checkoutContext';
import { CookiesContext } from '~/context/cookiesContext';
import { PaymentProps } from '~/interfaces';

const useMercadoPagoFormBricks = () => {
	const { handleCardFormData, total, setNivelCheckoutId, email, mpData, body3D, handleBody3D, config } = useContext(CheckoutContext);

	const params = useParams();
	const nNivelTienda = params.nNivelTienda;
	const [isReady, setIsReady] = useState(false);

	const onReady = () => {
		setIsReady(true);
	};

	useEffect(() => {
		if (nNivelTienda) {
			setNivelCheckoutId(parseInt(nNivelTienda));
		}
	}, [nNivelTienda]);

	const publicKeyMP = import.meta.env.VITE_PUBLIC_KEY_MP;
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (loading) {
			initMercadoPago(publicKeyMP, { locale: 'es-MX' });
			setLoading(false);
		}
	}, []);

	const { userInfo } = useContext(CookiesContext);

	const initialization: PaymentProps['initialization'] = {
		amount: total || NaN,
		payer: {
			email: userInfo?.invitado ? email : userInfo?.cCorreo,
			customerId: mpData?.id || undefined,
			cardsIds: mpData?.tarjetas || undefined,
		},
	};

	const customization: PaymentProps['customization'] = {
		paymentMethods: {
			creditCard: 'all',
			debitCard: 'all',
			maxInstallments: 1,
		},
		visual: {
			style: {
				theme: 'flat',
				customVariables: {
					
				}
			},
		},
		...config,
	};

	const onSubmit: PaymentProps['onSubmit'] = async (data) => {
		// callback llamado al hacer clic en el botón enviar datos
		if (total && data?.formData) {
			handleCardFormData({
				paymentMethodId: data.formData.payment_method_id,
				issuerId: data.formData.issuer_id,
				cardholderEmail: data.formData.payer.email || '',
				amount: total.toFixed(2),
				identificationNumber: data.formData.payer.id || '',
				identificationType: data.formData.payer.type || '',
				token: data.formData.token,
				installments: data.formData.installments,
			});
		}
	};

	return {
		initialization,
		customization,
		onSubmit,
		onReady,
		isReady,
		body3D,
		handleBody3D,
	};
};

export default useMercadoPagoFormBricks;
