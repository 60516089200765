import React, {
	FunctionComponent,
	MouseEvent,
	useState,
	useRef,
	useCallback,
} from 'react';
import { Item, SlideDirection } from '../../types/carousel';
import styles from '../../styles/slider/styles.module.css';
import { getOuterWidth } from '../../helpers';
import { defaultProps } from './defaultProps';
import ChevronIconButton from '~/components/ChevronIconButton';
import Grid from '~/components/Grid';
import Title from '~/components/Title';

export const ScrollingCarousel: FunctionComponent<SliderProps> = (
	userProps: SliderProps,
) => {
	const { children, className, triggerClickOn, margin, title, subTitle, arrowsLocation = 'inline' } = {
		...defaultProps,
		...userProps,
	};
	const slider = useRef<HTMLDivElement>(null);
	const [isDown, setIsDown] = useState(false);
	const [position, setPosition] = useState({
		startX: 0,
		scrollLeft: 0,
	});

	const showArrows = (): Arrows => {
		const sliderElement = slider.current;
		return {
			left: !!sliderElement && sliderElement.scrollLeft > 0,
			right:
				!!sliderElement &&
				sliderElement.scrollWidth >
					sliderElement.scrollLeft + sliderElement.offsetWidth + 32
		};
	};
	const [showArrow, setShowArrow] = useState<Arrows>(showArrows());

	const onScroll = () => {
		setShowArrow(showArrows());
	};

	const ref = useCallback(
		(node: any) => {
			if (node !== null) {
				Object.defineProperty(slider, 'current', { value: node });
				setShowArrow(showArrows());
				node.addEventListener('scroll', onScroll);
			}
		},
		[slider, children],
	);

	const mouseDown = (e: MouseEvent) => {
		setIsDown(true);
		setPosition({
			startX: e.pageX - slider.current!.offsetLeft,
			scrollLeft: slider.current!.scrollLeft,
		});
	};

	const mouseUp = () => {
		setIsDown(false);
		setShowArrow(showArrows());
		slider.current!.classList.remove(styles.sliding);
	};

	const mouseMove = (e: MouseEvent) => {
		if (!isDown) return;
		e.preventDefault();
		const eventPosition = e.pageX - slider.current!.offsetLeft;
		const slide = eventPosition - position.startX;

		if (Math.abs(slide) > triggerClickOn) {
			slider.current!.classList.add(styles.sliding);
		}
		slider.current!.scrollLeft = position.scrollLeft - slide;
	};

	const calculateSlideAmount = (direction: SlideDirection): number => {
		const _slider = slider.current!;
		const currentView =
			direction === SlideDirection.Left
				? _slider.scrollLeft + _slider.offsetWidth
				: _slider.scrollLeft;

		const childNodes = Array.from(_slider.children) as HTMLElement[];
		let nodeWidthSum = 0;
		for (const node of childNodes) {
			const nodeWidth = getOuterWidth(node);
			nodeWidthSum += nodeWidth;

			if (nodeWidthSum >= currentView) {
				const showingPart =
					direction === SlideDirection.Left
						? nodeWidthSum - currentView
						: nodeWidth;

				return (_slider.offsetWidth - showingPart) * direction;
			}
		}

		return _slider.offsetWidth;
	};

	const slide = (direction: SlideDirection) => {
		const slideAmount = calculateSlideAmount(direction);
		
		const start = slider.current!.scrollLeft;
		smoothHorizontalScroll(500, slideAmount, start);
	};

	const smoothHorizontalScroll = (time: number, amount: number, start: number) => {
		let curTime = 0;
		for (let scrollCounter = 0; curTime <= time; scrollCounter++) {
			window.setTimeout(
				smoothHorizontalScrollBehavior,
				curTime,
				(scrollCounter * amount) / 100 + start,
			);
			curTime += time / 100;
		}
	};

	const smoothHorizontalScrollBehavior = (amount: number) => {
		slider.current!.scrollLeft = amount;
	};

	const leftArrow = (
		<ChevronIconButton 
			bgColor='gray_100'
			disabled={!showArrow.left} 
			chevronColor='gray_900'
			size={32}
			style={{transform: 'scale(32/20)', opacity: showArrow.left ? 1 : 0.5, flexShrink: 0}}
			orientation='left'
			onClick={() => slide(SlideDirection.Right)}
		/>
	);

	const rightArrow = (
		<ChevronIconButton 
			chevronColor='gray_900' 
			bgColor='gray_100'
			size={32}
			disabled={!showArrow.right} 
			style={{opacity: showArrow.right ? 1 : 0.5, flexShrink: 0}}
			onClick={() => slide(SlideDirection.Left)}
		/>
	);

	return (
		<Grid container direction='column' width='100%'>
			<Grid container justifyContent='space-between' alignItems='center'>
				<Grid container direction='column'>
					{title && <Title color='gray_900' variant='h8'>{title}</Title>}
					{subTitle && <Title variant='h5-medium' color='gray_600'>{subTitle}</Title>}
				</Grid>

				{arrowsLocation === 'top' && <Grid container direction='row' alignItems='start' spacing='8px'>
					{leftArrow}
					{rightArrow}
				</Grid>}
			</Grid>

			<div style={{display: 'flex', alignItems: 'center', margin: margin, gap: '16px' }} className={`${styles.sliderBase} ${className}`} data-testid="carousel">
				{arrowsLocation === 'inline' && leftArrow}

				<div
					data-testid="sliderList"
					ref={ref}
					onMouseDown={mouseDown}
					onMouseLeave={mouseUp}
					onMouseUp={mouseUp}
					onMouseMove={mouseMove}
					className={styles.slider}
					style={{flex: 1,}}
				>
					{children}
				</div>

				{arrowsLocation === 'inline' && rightArrow}
			</div>
		</Grid>
	);
};

export interface SliderProps {
	children: Item[];
	className?: string;
	gap?: string;
	margin?: string;
	triggerClickOn?: number;
	title?: string;
	subTitle?: string;
	arrowsLocation?: 'top' | 'inline' | 'none';
}

export type Arrows = {
	left: boolean;
	right: boolean;
};
