import { Grid } from '~/components';
import CardMedia, { CardMediaProps } from '~/components/CardMedia';
import React, { useState } from 'react';
import { getCdnUrl } from '~/utils';
import colors from '~/styles/colors';

interface ProductoCarouselProps {
	description?: string;
	images: string[];
	mainImageProps?: Omit<CardMediaProps, 'src'>;
	thumbnailsProps?: Omit<CardMediaProps, 'src'>;
	thumbnailsContainerStyle?: React.CSSProperties;
	useGetCdnUrl?: boolean;
}

export const ProductoCarousel: React.FC<ProductoCarouselProps> = ({ description, images, mainImageProps, useGetCdnUrl, thumbnailsProps, thumbnailsContainerStyle }) => {
	const [selectedImage, setSelectedImage] = useState<string>(images.length > 0 ? getCdnUrl(images[0]) : '');

	// Function to handle thumbnail click
	const handleThumbnailClick = (image: string) => {
		setSelectedImage(image);
	};

	return (
		<Grid container direction="column" spacing="8px" flexWrap='nowrap'>
			{/* Main Image */}
			<CardMedia alt={description} src={selectedImage} {...mainImageProps} />

			{/* Thumbnails Row */}
			{images.length > 1 && <Grid container direction='row' spacing="8px" justifyContent="start" style={thumbnailsContainerStyle}>
				{images.map((image, index) => {
					const imageSrc = useGetCdnUrl ? getCdnUrl(image) : image;

					return (
						<Grid item key={index}>
							<CardMedia
								src={imageSrc}
								alt={`${description || 'Product'} thumbnail ${index + 1}`}
								onClick={() => handleThumbnailClick(imageSrc)}
								{...thumbnailsProps}
								style={{
									width: '42px',
									height: '42px',
									cursor: 'pointer',
									border: selectedImage === imageSrc ? `2px solid ${colors['gray_900']}` : `2px solid ${colors['gray_300']}`,
									borderRadius: '3px',
									...thumbnailsProps?.style
								}}
							/>
						</Grid>
					);
				})}
			</Grid>}
		</Grid>
	);
};
