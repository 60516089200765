import React from 'react';
import {RipplesButtons} from 'components';
import {IconButtonStyled} from './styled';



export type IconButtonProps = {
  children: JSX.Element | JSX.Element[],
  style?: React.CSSProperties,
  onClick?: VoidFunction,
  className?: string,
  bgColor?: string,
  size?: string,
  disabled?: boolean
  padding?: string
  containerPadding?: string
};

const IconButton : React.FC<IconButtonProps> = ({ style, className, children, onClick, bgColor, size, disabled, padding, containerPadding } : IconButtonProps) => {

	const styleIcon: React.CSSProperties = {
		borderRadius: '50%', 
		padding: padding || '2px',
		placeItems: 'center',
		backgroundColor: 'transparent',
		border: 'none',
		textAlign: 'center',
		overflow: 'hidden',
		...style,
	};

	return (
		<RipplesButtons disabled={disabled} style={styleIcon} onClick={onClick}>
			<IconButtonStyled disabled={disabled} width={size} height={size} bgColor={bgColor} padding={containerPadding} className={className}>
				{children}
			</IconButtonStyled>
		</RipplesButtons>
	);
};
export default (IconButton);



