import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';
import moment from 'moment';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { ISubCategorias } from '~/interfaces';
export interface InivelesTienda {
	bActivo: boolean,
	cDescripcion: string,
	cImagenUrl: string,
	nHorario?: string,
	bHorarioDisponible: number,
	nHorarioFin: string,
	nHorarioInicio: string,
	nNivelTienda: number,
	subCategorias?: ISubCategorias[],
}
const useWarningTimeOutModal = (nivelTienda: InivelesTienda) => {

	const {
		detalleEnvio,
		handleDetalleEnvio,
		headersApi
	} = useContext(CookiesContext);
	const {
		nivelesTiendaId,
		warningOpcionesEntregaModal,
		setWarningOpcionesEntregaModal
	} = useContext(GeneralLayoutContext);
	const format = 'hh:mm:ss';

	// var time = moment() gives you current time. no format required.

	useEffect(() => {
		const hhFormat = 'HH:mm:ss';

		const horaInicioA = moment(moment(nivelTienda?.nHorarioInicio).utc().format(hhFormat), format);
		const horaFinA = moment(moment(nivelTienda?.nHorarioFin).utc().format(hhFormat), format);

		const currentTime = moment(moment(detalleEnvio?.cHoraAgendada, 'HH:mm').format(hhFormat), hhFormat);

		const beforeTime = currentTime.isBetween( horaInicioA, horaFinA, undefined, '[]');
		
		if(nivelTienda && (nivelTienda?.nHorarioFin !== null && nivelTienda?.nHorarioInicio !== null) && (((detalleEnvio?.cHoraAgendada === null && !detalleEnvio?.bHoraPendiente) || (!beforeTime && (detalleEnvio?.cHoraAgendada) !== null && !detalleEnvio?.bHoraPendiente)))){
			setWarningOpcionesEntregaModal(true);
			// handleClose();
		}
	}, [nivelTienda, detalleEnvio?.cHoraAgendada]);
	const apiUrl = import.meta.env.VITE_API_URL;
	
	const handleClose = () => {
		setWarningOpcionesEntregaModal(false);
		if(detalleEnvio){
			handleDetalleEnvio({
				...detalleEnvio,
				bHoraPendiente: true,
				nNivelTienda: nivelesTiendaId
			});

			const body = {
				dFechaAgendada: detalleEnvio.dFechaAgendada || moment().format('YYYY-MM-DD'),
				nNivelTienda: nivelesTiendaId,
				hora: 'pendiente',
				bHoraPendiente: true
			};
			fetch(`${apiUrl}/APPWEB/ModificaFechaHoraGlobal`, {
				method: 'POST',
				headers: headersApi,
				body: JSON.stringify(body)
			});
		}
	};
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate('/');
		setWarningOpcionesEntregaModal(false);
	};

	const horaInicio = moment(nivelTienda?.nHorarioInicio).utc().format('h:mm A');
	const horaFin = moment(nivelTienda?.nHorarioFin).utc().format('h:mm A');

	return {
		nivelTienda,
		handleClose,
		open: warningOpcionesEntregaModal,
		horaInicio,
		handleGoBack,
		horaFin,
	};
};

export default useWarningTimeOutModal;