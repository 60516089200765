// Responsive

import React, { useContext, useEffect } from 'react';
import { Button, ChevronIconButton, CuponContainer, CuponesModal, DetalleProductoModal, Grid, Loading, MercadoPagoForm, OpcionesEntregaModal, Title, Typography, TextFieldPhoneNumber, TextField, ControlledSpinner, LoadingRing, BackButton, Warning, ExpansionPanelBottom, IconButton, Tag, Modal, WarningSobrePedido, EmptyCheckout, CheckBox, CardMedia } from '~/components';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useCheckout, useMediaQuery, useTitle } from '~/hooks';
import { CheckoutContainer, RoundCardContainer } from './styled';
import Logo from '../../assets/images/pana_panama.webp';
import EscudoExclamacion from '~/assets/icons/shieldExclamacion.png';
import { EditIcon, IconCircleAlert, LocationOnIcon, ScheduleIcon, StoreIcon } from '~/assets/icons';
import ProductCard from './containers/productCard';
import { getUtcDate, pricePrettify, helperBeginCheckout } from '~/utils';
import useOpcionesEntrega from '~/hooks/useOpcionesEntrega';
import HideShowComponent from '~/components/HideShowComponent';
import { AlertToastContext } from '~/context/alertToastContext';
import { CarritoTypes, PAGINAS } from '~/interfaces';
import { TIPO_ACCION_CARRITO_MODIFICAR } from '~/constants';
import { GridStyled } from '../detallePedido/styled';
import { PATHS } from '~/constants/paths';
import { Link } from 'react-router-dom';

const PADDING_TITLE = '0 15px 10px 15px';

type Props = {
	children?: JSX.Element | JSX.Element[],
};
const Checkout: React.FC<Props>  = ({children}) => {
	const {
		handleShowBack,
		showSideBarIcon,
		xs,
		navigate,
	} = useContext(GeneralLayoutContext);

	const {
		dataCarrito,
		headersApi,
	} = useContext(CookiesContext);

	const  {
		dataTiposEntregas,
		cupon,
		handleCupon,
		addCupon,
		handleAddCupon,
		handleCancelAddCupon,
		activePositionCupon,
		handleNextCupon,
		handleBackCupon,
		handleCanjearCupon,
		loadingCupon,
		loadingConsultaCupones,
		dataCupones,
		cuponError,
		openCuponesModal,
		handleOpenCuponesModal,
		handleCloseCuponesModal,
		handleCurrentCupon,
		currentCupon,
		handlePagar,
		numeroCelular,
		handleNumeroCelular,
		numeroCelularCheck,
		nombres,
		handleNombres,
		nombresCheck,
		checkoutErrors,
		gralLoading,
		finishingLoading,
		handleCancelarPedido,
		mpData,
		carrito,
		nivelCheckoutId,
		loadingCheckoutCarrito,
		total,
		showMercadoPago,
		handleShowMercadoPago,
		handleCardFormData,
		loadingMp,
		userInfo,
		tipoCheckout,
		selectedCupon,
		email, 
		handleEmail,
		handleHideMercadoPago,
		showCarrito,
		handleShowCarrito,
		showDatosContacto,
		handleDatosContacto,
		editDatosContacto,
		handleEditDatosContacto,
		openMissingFields,
		handleCloseMissingFields,
		openSobrePedidoModal,
		handleOpenSobrePedidoModal,
		handleCloseSobrePedidoModal,
		emailCheck,
		loadingRemove,
		handleloadingRemove,
		handleStoploadingRemove,
		guardarTarjetaCheck,
		handleGuardarTarjetaCheck,
		openDetalleProducto,
		descripcionModal,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleOpenDetalleProducto,
		handleClick,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		handleNivelMainId,
		handleOrdenar,
		body3D,
		loadingInit,
		carritoItem,
	} = useCheckout(headersApi);

	const editDefault = checkoutErrors.name || checkoutErrors.cel || checkoutErrors.email;

	const {
		activePosition,
		handleAddDireccion,
		tipoStep,
		getFunctionButton,
		nuevaDireccion,
		loadingButton,
		handleProgramarRecoleccion,
		dataSucursales,
		sucursal,
		handleSucursal,
		dataCiudades,
		ciudad,
		handleCiudad,
		loadingSucursales,
		getDisabledButton,
		handleProgramarEnvio,
		fechaRecoleccion,
		handleFechaRecoleccion,
		horas,
		horaRecoleccion,
		handleHoraRecoleccion,
		detalleEnvio,
		today,
		handleCancelar,
		handleGoToModificarDomicilio,
		dataDirecciones,
		currentDireccion,
		handleCurrentDireccion,
		handleReset,
		open,
		handleClose,
		handleOpen,
		handleDataCarrito,
		// handleOpenModalByTipoPedido,
		loadingCheckout,
		handleEntregarAhora,
		handleContinue,
		errorDate,

		handleClickRecibirDomicilio,
		handleClickRecibirSucursal,
	} = useOpcionesEntrega({ 
		nivelesTiendaId: nivelCheckoutId || undefined, 
		isCheckout: true, 
		nCupon: currentCupon?.nCupon || null 
	});

	const checkoutTwoRows = useMediaQuery(952);

	const {showBlackAlert} = useContext(AlertToastContext);

	const handleRemove = (item: CarritoTypes) => {
		handleloadingRemove(item.nPedido || 0);

		handleModificaCarrito(TIPO_ACCION_CARRITO_MODIFICAR, item.nVProducto, item.nProductoSIP, 0, undefined, item.nPedido, item.jExtras, undefined, ()=> {
			handleStoploadingRemove(); 
			handleOpenDetalleProductoModal(false); 
			showBlackAlert('Producto eliminado');
		}, true);
	};

	useTitle(PAGINAS.TERMINAR_COMPRA);

	useEffect(() => {
		if (userInfo?.invitado) {
			navigate(PATHS.Auth);
		}
	}, []);

	useEffect(() => {
		handleShowBack('/', 'Volver a la tienda',checkoutTwoRows ? (
			<img 
				width={'119px'} height='54.5px'
				src={Logo}
				alt = "Logo Panamá"
			/>
		) : finishingLoading ? 'Validando Pago' : gralLoading ? 'Creando pedido' : 'Terminar y pagar');
	}, [finishingLoading, gralLoading, checkoutTwoRows]);
	

	useEffect(() => {
		if (carrito) {
			helperBeginCheckout(carrito,selectedCupon?.cNombre);
		}
	}, [!!carrito]);

	if(!dataTiposEntregas || !dataCarrito || !dataDirecciones || !nivelCheckoutId || loadingCheckout || loadingCheckoutCarrito || !mpData || loadingMp) {
	// if(!dataTiposEntregas || !dataCarrito || !dataDirecciones || !nivelCheckoutId || loadingCheckout || !mpData || loadingMp) {
		// console.log({
		// 	dataTiposEntregas,
		// 	dataCarrito,
		// 	dataDirecciones,
		// 	nivelCheckoutId,
		// 	loadingCheckout,
		// 	loadingCheckoutCarrito,
		// 	mpData,
		// 	loadingMp
		// });
		return <Loading></Loading>;
	}

	if(!dataCarrito[0]){
		return <EmptyCheckout emptyTitle='Carrito vacío'></EmptyCheckout>;
	}

	const detalleInfo = {
		info1: detalleEnvio?.cSucursal ? (`Sucursal ${detalleEnvio?.cSucursal}`) : detalleEnvio?.nDireccion ? (`${detalleEnvio?.cCalle || ''} ${detalleEnvio?.cNumeroExterior ? `#${detalleEnvio?.cNumeroExterior}` : ''} ${detalleEnvio?.cColonia ? `Col. ${detalleEnvio?.cColonia}` : ''}` ): 'Ingresa la dirección a la que te gustaría que enviemos tu orden.',
		info2: `${detalleEnvio?.cMunicipio} ${detalleEnvio?.cEstado}`,
		info3: detalleEnvio?.bEntregarAhora ? 'Entregar ahora' : 'Entrega programada',
		info4: detalleEnvio?.dFechaAgendada  && detalleEnvio?.dFechaAgendada  !== today ? `Fecha: ${getUtcDate(detalleEnvio?.dFechaAgendada, 'LL')}` : 'Entregar ahora',
		info5: detalleEnvio?.bHoraPendiente ? 'Pendiente' : detalleEnvio?.cHoraAgendada ? `Hora: ${detalleEnvio?.cHoraAgendada}` : detalleEnvio?.nSucursal ? 'Pasar a recoger al realizar el pago' : '',
	};

	const editmode = !gralLoading && !showMercadoPago;
	const onlyInfo = gralLoading || showMercadoPago;

	const showWarning = !!((carrito?.doneDisponibilidadProductos === false && carrito?.errorDisponibilidadProductos) || (carrito?.doneFechasDisponibles === false && carrito?.errorFechasDisponibles));

	const childrenMercadoPagoFrom = <>
		<RoundCardContainer>
			<span className='titleContainer'>
				Detalles del pago
			</span>

			<Grid container borderbottom direction='column' padding='8px 0' spacing='8px'>
				<Grid container alignItems='center' justifyContent='space-between'>
					<Typography color='titleGrey' variant='body2'>
						Subtotal
					</Typography>

					<Typography color='titleGrey' variant='body2'>
						{pricePrettify(carrito?.nTotalSinDescuento || 0)}
					</Typography>
				</Grid>

				{
					!!(carrito?.nPrecioEnvio) && <Grid borderbottom container alignItems='center' justifyContent='space-between'>
						<Typography color='titleGrey' variant='body2'>
							Costo de envío
						</Typography>

						<Typography color='titleGrey' variant='body2'>
							{pricePrettify(carrito?.nPrecioEnvio)}
						</Typography>
					</Grid>
				}

				{
					!!(carrito?.nDescuentoCupon) && <Grid borderbottom container alignItems='center' justifyContent='space-between'>
						<Typography color='titleGrey' variant='body2'>
							Descuento
						</Typography>

						<Typography color='titleGrey' variant='body2'>
							{pricePrettify(carrito?.nDescuentoCupon)}
						</Typography>
					</Grid>
				}
			</Grid>

			{
				(!showMercadoPago && !userInfo?.invitado) && <CuponContainer onClick={handleOpenCuponesModal} padding="8px 0px">
					<Grid container justifyContent='center'>
						<Grid item container direction='column' flex>
							<Typography variant='subtitle2'>
								Cupones y promociones
							</Typography>

							<Grid container alignItems='center'>
								{
									selectedCupon?.cErrorCupon && <IconCircleAlert color='red_500'></IconCircleAlert>
								}

								<Grid item flex  padding={selectedCupon?.cErrorCupon ? '0 0 0 5px' : undefined}>
									<Typography variant='regular' color={selectedCupon?.cErrorCupon ? 'red_500' : undefined}>
										{
											selectedCupon ? selectedCupon?.cErrorCupon || selectedCupon.cNombre : 'Ninguna promoción activa'
										}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<ChevronIconButton bgColor='yellow_600' chevronColor='white' onClick={handleOpenCuponesModal} />
					</Grid>
				</CuponContainer>
			}

			<Grid container padding='8px 0px' justifyContent='space-between'>
				<Typography variant='subtitle1bold'>
					Total a pagar
				</Typography>

				<Typography variant='subtitle1bold'>
					MX {pricePrettify(carrito?.nTotal || 0)}
				</Typography>
			</Grid>
		</RoundCardContainer>
	</>;

	return (
		<>
			<CheckoutContainer showSideBarIcon={showSideBarIcon} className='noselect'>
				{
					body3D ? 
						<GridStyled direction='column' container alignItems='center'>
							<Grid alignItems='center' container justifyContent='center'>
								{
									loadingInit && <Grid height='70px' width='70px' item> 
									</Grid>
								}

								<img 
									width={'119px'} height='54.5px'
									src={Logo}
									alt = "Logo Panamá"
								/>
	
								{
									loadingInit && <Grid item width='70px' height='41px'> <LoadingRing color="green"/></Grid>
								}
	
							</Grid>
							<Grid textAlign='center' item padding='0 0 10px 0'>
								<Title padding={PADDING_TITLE} variant={xs ? 'h8' : 'h7'}>{loadingInit ? 'Por favor, no cierres esta ventana' : 'Validando Pago'}</Title>
							</Grid>
							<div id='containerDiv'></div>
						</GridStyled>
						: <>
							<div className='item-checkout-container leftSide'>
								<Grid alignItems='center' container justifyContent='center'>
									{
										gralLoading && <Grid height='70px' width='70px' item> 
										</Grid>
									}

									{
										finishingLoading ? <Grid item width='70px' height='41px'> <LoadingRing color="green"/></Grid> : gralLoading ? <ControlledSpinner handleFinish={handlePagar}/> : undefined
									}
								</Grid>

								{checkoutTwoRows && <Grid container justifyContent='center' textAlign='center'>
									<Typography variant='subtitle1bold'>{finishingLoading ? 'Validando Pago' : gralLoading ? 'Creando pedido' : 'Terminar y pagar'}</Typography>
								</Grid>}

								{
									showWarning && <Warning title={(carrito.doneFechasDisponibles === false ? carrito.errorFechasDisponibles : carrito.errorDisponibilidadProductos) || ''}></Warning>
								}


								<RoundCardContainer padding='8px 16px' margin='19px 0 0 0'>
									{
										dataCarrito[0].carrito.length !== 0 ? (
											<ExpansionPanelBottom 
												title={
													<Grid container direction='column'>
														<Typography variant='subtitle1bold'>Resumen de tu orden</Typography>
														<Typography variant='captionRegular'>{dataCarrito[0].carrito.length} Producto{dataCarrito[0].carrito.length === 1 ? '' : 's'}</Typography>
													</Grid>
												} 
												open={showCarrito} 
												handleOpen={handleShowCarrito} 
												bordertop={false} 
												hideChevron={!(dataCarrito[0].carrito.length > 0)}
											>
												<Grid container direction='column' spacing='8px' padding='8px 0px'>
													{
														dataCarrito[0]?.carrito?.map((item, e) => (
															<ProductCard 
																loadingRemove={loadingRemove === item.nPedido} 
																handleRemove={handleRemove} 
																disabled={onlyInfo}
																onClick={handleOpenDetalleProducto} 
																item={item} 
																key={e} 
															/>
														))
													}
												</Grid>
											</ExpansionPanelBottom>
										) : (
											<EmptyCheckout emptyTitle='Carrito vacío' />
										)
									}
								</RoundCardContainer>

								<HideShowComponent show={editmode}>
									<RoundCardContainer padding='16px' margin='8px 0 0 0'>
										<Grid container alignItems='center' borderbottom justifyContent='flex-start' padding='4px 0px'>
											<Typography variant='subtitle1bold'>Datos de entrega</Typography>
										</Grid>

										<Grid container padding='8px 0px 0px 0px'>
											{
												(checkoutErrors.domicilio || checkoutErrors.fechaYhora || (!detalleEnvio?.nSucursal && !detalleEnvio?.nDireccion) || detalleEnvio?.cValorHoraAgendada === 'Pendiente') && <Typography variant='subtitle1bold' color='red_500'>Campos requeridos: </Typography>
											}
	
											<Grid width='100%' container direction='row' flexWrap='nowrap' spacing='8px' padding='8px 0px' borderbottom>
												{
													detalleEnvio?.nSucursal ? (
														<Grid container alignItems='center' justifyContent='center' item>
															<StoreIcon />
														</Grid>
													) : (
														<Grid container alignItems='center' justifyContent='center' item width='24px'>
															<LocationOnIcon style={{transform: 'scale(1.1)'}} />
														</Grid>
													)
												}
	
												<Grid cursor='pointer' flex width='100%' spacing='4px' container direction='column' onClick={handleOpen}>
													<Grid spacing='4px' alignItems='center' container>
														<Grid item >
															<Typography lineHeight='1' variant='subtitle2'>
																{detalleEnvio?.nSucursal ? 'Recoger en sucursal' : 'Entrega a domicilio'}
															</Typography>
														</Grid>
														{/* {
															detalleEnvio?.cTipoDomicilio && (
																<Tag>
																	{detalleEnvio.cTipoDomicilio}
																</Tag>
															)
														} */}
													</Grid>
	
													<Grid cursor='pointer' onClick={handleOpen} container alignItems='center' spacing='7px'>
														<Grid container item flex alignItems='flex-start' direction='column'>
															<Typography variant='subtitle2regular'>
																{detalleInfo.info1}, {detalleInfo.info2}
															</Typography>
														</Grid>
	
														{/* {
															(checkoutErrors.domicilio || (!detalleEnvio?.nDireccion && !detalleEnvio?.nSucursal)) && <IconCircleAlert color='red_500'></IconCircleAlert>
														} */}
													</Grid>
												</Grid>

												<Grid item width='20px' container alignItems='center' justifyContent='center'>
													<ChevronIconButton  />
												</Grid>
											</Grid>

											<Grid container width='100%' direction='row' flexWrap='nowrap' padding='8px 0px' spacing='8px' borderbottom>
												<Grid container alignItems='center' justifyContent='center' item>
													<ScheduleIcon />
												</Grid>

												<Grid container width='100%' direction='column' spacing='4px' onClick={handleOpen} cursor='pointer'>
													<Typography pointer onClick={handleOpen} variant='subtitle2'>
														{detalleInfo.info3}
													</Typography>

													<Grid cursor='pointer' onClick={handleOpen} container alignItems='flex-start'>
														<Grid item flex textAlign='justify' container direction='column'>
															<Typography variant='subtitle2regular'>
																{detalleInfo.info4}
															</Typography>
															<Typography variant='subtitle2regular'>
																{detalleInfo.info5}
															</Typography>
														</Grid>

														{/* {
															(checkoutErrors.fechaYhora || detalleEnvio?.cValorHoraAgendada === 'Pendiente' || !detalleEnvio?.cValorHoraAgendada) && <IconCircleAlert color='red_500'></IconCircleAlert>
														} */}
													</Grid>
												</Grid>
												
												<Grid item width='20px' container alignItems='center' justifyContent='center'>
													<ChevronIconButton  />
												</Grid>
											</Grid>
										</Grid>
									</RoundCardContainer>
								</HideShowComponent>

								<HideShowComponent show={onlyInfo}>
									<RoundCardContainer padding='16px' margin='8px 0 0 0'>
										<span className='titleContainer'>
											Datos de entrega
										</span>

										{
											(checkoutErrors.domicilio || checkoutErrors.fechaYhora) && <Typography variant='subtitle1bold' padding='5px 0 0 0' color='red_500'>Campos requeridos: </Typography>
										}

										<Grid spacing='4px' container padding={detalleEnvio?.cTipoDomicilio ? '8px 0 2px 0' : '8px 0 8px 0'}>
											<Grid item >
												<Typography  variant='subtitle2'>
													{detalleEnvio?.nSucursal ? 'Recoger en sucursal' : 'Entrega a domicilio'}
												</Typography>
											</Grid>
											{
												detalleEnvio?.cTipoDomicilio && <Tag>
													{detalleEnvio.cTipoDomicilio}
												</Tag>
											}
										</Grid>

										<Grid margin='0 0 8px 0' borderbottom padding='0 0 8px 0' container alignItems='center' spacing='8px'>
											<Grid item padding='0 5px 0 0'>
												{
													detalleEnvio?.nSucursal ? <StoreIcon /> : <LocationOnIcon></LocationOnIcon>
												}
				
											</Grid>
											<Grid container item flex alignItems='flex-start' direction='column'>
												<Typography variant='subtitle2regular'>
													{detalleInfo.info1}
												</Typography>
												{
													detalleEnvio?.cMunicipio && <Typography  variant='subtitle2regular'>
														{detalleInfo.info2}
													</Typography>
												}
											</Grid>
										</Grid>

										<Typography padding='8px 0 8px 0' variant='subtitle2'>
											{detalleInfo.info3}
										</Typography>

										<Grid container alignItems='flex-start'>
											<Grid item padding='0 5px 0 0'>
												<ScheduleIcon></ScheduleIcon>
											</Grid>
											<Grid item flex textAlign='justify' container direction='column'>
												<Typography variant='subtitle2regular'>
													{detalleInfo.info4}
												</Typography>
												<Typography variant='subtitle2regular'>
													{detalleInfo.info5}
												</Typography>
											</Grid>
										</Grid>
									</RoundCardContainer>
								</HideShowComponent>

								<RoundCardContainer padding='16px' margin='9px 0 0 0'>
									<ExpansionPanelBottom 
										title={
											<Grid container direction='column' padding='4px 0 0 0'>
												<Typography variant='subtitle1bold'>Datos del contacto</Typography>
											</Grid>
										}
										preview={
											<Grid item container borderbottom padding='16px 0 8px 0'>
												{
													((editDatosContacto  || editDefault) && !onlyInfo) ? (
														<TextField 
															fullWidth 
															edit={true} 
															{...(checkoutErrors.name ? {error: true, helperText: 'Campo requerido'} : nombresCheck)} 
															onChange={handleNombres} 
															value={nombres} 
															label='Nombre de quien recibe' 
															placeholder='Nombre de quien recibe'
														/>
													) : (
														<>
															<Grid item flex container direction='column'>
																<Typography variant="subtitle2">Nombre de quien recibe</Typography>
																<Typography variant='subtitle2regular'>{nombres}</Typography>
															</Grid>

															{
																editmode && !onlyInfo && <Grid item>
																	<IconButton onClick={handleEditDatosContacto}><EditIcon /></IconButton>
																</Grid>
															}
														</>
													)
												}
											</Grid>
										}
										open={showDatosContacto || editDefault || onlyInfo} 
										handleOpen={handleDatosContacto} 
										bordertop={false} 
										hideChevron={!(dataCarrito[0].carrito.length > 0) || onlyInfo}
									>
										<Grid container direction='column' spacing='8px' padding='8px 0px'>
											{
												(editDatosContacto || editDefault) && !onlyInfo ? (
													<>
														<TextFieldPhoneNumber 
															fullWidth 
															edit={true} 
															{...(checkoutErrors.cel ? {error: true, helperText: 'Campo requerido'} : numeroCelularCheck)} 
															value={numeroCelular} 
															countries={['MX']} 
															onChange={handleNumeroCelular} 
															label='Número celular'
														/>

														<TextField 
															edit={true} 
															{...(checkoutErrors.email ? {error: true, helperText: 'Campo requerido'} : emailCheck)} 
															value={email} 
															onChange={handleEmail} 
															margin='5px 0 0 0' 
															fullWidth 
															placeholder='Email' 
															label='Email' 
															inputId={'form-checkout__cardholderEmail' }
														/>
													</>
												) : (
													<>
														<Grid container borderbottom padding='8px 0px' direction='column'>
															<Typography variant="subtitle2">Número celular</Typography>
															<Typography variant='subtitle2regular'>{numeroCelular}</Typography>
														</Grid>

														<Grid container borderbottom padding='8px 0px' direction='column'>
															<Typography variant="subtitle2">Email</Typography>
															<Typography variant='subtitle2regular'>{email}</Typography>
														</Grid>
													</>
												)
											}
										</Grid>
									</ExpansionPanelBottom>
								</RoundCardContainer>
							</div>

							<div className='item-checkout-container'>
								{childrenMercadoPagoFrom}

								<Grid padding='8px 0px' container alignItems='center'>
									{
										userInfo?.invitado ? null : <CheckBox disabled={showMercadoPago || onlyInfo} onClick={handleGuardarTarjetaCheck} checked={guardarTarjetaCheck}></CheckBox>
									} 

									{
										userInfo?.invitado ? null : <Grid item flex>
											<Typography variant="subtitle2" onClick={!showMercadoPago ? handleGuardarTarjetaCheck : undefined} pointer={!showMercadoPago}>Guardar los datos de mi tarjeta para futuras compras.</Typography>
										</Grid>
									}
								</Grid>

								{
									!userInfo?.bCorreoConfirmado ? (
										<Grid padding='12px' borderRadius='12px' bgcolor='profileBg' border={'1px solid rgba(254, 215, 170, 1)'} container direction='row' spacing='16px' textAlign='center' flexWrap='nowrap'>
											<CardMedia src={EscudoExclamacion} staticImg maxwidth='18px' />

											<Grid container direction='column'>
												<Typography textAlign='start' variant='subtitle2'>Verifica tu correo</Typography>
												<Typography textAlign='start' variant='subtitle2regular'>Tu comprobante de pago se enviará a <b>{userInfo?.cCorreo}</b>. Si el correo es incorrecto, <b><Link to='/perfil'>actualízalo en tu perfil</Link></b>.</Typography>
											</Grid>
										</Grid>
									) : <></>
								}

								{
									total && (!showMercadoPago || gralLoading) ? (
										<Grid width='100%' position={checkoutTwoRows ? undefined : 'fixed'} zindex={1000} left='0px' bottom='0px' container justifyContent='center' boxShadow={checkoutTwoRows ? undefined : '0px -6px 34px 0px rgba(0, 0, 0, 0.16)'} bgcolor='white' padding={checkoutTwoRows ? '32px 0 0 0' : '8px 16px'}>
											{!gralLoading ? (
												<Button 
													disabled={showWarning} 
													onClick={() => {
														window.fbq('track', 'InitiateCheckout', {
															currency: 'MXN',
															value: carrito?.nTotal,
															content_type: 'product',
															num_items: carrito?.carrito?.length,
															content_ids: carrito?.carrito?.map((producto) => producto.nVProducto.toString()),
															contents: carrito?.carrito?.map((producto) => ({
																id: producto.nVProducto.toString(),
																quantity: producto.nCantidad,
															})),
														});

														handleOpenSobrePedidoModal(handleContinue);
													}} 
													variant='solid'
													width={checkoutTwoRows ? '100%' : '374px'}
												>
													{`Ir a pagar ${pricePrettify(carrito?.nTotal || 0)}`}
												</Button>
											) : (
												<Button 
													onClick={handleCancelarPedido} 
													variant='solid'
													width={checkoutTwoRows ? '100%' : '374px'}
												>
													Cancelar pedido
												</Button>
											)}
										</Grid>
									) : (
										<Grid padding='32px 0 0 0'>
											<div className='footer'>
												<BackButton padding="0px" onClick={handleHideMercadoPago} title='Editar mi orden'></BackButton>
											</div>

											{tipoCheckout === '2' ? children : <MercadoPagoForm 
												handleCardFormData={handleCardFormData}
												nTotal={carrito?.nTotal || 0}>
											</MercadoPagoForm>}
										</Grid>
									)
								}
							</div>
						</>
				}
			</CheckoutContainer>

			<CuponesModal
				loadingCupon={loadingCupon}
				handleCanjearCupon={handleCanjearCupon}
				activePosition={activePositionCupon}
				handleBack={handleBackCupon}
				handleNext={handleNextCupon}
				handleCurrentCupon={handleCurrentCupon}
				currentCupon={currentCupon}
				xs={xs}
				open={openCuponesModal} 
				handleClose={()=>{
					if(currentCupon === null){
						handleDataCarrito();
					}
					handleCloseCuponesModal();
				}} 
				cupon={cupon}
				handleCupon={handleCupon}
				addCupon={addCupon}
				handleAddCupon={handleAddCupon}
				handleCancelAddCupon={handleCancelAddCupon}
				loadingConsultaCupones={loadingConsultaCupones}
				dataCupones={dataCupones}
				cuponError={cuponError}
			/>

			<OpcionesEntregaModal 
				handleEntregarAhora={handleEntregarAhora}
				errorDate={errorDate}
				today={today}
				carrito={carrito}
				detalleEnvio={detalleEnvio}
				activePosition={activePosition}
				handleAddDireccion={handleAddDireccion}
				tipoStep={tipoStep}
				getFunctionButton={getFunctionButton}
				nuevaDireccion={nuevaDireccion}
				loadingButton={loadingButton}
				handleProgramarRecoleccion={handleProgramarRecoleccion}
				dataSucursales={dataSucursales}
				sucursal={sucursal}
				handleSucursal={handleSucursal}
				dataCiudades={dataCiudades}
				ciudad={ciudad}
				handleCiudad={handleCiudad}
				loadingSucursales={loadingSucursales}
				getDisabledButton={getDisabledButton}
				handleProgramarEnvio={handleProgramarEnvio}
				fechaRecoleccion={fechaRecoleccion}
				handleFechaRecoleccion={handleFechaRecoleccion}
				horas={horas}
				horaRecoleccion={horaRecoleccion}
				handleHoraRecoleccion={handleHoraRecoleccion}
				isCheckout={true}
				handleCancelar={handleCancelar}
				handleGoToModificarDomicilio={handleGoToModificarDomicilio}
				dataDirecciones={dataDirecciones}
				currentDireccion={currentDireccion}
				handleCurrentDireccion={handleCurrentDireccion}
				handleReset={handleReset}
				open={open} 
				handleClose={handleClose} 

				nivelTienda={nivelCheckoutId}
				handleClickRecibirDomicilio={handleClickRecibirDomicilio}
				handleClickRecibirSucursal={handleClickRecibirSucursal}
			/>

			<DetalleProductoModal 
				openDetalleProducto={openDetalleProducto}
				productoId={productoId}
				handleOpenDetalleProductoModal={handleOpenDetalleProductoModal}
				handleProductoId={handleProductoId}
				handleClick={handleClick}
				handleRemove={handleRemove}
				loadingRemove={loadingRemove !== null}
				handleDataCarrito={handleDataCarrito}
				handleModificaCarrito={handleModificaCarrito}
				carritoLoading={carritoLoading}
				carritoItem={carritoItem}
				nivelMainId={nivelMainId}
				handleNivelMainId={handleNivelMainId}
				handleOrdenar={handleOrdenar}
				detalleEnvio={detalleEnvio}
				loadingCheckoutCarrito={loadingCheckoutCarrito}
				itemList={'Dentro del Checkout'}
			/>  

			<Modal 
				action={
					<Grid container spacing='5px' padding='25px 15px 15px 15px'>
						<Grid item flex>
							<Button fullWidth variant='solid' onClick={handleCloseMissingFields}>
								De acuerdo
							</Button>
						</Grid>  
					</Grid>
				}
				maxWidth='370px' 
				padding='20px 15px 0 15px' 
				open={openMissingFields} 
				handleClose={handleCloseMissingFields}
			>
				<Grid container justifyContent='center' padding='0 0 5px 0'>
					<IconCircleAlert width='80px' height='80px' color="red_500" />
				</Grid>

				<Grid item container textAlign='center'>
					<Title variant='h7'>Antes de continuar, por favor llene los siguientes campos:</Title>
				</Grid>

				<ul>
					{
						checkoutErrors.name ? <li>
							<Typography variant='subtitle1regular'>Nombre de quien recibe</Typography>
						</li> : null
					}
					{
						checkoutErrors.cel ? <li>
							<Typography variant='subtitle1regular'>Número celular</Typography>
						</li> : null
					}
					{
						checkoutErrors.email ? <li>
							<Typography variant='subtitle1regular'>Email</Typography>
						</li> : null
					}
				</ul>
			</Modal>

			<WarningSobrePedido 
				descripcion={descripcionModal} 
				handleClickItem={()=>handleContinue(handleShowMercadoPago)} 
				handleClose={handleCloseSobrePedidoModal} 
				open={openSobrePedidoModal} 
			/>

			{/* <InfoModal bEnCheckOut nNivelTienda={nivelCheckoutId} /> */}
		</>
	);
};

export default (Checkout);