import React from 'react';
import { getIsTouchDevice } from '~/utils';
import { ScrollingCarousel, SliderProps } from '../ScrollCarrousel/components/scrolling-carousel';

type Props = {
    children: JSX.Element[],
	gap?: string,
	margin?: string,
	title?: string,
	subTitle?: string,
	arrowLocation?: SliderProps['arrowsLocation'],
};

const CarrouselCard : React.FC<Props> = ({ children, gap, title, subTitle, arrowLocation } : Props) => {

	const scrollMood = getIsTouchDevice();

	return (
		<ScrollingCarousel title={title} subTitle={subTitle} arrowsLocation={scrollMood ? 'none' : arrowLocation} gap={gap}>
			{
				children.map((child, i) => (
					<div key={i} style={{paddingRight: gap || '5px'}}>{child}</div>
				))
			}
		</ScrollingCarousel>
	);
};
export default (CarrouselCard);

/* 
import React, { useEffect, useRef, useState } from 'react';
import { RightIcon } from '~/assets/icons';
import { CarrouselCardContainer, CarrouselHiddenContainer, ItemContainer, ScrollContainer } from './styled';
import _ from 'lodash';
import { getEmptyColumns, getIsTouchDevice } from '~/utils';
import Grid from '../Grid';
import { LeftIcon } from '../../assets/icons/index';
import IconButton from '../IconButton';
type Props = {
    children: JSX.Element[],
	gap?: string,
	margin?: string,
};



const CarrouselCard : React.FC<Props> = ({ children, gap, margin} : Props) => {



	const [loading, setLoading] = useState(true);
	// Hooks para el carrousel
	const [activePosition, setActivePosition] = useState(0);

	const handleNext = () => {
		setActivePosition(activePosition + 1);
	};
	const handleBack = () => {
		setActivePosition(activePosition - 1);
	};
	// Container del carrousel
	const carrouselContainer = useRef<HTMLInputElement>(null);
	// Cada item que se va a mapear en el carrousel
	const cardItem = useRef<HTMLInputElement>(null);
	// Número de items por container
	const [itemsSize, setItemsSize] = useState(1);
	
	// Regresa los items ordenados de itemsSize en itemsSize
	const newItem = _.chunk(children, itemsSize);
	
	// Valida si el botón de next debe estar mostrándose
	const hideNextbutton = ((activePosition || 0) + 1) === newItem.length;

	// Checa cuantos items caben en el container dependiendo del tamaño del item que se mapee

	const [widthItem, setwidthItem] = useState<number | null>(null);
	const [widthContainer, setwidthContainer] = useState<number | null>(null);
	
	const updatePosition = () => {
		
		if(cardItem &&  cardItem.current && carrouselContainer &&  carrouselContainer.current){
			const carrouselContainerCurrent = carrouselContainer.current;
			const cardItemCurrent = cardItem.current;
			setwidthItem(cardItemCurrent.offsetWidth);
			setwidthContainer(carrouselContainerCurrent.offsetWidth);
		
			
			const helperItemsPerContainer = (Math.floor( (carrouselContainerCurrent.offsetWidth) / (cardItemCurrent.offsetWidth + (4))));
			
			setItemsSize(helperItemsPerContainer);
			setLoading(false);
			
		} else if (widthItem && widthContainer && loading === false) {
			const helperItemsPerContainer = (Math.floor( (carrouselContainer?.current?.offsetWidth || widthContainer) / (widthItem + (4))));
			setItemsSize(helperItemsPerContainer);
		}
	};
	
	useEffect(() => {
		updatePosition();
	}, [carrouselContainer?.current?.offsetWidth]);

	// Checa si el size de la pantalla cambia para volver a calcular

	/* 	useEffect(() => {
	
		window.addEventListener('resize', updatePosition);
	});


	const scrollMood = getIsTouchDevice();

	const notCarrousel = !!(loading || !carrouselContainer || !cardItem || newItem.length === 1 || scrollMood);


	if(scrollMood) {
		return <ScrollContainer>
			{
				children.map(item => (
					item
				))
			}
		</ScrollContainer>;
	} else {
		return (
			<Grid container alignItems='center' width='100%' margin={margin ? margin : '0 0 15px 0'}>
				{
					!notCarrousel && <Grid container item width='50px' justifyContent='flex-end' alignItems='center'>
						<IconButton disabled={(activePosition) === 0} size='44px' onClick={() => {handleBack(); updatePosition();}} >
							<LeftIcon color={(activePosition) === 0 ? 'grey' : 'primary'}></LeftIcon>
						</IconButton>
					</Grid>
				}
				
				<CarrouselHiddenContainer ref={carrouselContainer}>
					
					<CarrouselCardContainer notCarrousel={notCarrousel} marginleft={(activePosition ? `-${activePosition}00%` : null)}>
	
	
						<div className='div' >
							{newItem.map((child, i) =>(
								<ItemContainer 
									gap={gap}
									notCarrousel={notCarrousel}
									className='item' key={i}
								>
									{
										child.map((item, e) =><div ref={i === 0 ? cardItem : undefined} key={e}>
											{item}
										</div>)
									}
									{
										newItem.length > 1 && (i + 1) === newItem.length ? getEmptyColumns(child.length, itemsSize).map(item => (
											<div key={`empty${item}`} style={{width: `${cardItem && cardItem.current && cardItem.current.offsetWidth}px`}}></div>
										)) : null
									}
								</ItemContainer>))}
						</div>
	
	
	
					
					</CarrouselCardContainer>
				</CarrouselHiddenContainer>
				{
					!notCarrousel && <Grid container width='50px' alignItems='center'>
						<IconButton disabled={hideNextbutton} size='44px' onClick={() => {handleNext(); updatePosition();}} >
							<RightIcon color={hideNextbutton ? 'grey' : 'primary'}></RightIcon>
						</IconButton>
	
					</Grid>
				}
				
			</Grid>
		);
	}

	
};
export default (CarrouselCard);

*/