import React from 'react';
import ChevronIconButton from '../ChevronIconButton';
import Grid from '../Grid';
import Typography from '../Typography';
import { HelpCardContainer } from './styled';
type Props = {
    titulo: JSX.Element | JSX.Element[] | string,
	onClick: VoidFunction,
	margin?: string
};
const HelpCard : React.FC<Props> = ({ titulo, onClick, margin} : Props) => {
	return (
		<HelpCardContainer className='noselect' margin={margin} onClick={onClick}>
			<Grid item flex><Typography variant='subtitle1bold'>{titulo}</Typography></Grid>
			<ChevronIconButton onClick={onClick} />
		</HelpCardContainer>
	);
};
export default (HelpCard);