import styled from 'styled-components';

export interface TextFieldProps {
  icon?: boolean;
  open?: boolean
}

export interface TextFieldContainerInputProps {
  icon?: boolean;
  padding?: string;
}

export const TextFieldInput = styled.textarea<TextFieldProps>`
    padding: 12px 20px 12px 20px;
    border: none !important;
    resize: none;
    min-height: 60px;
    ::placeholder,
    ::-webkit-input-placeholder {
      font-family: ${props =>props.theme.text.font.primary};
      font-size: ${props =>props.theme.text.size.subtitle2};
      font-weight: ${props =>props.theme.text.weight.medium};
      color: ${props =>props.theme.colors.grey};

    }
    :-ms-input-placeholder {
      font-family: ${props =>props.theme.text.font.primary};
      font-weight: ${props =>props.theme.text.weight.medium};
      color: ${props =>props.theme.colors.grey};

    }
    flex: auto;
    font-family: ${props =>props.theme.text.font.primary};
    font-size: ${props => props.theme.text.size.subtitle1};
    background-color: ${props =>props.theme.colors.greyF5};
    &:focus {
      outline: none;
    }
`;

export const TextFieldInputType = styled.input<TextFieldProps>`
    padding: 0 20px 0 20px;
    border: none !important;
    resize: none;
    min-height: 52px;
    ::placeholder,
    ::-webkit-input-placeholder {
      font-family: ${props =>props.theme.text.font.primary};
      font-size: ${props =>props.theme.text.size.subtitle2};
      font-weight: ${props =>props.theme.text.weight.medium};
      color: ${props =>props.theme.colors.grey};

    }
    :-ms-input-placeholder {
      font-family: ${props =>props.theme.text.font.primary};
      font-weight: ${props =>props.theme.text.weight.medium};
      color: ${props =>props.theme.colors.grey};

    }
    flex: auto;
    font-family: ${props =>props.theme.text.font.primary};
    font-size: ${props => props.theme.text.size.subtitle1};
    background-color: ${props =>props.theme.colors.greyF5};
    &:focus {
      outline: none;
    }
`;



export const InputContainer = styled.div<TextFieldProps>`
  overflow: hidden;
  display: flex;
    
  flex: 1;
`;

export const TextFieldContainerInput = styled.div<TextFieldProps>`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    font-family: ${props => props.theme.text.font.primary};
    background-color: ${props => props.theme.colors.greyF5};
    

    border-radius: 4px;
    overflow: hidden;
    transition: width 0.3s ease;
    width: 100%;

    padding-right: 10px;
    flex: 1;
`;

