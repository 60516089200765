import React from 'react';
import { DataCarritoTypes } from '~/interfaces';
import { pricePrettify } from '~/utils';
import Button from '../Button';
import { Footer } from './styled';

interface Props {
	singleMenu?: boolean;
	carrito: DataCarritoTypes,
	handleCheckout: (nNivelTienda: number, onCheckout?: () => void) => void,
	handleClose: VoidFunction,
	carritoEmpty: boolean,
	disabled?: boolean
}

const CarritoFooter : React.FC<Props> = ({ carrito, singleMenu, handleCheckout, handleClose, carritoEmpty, disabled} : Props) => {
	return (
		<Footer singleMenu={singleMenu}>
			{
				carritoEmpty ? (
					<Button disabled={disabled} onClick={handleClose} color='black' variant='outline' size='lg'>
						Agregar productos
					</Button>
				) : (
					<>
						<Button disabled={disabled} onClick={()=>handleCheckout(carrito.nValor)} padding='10px' color='black' size='lg' variant='solid'>
							{`Pagar ${pricePrettify(carrito.nTotal)}`}
						</Button>

						<Button onClick={handleClose} color='black' variant='outline' size='lg'>
							Agregar más productos
						</Button>
					</>
				)
			}
		</Footer>
	);
};
export default (CarritoFooter);