import IconButton from 'components/IconButton';
import React, {useEffect} from 'react';
import { ExpandLessIcon, RemoveShoppingCartIcon } from '~/assets/icons';
import useCarritoDrawer from '~/hooks/useCarritoDrawer';
import { DataCarritoTypes, InivelesTienda, CarritoTypes, handleModificaCarritoType } from '~/interfaces';
import { ChevronUpStyled } from '~/views/sucursales/styled';
import CarritoCard from '../CarritoCard';
import CarritoFooter from '../CarritoFooter';
import CloseButton from '../CloseButton';
import Grid from '../Grid';
import Title from '../Title';
import Typography from '../Typography';
import _ from 'lodash';
import {CarritoContainer, ModalBodyContainer, ModalContainer, CarritoMenuContainer, ImgContainer, CantidadContainer} from './styled';
import { getCdnUrl, helperCartEvents, pricePrettify } from '~/utils';
import { Button, DetalleProductoModal, OpcionesEntregaModal } from '..';
import Taco from '../../assets/images/taco.webp';
import { SideModalBarContainer } from '../SideBar';
import { useDetalleProductoModal } from '~/hooks';
import useOpcionesEntrega from '~/hooks/useOpcionesEntrega';
import { createPortal } from 'react-dom';
import { PayButtonContainer } from '../GeneralLayout/styled';

type Props = {
	handleCheckout: (nNivelTienda: number, onCheckout?: () => void) => void,
	title?: JSX.Element | JSX.Element[],
	action?: JSX.Element | JSX.Element[],
	style?: React.CSSProperties,
	open?: boolean,
	handleClose: () => void,
	padding?: string,
	dataCarrito: undefined | DataCarritoTypes[],
	height?: string,
	apiUrl: string,
	handleModificaCarrito: handleModificaCarritoType,
	currentCarrito: DataCarritoTypes | undefined,
	nivelesTienda: InivelesTienda[],
	loadingVaciarCarrito: boolean,
	handleVaciarCarrito: (nNivel?: number) => void,
	nivelesTiendaId: number,
	showBack: string | null,
};

const CarritoDrawer : React.FC<Props> = ({ 
	handleClose, 
	handleCheckout, 
	open, 
	padding, 
	height, 
	dataCarrito, 
	handleModificaCarrito, 
	currentCarrito, 
	nivelesTienda, 
	loadingVaciarCarrito, 
	handleVaciarCarrito, 
	nivelesTiendaId,
	showBack,
} : Props) => {
	const {
		openDetalleProducto,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleOpenDetalleProducto,
		handleClick,
		handleDataCarrito,
		carritoLoading,
		handleModificaCarrito: handleModificaCarritoDetalle,
		nivelMainId,
		handleNivelMainId,
		handleOrdenar,
		detalleEnvio,
		carritoItem,
		loadingCheckoutCarrito,
	} = useDetalleProductoModal();

	const {
		handleIsShowing,
		isShowing,
		currentDrawerCarrito,
		handleClickMenuCarrito,
		loadingLeftSide,
		loadingRightSide,
		handleloadingLeftSide,
		handleStroploadingLeftSide,
		handleloadingRightSide,
		handleStroploadingRightSide,
	} = useCarritoDrawer(currentCarrito);

	const {
		activePosition,
		carrito,
		ciudad,
		currentDireccion,
		dataCiudades,
		dataDirecciones,
		dataSucursales,
		detalleEnvio: detalleEnvioOpcionesEntrega,
		errorDate,
		fechaRecoleccion,
		getDisabledButton,
		getFunctionButton,
		handleAddDireccion,
		handleCancelar,
		handleCiudad,
		handleClickRecibirDomicilio,
		handleClickRecibirSucursal,
		handleCurrentDireccion,
		handleEntregarAhora,
		handleFechaRecoleccion,
		handleGoToModificarDomicilio,
		handleHoraRecoleccion,
		handleProgramarEnvio,
		handleProgramarRecoleccion,
		handleReset,
		handleSucursal,
		horaRecoleccion,
		horas,
		loadingButton,
		loadingSucursales,
		nuevaDireccion,
		handleOpen: handleOpenOpcionesEntrega,
		handleClose: handleCloseOpcionesEntrega,
		open: isOpenOpcionesEntrega,
		sucursal,
		tipoStep,
		today,	
		userInfo,	
	} = useOpcionesEntrega({
		nivelesTiendaId: currentDrawerCarrito,
		isCheckout: false,
		nCupon: dataCarrito?.find((carro) => carro.nCupon === currentCarrito)?.nCupon,
		onFinishRecoleccion: () => {
			handleClose();
		}
	});

	useEffect(() => {
		const body = document.body;

		if (!open) {
			body.style.height = 'auto';
			body.style.overflowY = 'auto';
			return;
		}

		body.style.height = '100vh';
		body.style.overflowY = 'hidden';

		if (open && isShowing) {
			helperCartEvents(currentCarritoById?.carrito || [], currentCarritoById?.cValor ? `Carrito de ${currentCarritoById?.cValor}` : 'Indefinido', 'view_cart');
		}
	}, [open]);

	if(!dataCarrito){
		return <></>;
	}
	const carritoEmpty = dataCarrito.length === 0;

	const currentCarritoById = _.find(dataCarrito, o=>o.nValor === currentDrawerCarrito);

	const handleAdd = (item: CarritoTypes) => {
		handleloadingRightSide(item.nPedido || 0);

		handleModificaCarrito(2, item.nVProducto, item.nProductoSIP, item.nCantidad + 1, undefined, item.nPedido, item.jExtras, currentCarritoById?.nValor, handleStroploadingRightSide, true);
	};
	const handleRemove = (item: CarritoTypes) => {
		handleloadingLeftSide(item.nPedido || 0);

		handleModificaCarrito(2, item.nVProducto, item.nProductoSIP, (item.nCantidad - 1), undefined, item.nPedido, item.jExtras, currentCarritoById?.nValor, handleStroploadingLeftSide, true);
	};

	const handleClickVaciarCarrito = async () => {
		handleVaciarCarrito(currentCarritoById?.nValor);
		
		helperCartEvents(currentCarritoById?.carrito || [], currentCarritoById?.cValor ? `Carrito de ${currentCarritoById?.cValor}` : 'Indefinido', 'remove_from_cart');
	};

	const disabled = !!(loadingLeftSide || loadingRightSide);
	const showPayButton = ((showBack === null || showBack === '') && currentCarrito ) && nivelesTiendaId && !open;

	return (
		<>
			<SideModalBarContainer
				handleClose={handleClose}
				open={open || false}
			>
				<ModalContainer open={open} onClick={(e)=>e.stopPropagation()} height={height}>
					<Grid className='containerWidth' alignItems='center' padding='16px' container>
						<CloseButton onClick={handleClose} />

						<Grid onClick={() => {
							handleIsShowing();
						}} container justifyContent='flex-end' item flex alignItems='center'>
							<Typography className='noselect' pointer variant='subtitle1bold'>{`Carritos (${dataCarrito.length})`}</Typography>

							<IconButton
								onClick={() => {
									handleIsShowing();
								}}
							>
								<ChevronUpStyled isShowing={isShowing}>
									<ExpandLessIcon />
								</ChevronUpStyled>
							</IconButton>
						</Grid>
					</Grid> 

					{
						(dataCarrito.length > 0 && isShowing && currentCarritoById) ? (
							<Grid container direction='column' padding='8px 16px 24px 16px' spacing='8px' borderbottom>
								<Grid container alignItems='center' spacing='5px'>
									<Grid item flex>
										<Title variant='h7'>{currentCarritoById?.cValor}</Title>
									</Grid>

									<Button 
										padding='0px' 
										loading={loadingVaciarCarrito} 
										disabled={carritoEmpty} 
										leftIcon={<RemoveShoppingCartIcon color={carritoEmpty ? 'grey' : 'red_500'} />} 
										onClick={handleClickVaciarCarrito} 
										variant='underline'
										size='sm'
										color='warning'
									>
										Vaciar Carrito
									</Button>
								</Grid>

								<Grid container textAlign='center' justifyContent='center'>
									<Typography color='grey' variant='body2'>
										{currentCarritoById?.cHoraEstimadaEntrega}
									</Typography>
								</Grid>
							</Grid>
						) : (<></>)
					}

					<ModalBodyContainer padding={padding}>
						{
							carritoEmpty ? (
								<Grid className='containerWidth' textAlign='center' container direction='column' justifyContent='center' alignItems='center'>
									<Typography padding='70px 0 30px 0' variant='subtitle1bold'>
										Tu carrito de <br/> Comidas y cenas  <br/> está vacío
									</Typography>

									<Grid item>
										<img loading='lazy' draggable='false' alt='Taco - Carito vacío' height='142px' width='142px' src={Taco}></img>
									</Grid>

									<Typography padding='23px 0 0 0' variant='subtitle1bold'>
										¡Llénalo de delicias! <br/> Continúa comprando
									</Typography>
								</Grid>
							) : isShowing && currentCarritoById ? (
								<CarritoContainer>
									{
										currentCarritoById?.carrito.map((item, e) =>(
											<CarritoCard 
												loadingLeftSide={loadingLeftSide === item.nPedido}
												loadingRightSide={loadingRightSide === item.nPedido}
												handleAdd={()=>handleAdd(item)}
												handleRemove={()=>handleRemove(item)}
												key={e} 
												item={item}
												onClick={()=>handleOpenDetalleProducto(item, currentCarritoById.nValor)}
											/>
										))
									}
								</CarritoContainer>
							) : (
								<Grid container direction='column' padding='0 25px 0 25px'>
									{
										dataCarrito.map((carrito, i) => {
											const nivel = _.find(nivelesTienda, o=>o.nNivelTienda === carrito.nValor);
											return (
												<CarritoMenuContainer 
													onClick={() => {
														handleClickMenuCarrito(carrito.nValor);
														helperCartEvents(carrito?.carrito || [], carrito?.cValor ? `Carrito de ${carrito?.cValor}` : 'Indefinido', 'view_cart');
													}} 
													key={i}
												>
													<Grid item  width='50px'>
														<ImgContainer>
															<img loading='lazy' draggable='false' width='100%' alt={nivel?.cDescripcion} src={getCdnUrl(nivel?.cImagenUrl || '')}></img>
														</ImgContainer>
													</Grid>

													<Grid padding='0 5px 0 10px' container flex item direction='column'>
														<Typography padding='0 0 5px 0' variant='subtitle1bold'>{carrito.cValor}</Typography>
														<Typography className='paragraphContainer' variant='body2'>
															Subtotal: {pricePrettify(carrito.nTotal)} <br />
															{carrito?.cSucursal ? (`Sucursal ${carrito?.cSucursal}`) : carrito?.nDireccion ? (`${carrito?.cCalle || ''} ${carrito?.cNumeroExterior ? `#${carrito?.cNumeroExterior}` : ''}` ): ''}
														</Typography>
													</Grid>

													<Grid item container alignItems='center'>
														<CantidadContainer>
															<Typography color='white'>
																{carrito.nTotalCantidad}
															</Typography>
														</CantidadContainer>
													</Grid>
												</CarritoMenuContainer>
											);
										})
									}
								</Grid>
							)
						}
					</ModalBodyContainer>

					{
						currentCarritoById && isShowing ? (
							<CarritoFooter 
								disabled={disabled} 
								carritoEmpty={carritoEmpty} 
								handleClose={handleClose} 
								handleCheckout={(nivelTienda) => {
									handleCheckout(nivelTienda, () => {
										userInfo?.invitado === false && handleOpenOpcionesEntrega();
									});
								}} 
								singleMenu={true} 
								carrito={currentCarritoById} 
							/>
						) : <></>
					}
				</ModalContainer>
			</SideModalBarContainer>

			{createPortal(
				showPayButton ? <PayButtonContainer >
					<Button onClick={()=>handleCheckout(nivelesTiendaId, () => userInfo?.invitado === false && handleOpenOpcionesEntrega())} minWidth='300px' variant='solid'>
						{`Pagar ${pricePrettify(currentCarrito.nTotal)}`}
					</Button>
				</PayButtonContainer> : null, 
				document.getElementById('generalLayoutID')!
			)}

			<OpcionesEntregaModal 
				today={today}
				errorDate={errorDate}
				handleEntregarAhora={handleEntregarAhora}
				detalleEnvio={detalleEnvioOpcionesEntrega}
				activePosition={activePosition}
				handleAddDireccion={handleAddDireccion}
				tipoStep={tipoStep}
				getFunctionButton={getFunctionButton}
				nuevaDireccion={nuevaDireccion}
				loadingButton={loadingButton}
				handleProgramarRecoleccion={handleProgramarRecoleccion}
				dataSucursales={dataSucursales}
				sucursal={sucursal}
				handleSucursal={handleSucursal}
				dataCiudades={dataCiudades}
				carrito={carrito}
				ciudad={ciudad}
				handleCiudad={handleCiudad}
				loadingSucursales={loadingSucursales}
				isCheckout={false}
				getDisabledButton={getDisabledButton}
				handleProgramarEnvio={handleProgramarEnvio}
				fechaRecoleccion={fechaRecoleccion}
				handleFechaRecoleccion={handleFechaRecoleccion}
				horas={horas}
				horaRecoleccion={horaRecoleccion}
				handleHoraRecoleccion={handleHoraRecoleccion}
				handleCancelar={handleCancelar}
				handleGoToModificarDomicilio={handleGoToModificarDomicilio}
				dataDirecciones={dataDirecciones}
				currentDireccion={currentDireccion}
				handleCurrentDireccion={handleCurrentDireccion}
				handleReset={handleReset}
				open={isOpenOpcionesEntrega} 
				handleClose={handleCloseOpcionesEntrega} 
				nivelTienda={currentCarritoById?.nValor}
				handleClickRecibirDomicilio={() => {
					handleClose();
					handleClickRecibirDomicilio();
				}}
				handleClickRecibirSucursal={handleClickRecibirSucursal}
			/>

			<DetalleProductoModal 
				openDetalleProducto={openDetalleProducto}
				productoId={productoId}
				handleOpenDetalleProductoModal={handleOpenDetalleProductoModal}
				handleProductoId={handleProductoId}
				handleClick={handleClick}
				handleDataCarrito={handleDataCarrito}
				handleModificaCarrito={handleModificaCarritoDetalle}
				carritoLoading={carritoLoading}
				nivelMainId={nivelMainId}
				handleNivelMainId={handleNivelMainId}
				carritoItem={carritoItem}
				handleOrdenar={handleOrdenar}
				detalleEnvio={detalleEnvio}
				loadingCheckoutCarrito={loadingCheckoutCarrito}
				itemList={`Carrito de ${currentCarritoById?.cValor}`}
			/>  
		</>
		
	);
};
export default (CarritoDrawer);
