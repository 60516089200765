import React from 'react';
import { Button, CloseButton, Grid, Modal, Typography } from '..';
import { ButtonProps } from '../Button';
type Props = {
    handleClose: VoidFunction,
	open: boolean,
    handleAcept: VoidFunction,
	title: string,
    description: string | JSX.Element | JSX.Element[],
    width?: string,
    loading?: boolean,
	buttonsDirection?: string,
	buttonsSize?: ButtonProps['size'],
	children?: JSX.Element | JSX.Element[],
	height?: string
	cancelLabel?: string,
	aceptLabel?: string,
	acceptColor?: ButtonProps['color']
};
const WarningModal : React.FC<Props> = ({ 
	handleClose,
	open,
	title,
	description,
	width,
	loading,
	children,
	handleAcept,
	cancelLabel,
	aceptLabel,
	acceptColor,
	height = 'auto',
	buttonsDirection = 'row',
	buttonsSize = 'sm',
} : Props) => {
	return (
		<Modal
			width={'100%'}
			maxWidth={width || '341px'}
			open={open}
			handleClose={handleClose}
			height={height}
		>
			<Grid padding='16px' container justifyContent='space-between' alignItems='center' bgcolor='gray_100'>
				<Typography variant='subtitle1bold'>{title}</Typography>

				<CloseButton style={{ padding: 0 }} padding='0' containerPadding='0' styleIcon={{ padding: 0 }} onClick={handleClose} />
			</Grid>

			<Grid container padding='16px' borderbottom>
				<Typography variant='captionRegular'>{description}</Typography>
			</Grid>

			<>
				{children}
			</>

			<Grid container direction={buttonsDirection} justifyContent='flex-end' padding='8px 16px' spacing='8px'>
				<Button variant='outline' width='auto' color='black' onClick={handleClose} size={buttonsSize}>
					{cancelLabel || 'No, cancelar'}
				</Button>

				<Button loading={loading} width='auto' variant='solid' color={acceptColor ?? 'warning'} onClick={handleAcept} size={buttonsSize}>
					{aceptLabel || 'Sí, Seguro'}
				</Button>
			</Grid>
		</Modal>
	);
};
export default (WarningModal);