import styled, { css } from 'styled-components';
import { getIsTouchDevice } from '~/utils';
import { ModalProps } from '../CarritoDrawer/styled';

export interface SideBarContainerProps {
  profile?: boolean |string;
  showSideBar?: boolean;
}

export const SideBarContainer = styled.div<SideBarContainerProps>`
    border-bottom: 5px solid ${props => props.theme.colors.red_500};
    display:block;
    width: 0px;

    width: ${props => props.showSideBar ?  ('395px') : '0px'};
    transition: all 0.3s ease-in-out;

    left: 0;
    bottom: 0;
    display: flex;
    z-index: 1010;
    flex-direction: column;
    overflow-x: hidden;
    .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 395px;
        // padding: 1rem 1rem 1rem 1rem;
        

        @media (max-width: 824px) {
            // padding: 1rem 1rem 1rem 1rem;
            min-width: 300px;
        }
    }
    

    @media (max-width: 824px) {
        width: ${props => props.showSideBar ?  ('100%') : '0px'};
        max-width: 395px;
    }

    justify-content: flex-start;
    .body-side-bar {
		max-height: 100%;
		overflow-y: auto;
		height: fit-content;
		flex: auto;
    }

    position: fixed;
    top: 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background: ${props => props.theme.colors.white};
`;

export const TopContainer = styled.div`
    background: ${props => props.theme.colors.peach};
    padding: 1rem;
    width: 100%;
    
    display: flex;
    justify-content: center;
    height: 80px;
`;

interface Props {
	container?: boolean;
	item?: boolean;
	flex?: boolean | number;
	spacing?: string;
	justifyContent?: string;
	alignItems?: string;
	flexWrap?: string;
	cursor?: string;
}

export const ProfileGrid = styled.div<Props>`
	${props => props.container ? css`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: ${props.spacing || '0'};
	justify-content: ${props.justifyContent || 'start'};
	align-items: ${props.alignItems || 'stretch'};
	` : null};

	${props => props.item ? css`
	${props.flex ? `flex: ${props.flex === true ? '1' : props.flex};` : ''}
	` : null};

	${props => props.cursor ? css`
	cursor: ${props.cursor};

	> * {
		cursor: inherit;
	}
	` : null}
	
`;

export const Modal = styled.div<ModalProps>`
  visibility: hidden;
  
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;

	${(props) => getIsTouchDevice() ? null : `
			::-webkit-scrollbar {
				-webkit-appearance: none;
			}

			::-webkit-scrollbar:vertical {
				width: 5px;
			}

			::-webkit-scrollbar:horizontal {
				height: 12px !important;
			}

			::-webkit-scrollbar-thumb {
				background-color: ${props.theme.colors.red_500};
				border: 0px;
			}
    
            ::-webkit-scrollbar-track {
                border-radius: 0px;
                background-color: ${props.theme.colors.red_100};
            }
		`}
  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(77, 77, 77, .7);
  z-index: 1001;
  ${props => props.open ? `
    visibility: visible;
  ` : null}
`;