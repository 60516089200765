import React from 'react';
import { EMPTY_TITLES } from '~/constants/contents';
import Grid from '../Grid';
import { EmptyContainer } from './styled';
import EmptyOopss from '../../assets/images/EmptyOopss.webp';
import Button from '../Button';
import Typography from '../Typography';
import { useNavigate } from 'react-router-dom';
type Props = {
	emptyTitle?: string | JSX.Element | JSX.Element[],
	emptySubtitle?: string | JSX.Element | JSX.Element[],
	children?: JSX.Element | JSX.Element[] | string,
	buttonLabel?: string,
};
// empty component sin diseño
const Empty : React.FC<Props> = ({emptyTitle, children, emptySubtitle, buttonLabel}: Props) => {
	const navigate = useNavigate();

	const handleGoToMain = () => {
		navigate('/');
	};
	return (
		<Grid direction='column' alignItems='center' item textAlign='center' justifyContent='center' container padding='25px 0 0 0'>
			<EmptyContainer>
				<Grid container padding='0 0 22px 0' justifyContent='center'>
					<img 
						width={'200px'}
						src={EmptyOopss}
						draggable="false"
						loading="lazy"
						alt="Logo Panamá"
					/>
				</Grid>
				<div className='textContainer'>
					<Typography variant='subtitle1bold'>
						{emptyTitle || EMPTY_TITLES.title1}
					</Typography>
				</div>

				{
					emptySubtitle && <Grid padding='10px 0 0 0' item>
						<div className='textContainer'>
							<Typography  variant='body2'>
								{emptySubtitle}
							</Typography>
						</div>
					</Grid>
				}
				{
					buttonLabel && <Grid padding='34px 0 0 0' width='100%' item maxwidth='342px'><Button onClick={handleGoToMain} width='100%' variant='solid'>
						{buttonLabel}
					</Button></Grid>
				}
				
				{children}

			</EmptyContainer>
		</Grid>
	);
};
export default (Empty);