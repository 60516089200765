import React from 'react';
import { IconCircleAlert, SupportAgentIcon } from '~/assets/icons';
import { Button, Grid, Typography, Loading, Container } from '~/components';
import { GridStyled } from './styled';
import useHistorialOrdeneDetalle from '~/hooks/useHistorialOrdenesDetalle';
import { getUtcDate, pricePrettify } from '~/utils';
import { useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';

type Props = {
	main?: boolean,
};

const DetallePedido : React.FC<Props> = ({main}: Props) => {
	const {
		orden,
		loading,
		handleVolverAPedir,
		loadingReenviar,
		handleReenvioTicket,
	} = useHistorialOrdeneDetalle(undefined, undefined, main);

	useTitle(PAGINAS.DETALLE_PEDIDO);

	if(!orden){
		return <Loading />;
	}

	return (
		<Container maxWidth="343px" padding='31px 0 32px 0' center>
			<div className='responsiveContainer'>
				{
					orden.error && <Grid padding='0 0 5px 0' alignItems='center' container>
						<IconCircleAlert width='20px' height='20px' color="red_500" />
						<Grid item flex>
							<Typography padding='0 0 0 5px' variant='caption' color='red_500'>
								{orden.error}
							</Typography>
						</Grid>
					</Grid>
				}
				<GridStyled margin='0 0 8px 0' width='100%' container direction='column'>
					<Typography variant='subtitle1bold'> {orden?.cTipoPedido} ({orden?.cSucursal})</Typography>
					<Typography variant='subtitle2' color='black' margin='12px 0'>Orden</Typography>
					<Grid container direction='column' spacing='4px' borderbottom={true} >
						{
							orden.carrito.map((item, i) => {
								const extras = (item.jExtras).filter(o=>o.nPrecio_Base_Total);
								const variantes = (item.jExtras).filter(o=>(!o.nPrecio_Base_Total));
								return (
									<Grid container direction='column' padding='0 0 10px 0' key={i}>
										
										<Grid container alignItems='flex-start'>
											<Grid item flex> <Typography variant='subtitle2regular'>
												{item.nCantidad > 0 ? `${item.nCantidad}x ` : ''}{item.cDescripcion}{variantes.length > 0 ? ` (${variantes?.map(e => `${(e?.nCantidad || 0) > 1 ? `${e.nCantidad}x ` : ''}${e.cDescripcion}${e.nPrecio_Base ? ` +${pricePrettify(e.nPrecio_Base)}` : ''}`).join(', ')})` : ''} 
											</Typography></Grid>
											<Typography padding='0 0 0 10px' variant='subtitle2bold'>
												{
													pricePrettify(item.nTotal || item.nPrecio_Base)
												}
											</Typography>
										</Grid>
										{
											extras?.map((ex, k) => (
												<Grid key={k} container alignItems='flex-start'>
													<Grid item flex> <Typography variant='subtitle2regular'>
														{`${(ex?.nCantidad || 0) > 1 ? `${ex.nCantidad}x ` : ''}${ex.cDescripcion}`} 
													</Typography></Grid>
													{
														ex.nPrecio_Base_Total && <Typography padding='0 0 0 10px' variant='subtitle2bold'>
															{
																pricePrettify(ex.nPrecio_Base_Total)
															}
														</Typography>
													}
												</Grid>
											))
										}
										
										{
											item.cComentarios && <>
												<Typography variant='subtitle2'>
													Comentarios
												</Typography>
												<Typography variant='subtitle2regular'>
													{item.cComentarios}
												</Typography>
											</>
										}
										
									</Grid>
								);
							})
						}
						{
							(!!orden.nCostoDomicilio) && <Grid container justifyContent='space-between' padding='5px 0 5px 0'>
								<Typography variant='regular'>Costo de envío</Typography>
								<Typography variant='regular'>{pricePrettify(orden.nCostoDomicilio)}</Typography>
							</Grid>
						}
						
						{/* <Typography variant='subtitle2' color='black' margin='8px 0'>Comentarios</Typography>
						<Typography variant='regular' margin='0 0 12px 0'>{'comentarios'}</Typography> */}
					</Grid>
					{
						!!(orden.nDescuentos) && <Grid container justifyContent='space-between' alignItems='center'>
							<Typography variant='subtitle2' color='black' margin='8px 0 0 0'>Descuento</Typography>
							<Typography variant='regular'>{pricePrettify(orden.nDescuentos)}</Typography>
						</Grid>
					}
					<Grid margin='8px 0 0 0' container justifyContent='space-between' alignItems='center'>
						<Typography variant='subtitle1' color='black'>Total</Typography>
						<Typography variant='subtitle1bold'>{pricePrettify(orden.nTotal)}</Typography>
					</Grid>
				</GridStyled>
				<GridStyled width='100%' container direction='column'>
					<Typography variant='subtitle1bold'> Detalles del pedido</Typography>
					<Typography variant='subtitle2' color='black' margin='8px 0'>Fecha y hora de entrega</Typography>
					<Typography variant='regular'>
						{getUtcDate(orden?.dFechaEntrega, 'D [de] MMMM [de] YYYY, hh:mm A')}
					</Typography>
					<Typography variant='subtitle2' color='black' margin='8px 0'>{orden?.nTipoEntrega === 1? 'Envio a domicilio' : 'Recolección en sucursal'}</Typography>
					<Typography variant='regular'>
						{
							orden?.nTipoEntrega === 2 ? (`Sucursal ${orden?.cSucursal}`) : orden?.cColonia ? (`${orden?.cCalle || ''} ${orden?.cNumeroExterior ? `#${orden?.cNumeroExterior}` : ''} ${orden?.cColonia ? `Col. ${orden?.cColonia}` : ''}${orden?.cReferencias ? `, ${orden?.cReferencias}` : ''}.` ): 'Sin información.'
						}
					</Typography>
					{/* <Typography variant='subtitle2' color='black' margin='8px 0'>Método de pago</Typography>
					<Typography variant='regular'>{'metodoDePago'}</Typography> */}
					<Typography variant='subtitle2' color='black' margin='8px 0'>ID del pedido</Typography>
					<Typography variant='regular'>{orden.cFolioMostrar}</Typography>
				</GridStyled>
				<Grid  width='100%' container direction='column' margin='24px 0 0 0'>
					<Button loading={loading} margin="0 0 0.5rem 0" variant='solid' onClick={handleVolverAPedir}>
						Agregar al carrito
					</Button>

					<Button 
						margin="0 0 10px 0" 
						variant='outline' 
						onClick={() => {
							if(orden.cNumeroAyuda){
								const wpLink = import.meta.env.VITE_WP_ME;
								const message = orden.cMensajeAyuda || `Necesito ayuda con mi pedido de tienda en línea con folio: ${orden.cFolioMostrar}`;
								const encodedMessage = encodeURIComponent(message);
								window.open(`${wpLink}${orden.cNumeroAyuda}?text=${encodedMessage}`);
							}
						}}
					>
						<Grid container alignItems='center' spacing='12px'> <SupportAgentIcon color='gray_900'></SupportAgentIcon>¿Necesitas ayuda?</Grid>
					</Button>
				</Grid>
				{
					!orden.error && <Grid textAlign='center'>
						<Button loading={loadingReenviar} variant='underline' onClick={handleReenvioTicket}>
							Enviar recibo a mi correo
						</Button>
					</Grid>
				}
				
			</div>
		</Container>
	);
};
export default (DetallePedido);