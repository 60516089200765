import styled from 'styled-components';

export interface Props {
    color?: string;
    disabled?: boolean;
  }

const ButtonStyled = styled.button<Props>`

  
  border-radius: 30px;
  transition: background-color 0.3 ease;
  color: ${props => props.theme.colors[props.disabled ? 'disabledGrey' : (props.color || 'white')]};  
  background-color: ${props => props.theme.colors.blackForm};

  text-align: center;
  padding: 5px 15px 5px 15px;
  border: none;
  font-size: ${props =>props.theme.text.size.caption};

  font-family: ${props =>props.theme.text.font.primary};
  cursor: pointer;

`;
export default ButtonStyled;
