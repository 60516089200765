import styled, { css } from 'styled-components';
import { ButtonProps } from '.';

export interface Props {
	color?: ButtonProps['color'];
	variant?: ButtonProps['variant'];
	size?: ButtonProps['size'];
	disabled?: boolean;
	circular?: boolean;
	padding?: string;
	spacing?: string;
	margin?: string;
	height?: string,
	fontSize?: string;
}

const ButtonStyled = styled.button<Props>`
	border-radius: ${props => {
		if (props.variant === 'underline') return '0px';
		if (props.size === 'sm') return '12px';
		return '16px';
	}};
	transition: background-color 0.3 ease;
	color: ${props => {
		const textColorVariants = ['outline', 'underline', 'light'];
		if (props.disabled) {
			return props.theme.colors.gray_400;
		} else if (textColorVariants.includes(props.variant ?? '')) {
			switch (props.color) {
			case 'black':
				return props.theme.colors.gray_900;
			case 'warning':
				return props.theme.colors.red_500;
			default:
				return props.theme.colors[(props.color || 'primary')];
			}
		} else {
			return props.theme.colors.white;
		}
	}};
	text-align: center;
	margin: ${props => props.margin};
	padding: ${props => {
		switch (props.size) {
		case 'lg':
			return props.padding ? props.padding : '12px 16px';
		case 'md':
			return props.padding ? props.padding : '8px 12px';
		case 'sm':
			return props.padding ? props.padding : '6px 8px';
		default:
			return props.padding ? props.padding : '7px 12px';
		}
	}};
	height: ${props => {
		if (props.variant === 'underline') return 'auto';
		if (props.height) return props.height;

		switch (props.size) {
		case 'lg':
			return '48px';
		case 'md':
			return '40px';
		case 'sm':
			return '32px';
		default:
			return '48px';
		}
	}};
	display: flex;
	justify-content: center;
	align-items: center;
	gap: ${props => props.spacing};
	width: 100%;

	font-family: ${props =>props.theme.text.font.primary};
	font-size: ${props => props.theme.text.size[props.fontSize ? props.fontSize : ['lg', 'md'].includes(props.size ?? '') ? 'subtitle1' : 'subtitle2']};
	line-height: 20px;
	font-weight: ${props =>props.theme.text.weight.medium};
	cursor: ${props => props.disabled ? null: 'pointer'};

	${props => {
		switch (props.variant) {
		case 'solid':
			return css`
				border: none;
				background-color: ${props.theme.colors[props.disabled ? 'gray_100' : props.color === 'black' ? 'gray_900' : 'red_500']};
			`;
		case 'outline':
			return css`
				border: 1px solid ${props.theme.colors[props.disabled ? 'gray_400' : props.color === 'black' ? 'gray_900' : 'red_500']};
				background-color: transparent;
			`;
		case 'underline':
			return css`
				border: none;
				background-color: transparent;
				text-decoration: underline;
			`;
		case 'light':
			return css`
				border: none;
				background-color: transparent;
			`;
		default:
			return '';
		}
	}};
`;
export default ButtonStyled;