import React from 'react';
import { ChevronRightSM } from '~/assets/icons';
import IconButton from '../IconButton';

type Props = {
	onClick?: VoidFunction;
	orientation?: 'left' | 'right' | 'up' | 'down';
	bgColor?: string;
	size?: number;
	chevronColor?: string;
	disabled?: boolean;
	style?: React.CSSProperties;
};

const ChevronIconButton: React.FC<Props> = ({ onClick, orientation = 'right', bgColor = 'gray_100', chevronColor = 'black', style, size = 20,disabled }: Props) => {
	const finalIncrese = size / 20;

	// Map orientation to rotation degrees
	const rotationMap = {
		left: '180deg',
		right: '0deg',
		up: '-90deg',
		down: '90deg',
	};

	const rotationDegree = rotationMap[orientation] || '0deg';

	return (
		<IconButton
			padding="0px"
			disabled={disabled}
			size={`${finalIncrese * 20}px`}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				...style,
			}}
			onClick={onClick}
			bgColor={bgColor}>
			<ChevronRightSM
				style={{
					margin: '0',
					transform: `scale(${finalIncrese}) rotate(${rotationDegree})`,
				}}
				color={chevronColor}
			/>
		</IconButton>
	);
};

export default ChevronIconButton;
