import React from 'react';
import { EditIcon, IconCircleAlert, LocationIconMini, ScheduleIcon } from '~/assets/icons';
import { Button, CustomCarrousel, Modal, Title, Typography, Tag, IconButton } from '~/components';
import Grid from '~/components/Grid';
import { Colonias, DataCarritoTypes, DetalleEnvioType, DireccionesTypes, DireccionType, ErrorTextFieldType, SelectItemsTypes, SelectSucursalType } from '~/interfaces';
import NuevaDireccion from '~/views/misDirecciones/containers/nuevaDireccion';
import ProgramarEnvio from './containers/ProgramarEnvio';
import Recoleccion from './containers/Recoleccion';
import SeleccionarDireccion from './containers/SeleccionarDireccion';
import { OptionContainer, ShowContainer } from './styled';
import { Selector } from '../Selector';
import { getUtcDate } from '~/utils';

type errorNumeroExt = {
    error: boolean;
    check: boolean;
    helperText: string;
}

type Props = {
	open: boolean,
	handleClose: () => void,
	activePosition: number,
	isCheckout?: boolean,
	handleAddDireccion: () => void,
	tipoStep: number,
	getFunctionButton: () => (() => void) | undefined,
	nuevaDireccion: {
		codigoPostal: string,
		handleDataColonias: () => void,
		handleCodigoPostal: (e: React.ChangeEvent<HTMLInputElement>) => void,
		colonia: SelectItemsTypes,
		handleColonia: (e: SelectItemsTypes) => void,
		dataColonias: Colonias | undefined,
		calle: string,
		handleCalle: (e: React.ChangeEvent<HTMLInputElement>) => void,
		numeroExterior: string,
		handleNumeroExterior: (e: React.ChangeEvent<HTMLInputElement>) => void,
		numeroInterior: string,
		handleNumeroInterior: (e: React.ChangeEvent<HTMLInputElement>) => void,
		entreCalle: string,
		handleEntreCalle: (e: React.ChangeEvent<HTMLInputElement>) => void,
		tipoDomicilio: SelectItemsTypes,
		handleTipoDomicilio: (e: SelectItemsTypes) => void,
		indicadoresAdicionales: string,
		handleIndicacionesAdicionales: (e: React.ChangeEvent<HTMLInputElement>) => void,
		dataTipoDomicilios: SelectItemsTypes[] | undefined,
		errorCodigoPostal: errorNumeroExt,
		erroCalle: errorNumeroExt,
		errorNumeroExt: errorNumeroExt,
		errorNumeroInt: errorNumeroExt,
		errorEntreCalle: errorNumeroExt,
		errorIndic: errorNumeroExt,
		errorGuardarDireccion: boolean,
		loadingColonias: boolean,
		handleGuardarDireccion: (handleBack?: ((value?: DireccionType) => void) | undefined) => void,
		loading: boolean,
		direccionPredeterminada: boolean,
		handleDireccionPredeterminada: () => void,
		disabledDireccionPredeterminada: boolean,
	},
	loadingButton: boolean,
	handleProgramarRecoleccion: () => void,
	dataSucursales: SelectSucursalType[] | undefined,
	sucursal: SelectItemsTypes | null,
	handleSucursal: (suc: SelectItemsTypes) => void,
	dataCiudades: SelectItemsTypes[] | undefined,
	ciudad: SelectItemsTypes | null,
	handleCiudad: (value: SelectItemsTypes) => void,
	loadingSucursales: boolean,
	getDisabledButton: () => boolean | undefined,
	handleProgramarEnvio: () => void,
	fechaRecoleccion: string | null,
	handleFechaRecoleccion: (e: string) => void,
	horas: SelectItemsTypes[] | undefined,
	horaRecoleccion: SelectItemsTypes | null,
	handleHoraRecoleccion: (e: SelectItemsTypes) => void,
	detalleEnvio: DetalleEnvioType | null,
	today: string,
	handleCancelar: () => void,
	handleGoToModificarDomicilio: () => void,
	dataDirecciones: DireccionesTypes[] | undefined,
	currentDireccion: DireccionesTypes | null,
	handleCurrentDireccion: (direccion: DireccionesTypes) => void,
	handleReset: () => void,
	carrito: DataCarritoTypes | undefined,
	nivelTienda?: number,
	errorDate: ErrorTextFieldType,
	handleEntregarAhora: () => void

	handleClickRecibirSucursal: () => void
	handleClickRecibirDomicilio: () => void
};

// falta funcion de guardar en local storage e inicializar los valores
const OpcionesEntregaModal : React.FC<Props> = ({ 
	open,
	handleClose,
	activePosition,
	handleAddDireccion,
	tipoStep,
	getFunctionButton,
	nuevaDireccion,
	loadingButton,
	handleProgramarRecoleccion,
	dataSucursales,
	sucursal,
	handleSucursal,
	dataCiudades,
	ciudad,
	handleCiudad,
	loadingSucursales,
	getDisabledButton,
	handleProgramarEnvio,
	isCheckout,
	fechaRecoleccion,
	carrito,
	handleFechaRecoleccion,
	horas,
	horaRecoleccion,
	handleHoraRecoleccion,
	detalleEnvio,
	// today,
	handleCancelar,
	handleGoToModificarDomicilio,
	dataDirecciones,
	currentDireccion,
	handleCurrentDireccion,
	handleReset,
	errorDate,
	handleEntregarAhora,

	handleClickRecibirDomicilio,
	handleClickRecibirSucursal,
} : Props) => {
	const getTitle = () => {
		switch (tipoStep) {
		case 1:
			return isCheckout ? (
				<>
					Detalles del envío
				</>
			) : (
				<>
					¿Cómo deseas recibir tu pedido?
				</>
			);
		case 2:
			return <>
				Dirección de entrega
			</>;
		case 3:
			return <>
				Programar recolección
			</>;
		case 4:
			return <>
				Programar envío
			</>;
		
		default:
			return '';
		}
	};

	const getSecondStep = () => {
		switch (tipoStep) {
		case 2:
		case 6:
			return (
				<ShowContainer> 
					<NuevaDireccion 
						gralDisabled={loadingButton} 
						edit={tipoStep === 6}
						key={1}
						{...nuevaDireccion}
					/> 
				</ShowContainer>
			);

		case 3:
			return <Recoleccion 
				dataSucursales={dataSucursales?.map((sucursal) => ({ ...sucursal, error: sucursal.errorDisponibilidadProductos }))}
				sucursal={sucursal}
				handleSucursal={handleSucursal}
				dataCiudades={dataCiudades}
				ciudad={ciudad}
				handleCiudad={handleCiudad}
				loadingSucursales={loadingSucursales}
			/>;

		case 4:
			return <ProgramarEnvio 
				fechaRecoleccion={fechaRecoleccion}
				handleFechaRecoleccion={handleFechaRecoleccion}
				carrito={carrito}
				errorDate={errorDate}
				isCheckout={isCheckout}
				horas={horas}
				horaRecoleccion={horaRecoleccion}
				handleHoraRecoleccion={handleHoraRecoleccion}
			/>;

		case 5:
			return <SeleccionarDireccion 
				dataDirecciones={dataDirecciones}
				handleNext={handleAddDireccion}
				currentDireccion={currentDireccion}
				handleCurrentDireccion={handleCurrentDireccion}
			/>;

		default:
			return <></>;
		}
	};

	const getAction = () => {
		switch (tipoStep) {
		case 1:
			return <></>;
		case 3:
			return (
				<Grid container spacing='10px' padding='16px'>
					<Button loading={loadingButton} onClick={getFunctionButton()} disabled={getDisabledButton()} variant='solid' fullWidth>
						Continuar
					</Button>
				</Grid>
			);
		case 4:
			return (
				<Grid container direction='column' spacing='10px' padding='20px 40px 25px 40px'>
					<Grid item flex>
						<Button loading={loadingButton} onClick={getFunctionButton()} disabled={getDisabledButton()} variant='solid' fullWidth>
							Programar
						</Button>
					</Grid>

					<Grid item flex>
						<Button disabled={loadingButton || !detalleEnvio?.bEntregarAhoraDisponible} onClick={handleEntregarAhora} variant='outline' fullWidth>
							Entregar Ahora
						</Button>
					</Grid>
				</Grid>
			);
		default:
			return (
				<Grid container direction='column' spacing='10px' padding='20px 40px 25px 40px'>
					<Grid item flex>
						<Button disabled={loadingButton} onClick={handleCancelar} variant='outline' fullWidth>
							Cancelar
						</Button>
					</Grid>
					<Grid item flex>
						<Button loading={loadingButton} onClick={getFunctionButton()} disabled={getDisabledButton()} variant='solid' fullWidth>
							Continuar
						</Button>
					</Grid>
				</Grid>
			);
		}
	};
	
	return (
		<Modal 
			responsiveFullSize 
			height='90vh'
			action={getAction()}
			title={<Grid container textAlign='center' justifyContent='center'>
				<Title variant='h5'>{getTitle()}</Title>
			</Grid>}
			padding={'0 32px'} 
			width='100%' 
			maxWidth="438px" 
			close 
			handleClose={() =>{
				handleClose();
				handleReset();
			}} 
			open={open}
		>
			<CustomCarrousel 
				activePosition={activePosition}
				padding='0px'
				items={[
					isCheckout ? (
						<>
							<OptionContainer margin='16px 0'>
								<Grid container alignItems='center'>
									<Grid container width='100%' padding='4px 0 4px 0' direction='row' alignItems='center' borderbottom='gray_300'>
										<LocationIconMini />

										<Typography 
											padding='0 6px 0 8px' 
											variant='subtitle1bold'
										>
											{detalleEnvio?.nSucursal ? 'Sucursal de recolección' : 'Dirección de entrega'}
										</Typography>

										{
											detalleEnvio?.cTipoDomicilio && <Tag>
												{detalleEnvio.cTipoDomicilio}
											</Tag>
										}

										{
											isCheckout && (!detalleEnvio?.nSucursal && !detalleEnvio?.nDireccion) && <Grid item padding='0 0 0 3px'><IconCircleAlert color='red_500'></IconCircleAlert></Grid>
										}
									</Grid>
								</Grid>

								<Grid container justifyContent='space-between' alignItems='center' padding='8px 0'>
									<Grid container item flex alignItems='flex-start' direction='column'>
										<Typography variant='subtitle2regular'>
											{detalleEnvio?.cSucursal ? (`Sucursal ${detalleEnvio?.cSucursal}`) : detalleEnvio?.nDireccion ? (`${detalleEnvio?.cCalle || ''} ${detalleEnvio?.cNumeroExterior ? `#${detalleEnvio?.cNumeroExterior}` : ''} ${detalleEnvio?.cColonia ? `Col. ${detalleEnvio?.cColonia}` : ''}` ): 'Ingresa la dirección a la que te gustaría que enviemos tu orden.'}
										</Typography>
		
										{
											detalleEnvio?.cMunicipio && <Typography variant='subtitle2regular'>
												{`${detalleEnvio?.cMunicipio} ${detalleEnvio.cEstado}`}
											</Typography>
										}
									</Grid>
		
									{
										detalleEnvio?.nDireccion && <Grid bgcolor='gray_50' borderRadius='100%' container justifyContent='center' item>
											<IconButton padding='0px' containerPadding='0px' onClick={handleGoToModificarDomicilio} style={{ transform: 'scale(0.5)' }} ><EditIcon /></IconButton>
										</Grid>
									}
								</Grid>

								<Grid container justifyContent='flex-start' alignItems='center' spacing='8px'>
									<Button 
										onClick={detalleEnvio?.nSucursal ? handleProgramarRecoleccion : handleAddDireccion}
										variant='solid'
										size='sm'
									>
										{detalleEnvio?.nSucursal ? 'Cambiar sucursal' : detalleEnvio?.nDireccion ? 'Cambiar' : 'Añadir'}
									</Button>

									<Button 
										onClick={detalleEnvio?.nSucursal ? handleAddDireccion : handleProgramarRecoleccion} 
										variant='outline'
										size='sm'
									>
										{detalleEnvio?.nSucursal ? 'Enviar a domicilio' : 'Recoger en sucursal'}
									</Button>
								</Grid>
							</OptionContainer> 

							<OptionContainer borderWarning={!detalleEnvio?.cHoraAgendada}>
								<Grid borderbottom='gray_300' container alignItems='center' padding='0 0 5px 0'>
									<ScheduleIcon style={{ transform: `scale(${20/24})`, overflow: 'hidden' }} />
									
									<Typography padding='0 6px 0 8px' variant='subtitle1bold'>{detalleEnvio?.bEntregarAhora ? 'Entregar ahora' : 'Programar entrega'}</Typography>
								</Grid>

								<Grid container alignItems='center' justifyContent='space-between' padding='8px 0'>
									{
										detalleEnvio?.bEntregarAhora ? (
											<Typography variant='subtitle2regular'>{detalleEnvio.cRangoEntregaAhora} minutos</Typography>
										) : detalleEnvio?.dFechaAgendada ? (
											<Grid item flex textAlign='justify' container direction='column'>
												<Typography variant='subtitle2regular'>
													Fecha: {getUtcDate(detalleEnvio?.dFechaAgendada, 'LL')}
												</Typography>
												<Typography variant='subtitle2regular'>
													Hora: {detalleEnvio?.cHoraAgendada}
												</Typography>
											</Grid>
										) : (
											<Typography variant='subtitle2regular'>Programa tu fecha y hora de entrega</Typography>
										)
									}

									<Button onClick={handleProgramarEnvio} size='sm' variant={detalleEnvio?.dFechaAgendada ? 'outline' : 'solid'}>
										{(detalleEnvio?.bEntregarAhora || !detalleEnvio?.dFechaAgendada) ? 'Programar' : 'Cambiar'} 
									</Button>
								</Grid>
							</OptionContainer>
						</>
					) : (
						<>
							<Grid container direction='column' padding='32px 0'>
								<Selector isSelected={false} type='radio' handleChange={() => handleClickRecibirDomicilio()} disabled={detalleEnvio?.bEntregaDisponible === false}>
									A domicilio
								</Selector>
								<Typography variant='captionRegular' padding='4px 0 0 0'>Recibe tu pedido en tu dirección</Typography>
							</Grid>

							<Grid container direction='column'>
								<Selector isSelected={false} type='radio' handleChange={() => handleClickRecibirSucursal()} disabled={detalleEnvio?.bRecogerDisponible === false}>
									Recoger en sucursal
								</Selector>
								<Typography variant='captionRegular' padding='4px 0 0 0'>Elige tu sucursal más cercana y pasa por tu pedido</Typography>
							</Grid>
						</>
					),

					getSecondStep()
				].filter((step) => step !== null)}
			/>
		</Modal>
	);
};
export default (OpcionesEntregaModal);