
import _, { isNumber } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import {  CarritoTypes, ConfiguracionProducto, DataCarritoTypes, DetalleEnvioType, ProductosProps, handleModificaCarritoType } from '~/interfaces';
import { CheckoutContext } from '~/context/checkoutContext';
import { useProductTextFieldSelect } from '.';
import { errorCommentRegex, handleHelperValidRegex, handleValidText } from '~/utils/validations';
import errors from '~/constants/errors';

const useDetalleProducto = (
	productoId: number,
	handleOpenDetalleProductoModal: ((value: boolean) => void) | ((value: boolean) => boolean),
	handleProductoId: (value: number) => void,
	handleClick: (id: number, nivelId?: number, handleNivelMainId?: ((value: number | null) => void) | undefined) => void,
	handleDataCarrito: (nCupon?: number | undefined) => void,
	handleModificaCarrito: handleModificaCarritoType,
	carritoLoading: boolean,
	nivelMainId: number | null,
	handleOrdenar: (carrito: DataCarritoTypes, detalleEnvio: DetalleEnvioType, nombres: string, numeroCelular: string, nCupon?: number | undefined, handleDone?: VoidFunction | undefined) => void,
	detalleEnvio: DetalleEnvioType | null,
	loadingCheckoutCarrito: boolean,
	handleNivelMainId: (value: number | null) => void,
	carritoItem?: CarritoTypes | undefined,
) => {
	const {
		nivelCheckoutId,
		setNivelCheckoutId
	} = useContext(CheckoutContext);

	const {
		handleNivel,
		xsCarrito,
	} = useContext(GeneralLayoutContext);

	const params = useParams();
	const nNivelTienda = params.nNivelTienda ? parseInt(params.nNivelTienda) : null;

	useEffect(() => {
		if(nNivelTienda){
			handleNivel(nNivelTienda || 0);
			setNivelCheckoutId(nNivelTienda || 0);
		} else {
			setNivelCheckoutId(null);
		}
	}, [nNivelTienda]);
	
	
	// Hooks encargados de mostrar el detalle del producto
	// Es un modal, entonces cada que se seleccione una card productoId se va a setear
	// y el UseEffect se va a activar para modificar dataProducto
	const {headersApi, dataCarrito} = useContext(CookiesContext);
	const apiUrl = import.meta.env.VITE_API_URL;

	const [dataProducto, setDataProducto] = useState<ConfiguracionProducto | undefined>(undefined);

	const {
		handleCantidad,
		handlePlus,
		handleMinus,
		cantidad
	} = useProductTextFieldSelect(50, 1, 1);

	const {
		handleCantidad: handleEdad,
		handlePlus: handleEdadPlus,
		handleMinus: handleEdadMinus,
		cantidad: edad
	} = useProductTextFieldSelect(255, 0, '');

	const [comentario, setComentario] = useState('');
	const handleComentario = (e: string) => {
		setComentario(e);
	};

	const [nombreDelFestejado, setNombreDelFestejado] = useState('');
	const handleNombreDelFestejado = (e: string) => {
		setNombreDelFestejado(e?.substring(0, 15));
	};

	const [nombreCumpleaniero, setNombreCumpleaniero] = useState('');
	const handleNombreCumpleaniero = (e: string) => {
		setNombreCumpleaniero(e?.substring(0, 15));
	};

	const [panaBoxRemitente, setPanaBoxRemitente] = useState('');
	const handlePanaBoxRemitente = (e: string) => {
		setPanaBoxRemitente(e?.substring(0, 15));
	};

	const [panaBoxDestinatario, setPanaBoxDestinatario] = useState('');
	const handlePanaBoxDestinatario = (e: string) => {
		setPanaBoxDestinatario(e?.substring(0, 15));
	};

	const errorNombreDelFestejado = handleValidText(nombreDelFestejado, 'caracErrorForm');
	const errorNombreCumpleaniero = handleValidText(nombreCumpleaniero, 'caracErrorForm');
	const errorPanaBoxDestinatario = handleValidText(panaBoxDestinatario, 'caracErrorForm');
	const errorPanaBoxRemitente = handleValidText(panaBoxRemitente, 'caracErrorForm');
	const errorComentario = handleHelperValidRegex(comentario, errorCommentRegex, errors['caracErrorForm']);

	const nivelProducto = nivelMainId || nNivelTienda || nivelCheckoutId;
	
	useEffect(() => {
		setDataProducto(undefined);

		if(productoId){
			const body = {
				nVProducto: productoId,
				nNivelTienda: nivelProducto
			};
			fetch(`${apiUrl}/APPWEB/ObtenerModificadoresProducto`, {
				method: 'POST',
				body: JSON.stringify(body),
				headers: headersApi,
			}).then((res) => res.json()).then((response) => {
				if(response && response.Result && response.Result.done !== false && Object.keys(response.Result?.producto || {}).length !== 0){
					setDataProducto({...response.Result, nivelProducto});
				} else {
					setDataProducto(undefined);
					handleOpenDetalleProductoModal(false);
				}
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, [productoId, headersApi?.Authorization]);
	
	const handleProductoSIP = (dataProducto: ConfiguracionProducto) => {
		const productosVariantesChecked = dataProducto ? _.filter(dataProducto.dataVariantesProducto, o => o.checked !== undefined && o.checkedTipoVariante !== undefined) : [];
		const copy = {...dataProducto};

		if(dataProducto?.producto?.nVProducto && productosVariantesChecked.length > 0){
			let apartado = '';

			_.orderBy(productosVariantesChecked, o => o.nTipoVariante).forEach((element, e) => {
				_.orderBy(_.filter(element.jVariantes, v => v.nOpcion === element.checked), a => a.nOpcion).forEach((item, i) => {
						
					apartado = `${apartado}${i === 0 && e === 0 ? '' : ','}${element.nTipoVariante}-${item.nOpcion}`;
					
				});
			});
			const variante = dataProducto.dataProductoVariantes[apartado];
			if(variante){
				copy.producto.nProductoSIP = variante.nProductoSIP;
				copy.producto.nNuevoPrecio = variante.nPrecio_Base;
				copy.producto.bNuevoPrecioEspecial = variante.bPrecioEspecial;
				copy.producto.nNuevo_Precio_Base_Original = variante.nPrecio_Base_Original;
				if(dataProducto?.dataVariantesProducto?.length > 1) {
					copy.producto.bNuevaDisponibilidad = !!variante.bDisponible;
				}
			}
		} else if(dataProducto?.dataProductoVariantes) {
			copy.producto.nProductoSIP = 0;
			copy.producto.nNuevoPrecio = undefined;
			copy.producto.bNuevoPrecioEspecial = undefined;
			copy.producto.nNuevo_Precio_Base_Original = undefined;
			copy.producto.bNuevaDisponibilidad = undefined;
		}
		setDataProducto(copy);

	};

	const handleCheckVariantes = (e: number, id: number, nTipoVariante: number) => { 
		if(dataProducto?.dataVariantesProducto?.[e]){
			const copy = {...dataProducto};
			const checked = copy.dataVariantesProducto[e].checked === id && copy.dataVariantesProducto[e].checkedTipoVariante === nTipoVariante;

			copy.dataVariantesProducto[e].checked = checked ? undefined : id;
			copy.dataVariantesProducto[e].checkedTipoVariante = checked ? undefined : nTipoVariante;
			setDataProducto(copy);
			handleProductoSIP(dataProducto);
			
		}
	};

	const handleCheckModificadores = (i: number, id: number, nTipoModificador: number, bOpcionMultiple: boolean, e: number) => {
		
		if(bOpcionMultiple && dataProducto && dataProducto.dataModificadoresProducto && dataProducto.dataModificadoresProducto[i]){
			const copy = {...dataProducto};
			const checked = copy.dataModificadoresProducto[i].jModificadores[e].checked;
			copy.dataModificadoresProducto[i].jModificadores[e].checked = !checked;
			copy.dataModificadoresProducto[i].checkedTipoModificador = undefined;
			copy.dataModificadoresProducto[i].checked = undefined;
			
			setDataProducto(copy);


		} else if(dataProducto?.dataModificadoresProducto?.[i]){
			const copy = {...dataProducto};
			const checked = copy.dataModificadoresProducto[i].checked === id && copy.dataModificadoresProducto[i].checkedTipoModificador === nTipoModificador;

			copy.dataModificadoresProducto[i].checked = checked ? undefined : id;
			copy.dataModificadoresProducto[i].checkedTipoModificador = checked ? undefined : nTipoModificador;
			setDataProducto(copy);
		}
	};

	const handleAddExtras = (e: number, remove?: boolean) => { 
		if(dataProducto?.dataExtras?.[e]){
			const copy = {...dataProducto};
			const cantidadHelper = copy.dataExtras[e].nCantidad || 0;
			copy.dataExtras[e].nCantidad = remove ? (copy.dataExtras[e].nCantidad === 0 ? undefined : cantidadHelper - 1) : (cantidadHelper + 1);
			
			setDataProducto(copy);
		}
	};

	const handleAddComplementos = (e: number, remove?: boolean) => { 
		if(dataProducto?.dataComplementos?.[e]){
			const copy = {...dataProducto};
			const cantidadHelper = copy.dataComplementos[e].nCantidad || 0;
			copy.dataComplementos[e].nCantidad = remove ? (copy.dataComplementos[e].nCantidad === 0 ? undefined : cantidadHelper - 1) : (cantidadHelper + 1);
			
			setDataProducto(copy);
		}
	};
	
	
	
	const handleCloseDetalleProducto = () => {
		handleOpenDetalleProductoModal(false);
		handleProductoId(0);
		setDataProducto(undefined);
		handleCantidad(1);
		
		handleNivelMainId(null);
		handleEdad('');
		setNombreCumpleaniero('');
		setComentario('');
		setNombreDelFestejado('');
		setPanaBoxDestinatario('');
		setPanaBoxRemitente('');
	};

	const handleAddToCart = (item: ProductosProps) => {
		handleClick(item.nVProducto);
	};

	useEffect(() => {
		if(dataProducto && carritoItem){
			const copy: ConfiguracionProducto = {...dataProducto};
			setComentario(carritoItem.cComentarios);

			//bFelicidades
			setNombreDelFestejado(carritoItem.cFelicidades);
			setNombreCumpleaniero(carritoItem.cNombreCumpleanios);

			//bPanaBox
			setPanaBoxRemitente(carritoItem.cDePanaBox);
			setPanaBoxDestinatario(carritoItem.cParaPanaBox);

			handleEdad(isNumber(carritoItem.nEdadCumpleanios) ? carritoItem.nEdadCumpleanios : '');
			carritoItem.jExtras.forEach(element => {
				if(element.nTipoExtra === 1 && copy.dataVariantesProducto) {
					const index = _.findIndex(copy.dataVariantesProducto, o=>o.nTipoVariante === element.nExtra);
					if(index >= 0){
						copy.dataVariantesProducto[index].checked = element.nTipoOpcionExtra || undefined;
						copy.dataVariantesProducto[index].checkedTipoVariante = element.nExtra || undefined;
					}
				}

				if(element.nTipoExtra === 2 && copy.dataModificadoresProducto
				) {
					const index = _.findIndex(copy.dataModificadoresProducto
						, o=>o.nTipoModificador === element.nExtra);
					if(index >= 0){
						if(copy.dataModificadoresProducto[index].bOpcionMultiple){
							const indexOpcion = _.findIndex(copy.dataModificadoresProducto[index].jModificadores
								, o=>o.nOpcion === element.nTipoOpcionExtra);
							if(indexOpcion >= 0){
								copy.dataModificadoresProducto[index].jModificadores[indexOpcion].checked = true;
							}
						} else {
							copy.dataModificadoresProducto[index].checked = element.nTipoOpcionExtra || undefined;
							copy.dataModificadoresProducto[index].checkedTipoModificador = element.nExtra || undefined;
						}
					}
				}

				if(element.nTipoExtra === 3 && copy.dataExtras) {
					const index = _.findIndex(copy.dataExtras, o=>o.nExtra === element.nExtra);
					if(index >= 0){
						copy.dataExtras[index].nCantidad = element.nCantidad || undefined;
					}
				}
			});

			if(carritoItem.nCantidad){
				handleCantidad(carritoItem.nCantidad);
			}
			handleProductoSIP(copy);
			setDataProducto(copy);
		}
	}, [dataProducto !== undefined, carritoItem]);

	return {
		dataProducto,
		handleCheckVariantes,
		handlePlus,
		handleCantidad,
		handleEdad,
		handleEdadPlus,
		handleEdadMinus,
		edad,
		cantidad,
		handleMinus,
		handleAddExtras,
		handleCheckModificadores,
		handleClick,
		handleCloseDetalleProducto,
		handleAddToCart,
		handleModificaCarrito,
		handlePanaBoxDestinatario,
		handlePanaBoxRemitente,
		handleComentario,
		comentario,
		carritoLoading,
		handleDataCarrito,
		carritoItem,
		handleAddComplementos,
		nombreDelFestejado,
		panaBoxRemitente, 
		panaBoxDestinatario, 
		nivelCheckoutId,
		loadingCheckoutCarrito,
		handleNombreDelFestejado,
		nombreCumpleaniero,
		handleNombreCumpleaniero,
		handleOrdenar,
		errorNombreDelFestejado,
		errorNombreCumpleaniero,
		errorPanaBoxRemitente,
		errorPanaBoxDestinatario,
		errorComentario,
		dataCarrito,
		detalleEnvio,
		nivelProducto, 
		xsCarrito,
	};
};

export default useDetalleProducto;