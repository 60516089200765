import React, { useEffect } from 'react';
import { WorkspaceIcon } from '~/assets/icons';
import { ProductosProps } from '~/interfaces';
import { getCdnUrl, helperViewItem, pricePrettify } from '~/utils';
import CardMedia from '../CardMedia';
import Grid from '../Grid';
import Typography from '../Typography';
import { ProductCardContainer, ProductCardContainerMain, ViewIconContainer, PriceContainer } from './styled';
import WarningSobrePedido from '../WarningSobrePedido';
import { useDialog, useFirstVisible } from '~/hooks';
import { DiscountTag, TagsContainer, YellowTag } from '../ProductCard/styled';

type Props = {
	item: ProductosProps,
	handleClickItem: (value: number) => void,
	item_list: string
	margin?: string
};

const MiniProductCard : React.FC<Props> = ({ item, handleClickItem, item_list, margin } : Props) => {
	const {
		handleClose,
		handleOpen,
		open
	} = useDialog();

	const { containerRef, hasBeenSeen } = useFirstVisible();

	useEffect(() => {
		if (hasBeenSeen) {
			helperViewItem(item, item_list);
		}
	}, [hasBeenSeen]);
	
	const disabled = !item.bDisponible;
	const textColor = disabled ? 'disabledFontGrey' : 'black';

	return (
		<>
			<ProductCardContainerMain margin={margin} ref={containerRef} disabled={disabled} >
				<ProductCardContainer disabled={disabled} onClick={disabled ? undefined : item?.bAnticipacion ? handleOpen : ()=>handleClickItem(item?.nVProducto)}>
					{
						!!(item.bPopular) && !disabled && <ViewIconContainer>
							<PriceContainer>
								<div className='div'><WorkspaceIcon></WorkspaceIcon></div>
							</PriceContainer>
						</ViewIconContainer>
					}

					<Grid item position='relative'>
						<CardMedia 
							alt={item?.cDescripcion} 
							disabled={disabled} 
							alignItems='flex-start' 
							height='87px'
							borderRadius='8px'
							src={getCdnUrl(Array.isArray(item?.cImagenUrl) ? item?.cImagenUrl[0] || '' : item?.cImagenUrl || '')} 
						/>

						<TagsContainer>
							{
								item.bAnticipacion && !disabled ? <Grid container>
									<YellowTag>Sobre pedido</YellowTag>
								</Grid> : null
							}
	
							{
								item?.bPrecioEspecial || disabled ? <Grid container><DiscountTag disabled={disabled}>{disabled ? 'No disponible' : 'Con descuento'}</DiscountTag></Grid> : null
							}
						</TagsContainer>
					</Grid>

					<Grid padding='4px 0 0 0' item xs container direction='column'>
						<Typography color={textColor} variant='body1'>{item.cDescripcion}</Typography>
						<Grid item flex container justifyContent='flex-end' direction='column'>
							<Grid item textAlign='right'>
								{
									!!(item?.bPrecioEspecial && item.nPrecio_Base_Original && item.nProductoSIP) && <Typography color={textColor} variant='line'>
										{pricePrettify(item.nPrecio_Base_Original)}
									</Typography>
								}

								<Typography color={textColor} variant='subtitle1bold'>{item.nPrecio_Base ? pricePrettify(item.nPrecio_Base) : ''}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</ProductCardContainer>
			</ProductCardContainerMain>

			{
				!!(item?.bAnticipacion) && (
					<WarningSobrePedido 
						nDiasAnticipacion={item.nDiasAnticipacion} 
						cDiasDisponibles={item.cDiasDisponibles}
						open={open} 
						handleClose={handleClose} 
						handleClickItem={()=>handleClickItem(item?.nVProducto)}
					/>
				)
			}
		</>
	);
};
export default (MiniProductCard);