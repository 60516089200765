import React from 'react';
import { Typography } from '..';
import { TextFieldContainer } from '../TextField/styled';
import { TextFieldInput, TextFieldContainerInput, InputContainer, TextFieldInputType } from './styled';
type Props = {
	label?: string,
	value?: string,
	padding?: string,
	helperText?: string,
	onChange: (value: string) =>void,
	placeholder?: string,
	width?: string,
	error?: boolean,
	margin?: string,
	maxLength?: number
	inputType?: boolean
};
const TextFieldComment : React.FC<Props> = ({value, onChange,  placeholder, width, margin, padding, helperText, maxLength, inputType, error} : Props) => {

	return (
		<TextFieldContainer fullWidth margin={margin} width={width} padding={padding}>
			<TextFieldContainerInput>
			
				<InputContainer>
					{
						inputType ? <TextFieldInputType
							id="commentInput"
							maxLength={maxLength}
							placeholder={placeholder}autoComplete='off' onChange={(e)=>onChange(e.target.value)} value={value} /> : <TextFieldInput
							id="comment"
							maxLength={maxLength}
							placeholder={placeholder}autoComplete='off' onChange={(e)=>onChange(e.target.value)} value={value} />
					}
					
				</InputContainer>
			
			
				
			</TextFieldContainerInput>
			{
				helperText && <Typography padding='5px 0 0 0' variant='regular' color={error ? 'red_500' : 'lightGrey'}>{helperText}</Typography>
			}
			
		</TextFieldContainer>
	);
};
export default (TextFieldComment);