import styled from 'styled-components';
export interface AlertContainerProps {
  bottom?: string;
  open?: boolean;
}
export const AlertContainer = styled.div<AlertContainerProps>`
  bottom: ${props => props.bottom || '50px'};

  border-radius: 6px;
  position: fixed; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  
  
  width: 0px;
  z-index: 1000000000;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  align-items: center;
  transition: all 0.3s ease;
  
  ${props => props.open ? `
    width: 344px;
    transition: all 0.3s ease;
    opacity: 1;

    div {

      visibility: visible;
      
    }
  ` : null}
`;

export const ShadowContainer = styled.div`
  width: 344px;

  background-color: ${props => props.theme.colors.black};
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  border-radius: 4px;

`;

export const BodyContainer = styled.div`
  padding: 16px;
  display: flex;
  border-radius: 4px;

  
  transition: all 0.3s ease;
  overflow: hidden;
  align-items: center;
  text-align: center;
  background-color: ${props => props.theme.colors.black};
  span {
    overflow: hidden;
  }
`;

