import React, { useState } from 'react';
import { BannerContainer } from './styled';
import CardMedia from '~/components/CardMedia';
import Typography from '~/components/Typography';
import Grid from '../Grid';
import { getCdnUrl } from '~/utils';
import CloseButton from '../CloseButton';

interface Props {
	title: string;
	iconSrc: string;
	backgroundKey: string;
	urlClick: string
	clickPestaniaNueva: boolean,
	nBanner: number
	bannerRef?: React.RefObject<HTMLDivElement>
}

export const BannerInferior: React.FC<Props> = ({ iconSrc, title, backgroundKey, clickPestaniaNueva, urlClick, bannerRef }) => {
	const [isVisible, setIsVisible] = useState(true);

	const handleClose = () => {
		setIsVisible(false);
	};

	const handleBannerClick = () => {
		if (clickPestaniaNueva) {
			window.open(urlClick, '_blank');
		} else {
			window.location.href = urlClick;
		}
	};

	if (!isVisible) return null;

	return (
		<BannerContainer ref={bannerRef} cursor={urlClick ? 'pointer' : 'auto'} backgroundKey={backgroundKey} onClick={handleBannerClick}>
			<Grid container direction='row' padding='8px' flexWrap='nowrap' alignItems="center" justifyContent="space-between">
				<Grid width='100%' container direction='row' spacing='8px' flexWrap='nowrap' alignItems="center">
					<CardMedia src={getCdnUrl(iconSrc)} height="24px" maxwidth="24px" alt="Banner Inferior icono" />
					<Typography variant="subtitle1regular" color='white'>{title}</Typography>
				</Grid>

				<CloseButton color='white' onClick={handleClose} />
			</Grid>
		</BannerContainer>
	);
};