import React, { useContext, useState } from 'react';

import { Button, CardMedia, CheckBox, Grid, LinkSpanBlue, Title, Typography } from '~/components';
import PanaConfirmando from '~/assets/images/panaConfirmando.webp';
import { MainContainer } from '../../styled';
import { AlertToastContext } from '~/context/alertToastContext';
import errors from '~/constants/errors';

interface Props {
	handleAceptarTerminos: VoidFunction
	handlePreviousStep: (() => Promise<void>) | VoidFunction
	terminosCheck: boolean
	handleTerminosCheck: VoidFunction
	aceptandoTerminos: boolean
}

const TerminosCondiciones : React.FC<Props> = ({
	handleAceptarTerminos,
	handlePreviousStep,
	terminosCheck,
	aceptandoTerminos,
	handleTerminosCheck
}) => {
	const { showError } = useContext(AlertToastContext);

	const [regresando, setRegresando] = useState(false);

	const handleRegresar = () => {
		setRegresando(true);

		const result = handlePreviousStep();

		if (result instanceof Promise) {
			result
				.catch(() => {
					showError(errors.errorForm);
				})
				.finally(() => {
					setRegresando(false);
				});
		} else {
			setRegresando(false);
		}
	};

	return (
		<MainContainer>
			<Grid direction='row' container flexWrap='nowrap' margin='0 0 24px 0' justifyContent='space-between'>
				<Title variant='h5'>Acepta los Términos y revisa el Aviso de privacidad de Panamá.</Title>

				<CardMedia staticImg src={PanaConfirmando} />
			</Grid>

			<Typography variant='caption'>Al seleccionar Aceptar a continuación, confirmo que revisé y acepto los <LinkSpanBlue variant='caption' externalLink={import.meta.env.VITE_TYC_LINK}>Terminos de uso</LinkSpanBlue> y reconozco que leí el <LinkSpanBlue externalLink={import.meta.env.VITE_AVISO_PRIVACIDAD_LINK} variant='caption'>Aviso de privacidad</LinkSpanBlue>. Soy mayor de 18 años.</Typography>

			<Grid container padding='0 0 0 0' margin='32px 0 64px 0' alignItems='center' spacing='8px' justifyContent='flex-start'>
				<CheckBox checked={terminosCheck} onClick={handleTerminosCheck}/> 
				<Grid item flex container textAlign='center' padding='0 0 0 0' justifyContent='flex-start'>
					<Typography variant='captionBold'>
						Aceptar
					</Typography>
				</Grid>
			</Grid>

			<Grid container spacing='8px' direction='row' flexWrap='nowrap'>
				<Button onClick={handleRegresar} loading={regresando} fullWidth loadingColor='primary' variant='outline'>Regresar</Button>
				<Button onClick={handleAceptarTerminos} loading={aceptandoTerminos} disabled={!terminosCheck} fullWidth variant='solid'>Siguiente</Button>
			</Grid>
		</MainContainer>
	);
};

export default (TerminosCondiciones);