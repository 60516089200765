import React from 'react';
import Grid from '~/components/Grid';
import TextFieldSelect from '~/components/TextFieldSelect';
import { SelectItemsTypes, SelectSucursalType } from '~/interfaces';
type Props = {
    dataSucursales: SelectSucursalType[] | undefined,
    sucursal: null | SelectItemsTypes,
    handleSucursal: (suc: SelectItemsTypes) => void,
    dataCiudades: SelectItemsTypes[] | undefined,
    ciudad: null | SelectItemsTypes,
    handleCiudad: (suc: SelectItemsTypes) => void,
    loadingSucursales: boolean
};
const Recoleccion : React.FC<Props> = ({ dataSucursales, sucursal, handleSucursal,
	dataCiudades,
	ciudad,
	handleCiudad, loadingSucursales
} : Props) => {
	const sinSucursales = dataSucursales?.length === 0;
	return (
		<Grid direction='column' margin='32px 0 0 0'>
			<TextFieldSelect 
				label='Elige la ciudad'
				id='disponibilidadlSelect'
				items={dataCiudades || []} 
				value={ciudad || undefined}
				fullWidth
				onChange={handleCiudad}
			/>

			<TextFieldSelect 
				padding='11px 0 0 0'
				label='Sucursales con disponibilidad'
				id='sucursalSelect'
				helperText={loadingSucursales ? 'Cargando sucursales...' : sinSucursales ? 'Sin sucursales disponibles' : ''}
				items={dataSucursales?.map((sucursal) => ({ ...sucursal, error: sucursal.errorDisponibilidadProductos })) || []} 
				value={(!sinSucursales && sucursal) || {cValor: 'Selecciona sucursal', nValor: 0}}
				onChange={handleSucursal}
				error={sinSucursales}
				fullWidth
				disabled={sinSucursales}
			/>
		</Grid>
	);
};
export default (Recoleccion);