import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';
import { IOrdenesActivas } from '~/interfaces';

const useOrdenesActivas = () => {
	const {
		headersApi,
	} = useContext(CookiesContext);
	const params = useParams();

	const apiUrl = import.meta.env.VITE_API_URL;

	const [ordenesActivas, setOrdenesActivas] = useState<IOrdenesActivas[] | undefined>(undefined);


	useEffect(() => {
		
		setOrdenesActivas(undefined);
		
		fetch(`${apiUrl}/APPWEB/ObtenerOrdenesActivas?nNivelTienda=${params.nNivelTienda || 0}`, {
			method: 'GET',
			headers: headersApi
		}).then((res) => res.json()).then((res) => {
			if(res?.Result?.ordenesActivas){
				setOrdenesActivas(res?.Result?.ordenesActivas);
			}
			

		}).catch((error) => {
			console.error(error, 'error');
		});

	
	}, [headersApi?.Authorization, params.nNivelTienda]);

	return {
		ordenesActivas,
	};
};

export default useOrdenesActivas;