import {
	useEffect,
	useContext,
	useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import errors from '~/constants/errors';
import _ from 'lodash';
import { CookiesContext } from '~/context/cookiesContext';
import {
	errorNombresRegex,
	errorPasswordRegex,
	getValidFechaDeNacimiento,
	handleHelperValidRegex,
	handlePasswordConfirmValidRegex,
	handleValidEmail,
	handleValidPhone,
} from '~/utils/validations';
import { AlertToastContext } from '~/context/alertToastContext';
import moment from 'moment';
import { SelectItemsTypes } from '~/interfaces';
import { CheckProps } from '~/views/auth/containers/AuthForm';
import { parsePhoneNumber } from 'react-phone-number-input';
import { ESTATUS_USUARIO } from '~/views/auth';

const DEFAULT_GENERO = {
	nValor: 0,
	cValor: 'Selecciona tu género'
};

// hooks para Registro de usuario y para
// modificar la info de usuario en Mi perfil
const useRegistro = (ajustes?: boolean, handleNext?: () => void) => {
	const apiUrl = import.meta.env.VITE_API_URL;

	// Para activar las alertas
	const { handleCloseAlert, statusDialogProps, showError, showSuccess } = useContext(AlertToastContext);

	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { handleUserInfo, userInfo, macAddress, headersApi, handleDoneInitialLoading, token, handleLogout } = useContext(CookiesContext);

	useEffect(()=>{
		if(ajustes && userInfo && userInfo.invitado){
			navigate('/');
		}

		if (!ajustes) {
			setApellidoMaterno(userInfo?.cApellido_Materno ?? '');
			setApellidoPaterno(userInfo?.cApellido_Paterno ?? '');
			setEmail(userInfo?.cCorreo ?? '');
			setFechaDeNacimiento((userInfo?.dFecha_Nacimiento && userInfo.nEstatus !== ESTATUS_USUARIO.FinalizarRegistro) ? moment(userInfo?.dFecha_Nacimiento.substring(0, 10)).format('YYYY-MM-DD') : '');
			setNombres(userInfo?.cNombre ?? '');
		}
	}, [userInfo]);

	const [dataGeneros, setDataGeneros] = useState<SelectItemsTypes[]>([]);
	// nombres
	const [nombres, setNombres] = useState('');
	const handleNombres = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setNombres(e.target.value);
	};
	// notificacionesCheck
	const [notificacionesCheck, setNotificacionesCheck] = useState(true);
	const handleNotificacionesCheck = () => {
		setNotificacionesCheck(!notificacionesCheck);
	};
	// apellidoPaterno
	const [apellidoPaterno, setApellidoPaterno] = useState('');
	const handleApellidoPaterno = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setApellidoPaterno(e.target.value);
	};
	// apellidoMaterno
	const [apellidoMaterno, setApellidoMaterno] = useState('');
	const handleApellidoMaterno = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setApellidoMaterno(e.target.value);
	};
	// fecha de nacimiento
	const [fechaDeNacimiento, setFechaDeNacimiento] = useState('');
	const handleFechaDeNacimiento = (e: string ) => {
		setFechaDeNacimiento(e);
	};
	// email
	const [email, setEmail] = useState('');
	const handleEmail = (e: React.ChangeEvent < HTMLInputElement > ) => {
		!hasTouchedEmail && handleTouchedEmail(true);
		setEmail(e.target.value);
	};

	const [hasTouchedEmail, setHasTouchedEmail] = useState(false);
	const handleTouchedEmail = (value: boolean) => {
		setHasTouchedEmail(value);
	};

	const [isEmailVerified, setIsEmailVerified] = useState(false);

	// numeroCelular
	const [numeroCelular, setNumeroCelular] = useState('');
	const [validandoCelular, setValidandoCelular] = useState(false);
	const handleNumeroCelular = (e: any) => {
		setNumeroCelular(e);

		const PhoneNumber = parsePhoneNumber(typeof e === 'string' ? e : '');
		const numeroFormateado = e?.slice((PhoneNumber?.countryCallingCode.length ?? 0) + 1);
		if (numeroFormateado?.length === 10) {
			handleValidarNumero(numeroFormateado);
		} else {
			setErrorNumeroCelular(null);
		}
	};

	const [errorNumeroCelular, setErrorNumeroCelular] = useState<CheckProps | null>(null);
	const handleValidarNumero = (cNumero_Celular: string) => {
		setValidandoCelular(true);
		fetch(`${apiUrl}/APPWEB/ValidaCelular`,{
			headers: headersApi,
			method: 'POST',
			body: JSON.stringify({
				cNumero_Celular
			})
		}).then((res) => res.json()).then((response) => {
			setErrorNumeroCelular({
				check: response?.Result.bDisponible,
				error: !response?.Result.bDisponible,
				helperText: response?.Result.cMensaje
			});
		}).catch(() => {
			setErrorNumeroCelular({
				check: false,
				error: true,
				helperText: 'Sucedió un error'
			});
		}).finally(() => {
			setValidandoCelular(false);
		});
	};

	const [modificandoTelefono, setModificandoTelefono] = useState(false);
	const handleModificarNumero = () => {
		setModificandoTelefono(true);

		const PhoneNumber = parsePhoneNumber(numeroCelular);
		const cNumero_Celular = numeroCelular?.slice((PhoneNumber?.countryCallingCode.length ?? 0) + 1);

		fetch(`${apiUrl}/APPWEB/ModificaCelular`,{
			headers: headersApi,
			method: 'POST',
			body: JSON.stringify({
				cNumero_Celular
			})
		}).then((res) => res.json()).then((response) => {
			if (response?.Result?.done) {
				handleNext?.();
			} else {
				showError(errors.errorForm);
			}
		}).catch(() => {
			showError(errors.errorForm);
		}).finally(() => {
			setModificandoTelefono(false);
		});
	};

	const [isSendingVerificationEmail, setIsSendingVerificationEmail] = useState(false);
	const handleSendVerificationEmail = () => {
		setIsSendingVerificationEmail(true);

		const PhoneNumber = parsePhoneNumber(numeroCelular);
		const cNumero_Celular = numeroCelular?.slice((PhoneNumber?.countryCallingCode.length ?? 0) + 1);

		fetch(`${apiUrl}/APPWEB/VerificarCorreoRequest`,{
			headers: headersApi,
			method: 'POST',
			body: JSON.stringify({
				cNumero_Celular
			})
		}).then((res) => res.json()).then((response) => {
			if (response?.Result?.done) {
				showSuccess('Se ha enviado un correo de verificación a tu correo electrónico');
			} else {
				showError(errors.errorForm);
			}
		}).catch(() => {
			showError(errors.errorForm);
		}).finally(() => {
			setIsSendingVerificationEmail(false);
		});
	};


	// genero
	const [genero, setGenero] = useState<SelectItemsTypes>(DEFAULT_GENERO);
	const handleGenero = (e: SelectItemsTypes) => {
		setGenero(e);
	};
	// password
	const [password, setPassword] = useState('');
	const handlePassword = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setPassword(e.target.value);
	};
	// passwordConfirm
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const handlePasswordConfirm = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setPasswordConfirm(e.target.value);
	};

	useEffect(() => {
	// para obtener el catálogo de géneros
		if (dataGeneros.length === 0) {
			fetch(`${apiUrl}/APPWEB/ObtenerCATGeneros`, {
				method: 'GET',
			}).then((res) => res.json()).then((response) => {
				if (response && response.Result && response.Result[0]) {
					setDataGeneros(response.Result[0]);
				}
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, []);

	useEffect(() => {
		const nGenero = userInfo?.nGenero;
		setGenero(dataGeneros.find((genero) => genero.nValor === nGenero) || DEFAULT_GENERO);
	}, [userInfo, dataGeneros]);

	// Para validar que los datos del formulario sean correctos
	const nombresCheck = handleHelperValidRegex(nombres, errorNombresRegex, errors.nombresError);
	const apellidoPaternoCheck = handleHelperValidRegex(apellidoPaterno, errorNombresRegex, errors.apellidosError);
	const apellidoMaternoCheck = handleHelperValidRegex(apellidoMaterno, errorNombresRegex, errors.apellidosError);
	const fechaDeNacimientoCheck = getValidFechaDeNacimiento(fechaDeNacimiento);
	const emailCheck = handleValidEmail(email, isEmailVerified);
	const generoCheck = {
		check: genero.nValor !== 0,
	};
	const passwordCheck = handleHelperValidRegex(password, errorPasswordRegex, errors.passwordError);
	const passwordConfirmCheck = handlePasswordConfirmValidRegex(password, passwordConfirm);
	const numeroCelularCheck = handleValidPhone(numeroCelular);

	// Controla el botón de guardar, si regresa true, es porque hay error y el botón se va a deshabilitar (Registro)
	const disabledRegistro = nombres === '' || (apellidoPaterno === '' && apellidoMaterno === '') || fechaDeNacimiento === '' || email === '' || nombresCheck.error ||
		apellidoPaternoCheck.error ||
		apellidoMaternoCheck.error ||
		fechaDeNacimientoCheck.error || genero.nValor === 0  || emailCheck.error;

	// Controla el botón de guardar, si regresa true, es porque hay error y el botón se va a deshabilitar (Modificar datos en mi perfil)
	const disabledModificar = nombres === '' || (apellidoPaterno === '' && apellidoMaterno === '') || fechaDeNacimiento === '' || nombresCheck.error ||
		apellidoPaternoCheck.error ||
		apellidoMaternoCheck.error ||
		fechaDeNacimientoCheck.error || genero.nValor === 0 || emailCheck.error;

	// Te regresa al menú principal
	const handleGoToMain = () => {
		navigate('/');
	};

	// Función para consultar la información del usuario y actualizarla
	const handleGetUserInfo = (onFinish?: VoidFunction) => {
		setLoading(true);

		if (macAddress && dataGeneros.length > 0 && token) {
			fetch(`${apiUrl}/WEB/ConsultaInfoUsuario`, {
				method: 'GET',
				headers: headersApi
			}).then((res) => res.json()).then((res) => {
				if(res?.Result?.userInfo?.[0]){
					const userInfoHelper = res.Result.userInfo[0];

					setNombres(userInfoHelper.cNombre);
					setApellidoPaterno(userInfoHelper.cApellido_Paterno);
					setApellidoMaterno(userInfoHelper.cApellido_Materno);
					handleFechaDeNacimiento(userInfoHelper.dFecha_Nacimiento.substring(0, 10));
					setGenero(_.filter(dataGeneros, (o)=>o.nValor === userInfoHelper.nGenero)[0]);
					setNumeroCelular(parsePhoneNumber(userInfoHelper.cNumero_Celular)?.country === 'MX' ? userInfoHelper.cNumero_Celular : `+52${userInfoHelper.cNumero_Celular}`);
					setEmail(userInfoHelper.cCorreo);
					setIsEmailVerified(userInfoHelper.bCorreoConfirmado);

					handleUserInfo(token, macAddress, {
						cNombre: userInfoHelper.cNombre,
						cApellido_Paterno: userInfoHelper.cApellido_Paterno,
						cApellido_Materno: userInfoHelper.cApellido_Materno,
						nGenero: userInfoHelper.nGenero,
						dFecha_Nacimiento: userInfoHelper.dFecha_Nacimiento,
						cCorreo: userInfoHelper.cCorreo,
						cNumero_Celular: userInfoHelper.cNumero_Celular,
						bCorreoConfirmado: userInfoHelper.bCorreoConfirmado,
						nEstatus: userInfoHelper.nEstatus,
					});

					onFinish?.();

					handleDoneInitialLoading();
				}
				setLoading(false);
			}).catch((error) => {
				console.error(error, 'error');
				showError(errors.errorForm);
				setLoading(false);
			});
		}
	};

	


	// Función para modificar los datos del usuario en mi perfil
	const handleModificarInfoUsuario = (eliminaCuenta?: boolean) => {
		setLoading(true);

		if(((!disabledModificar && dataGeneros.length > 0) || eliminaCuenta) && macAddress){
			const body = {
				cNombre: nombres,
				cApellido_Paterno: apellidoPaterno,
				cApellido_Materno: apellidoMaterno,
				nGenero: genero.nValor,
				dFecha_Nacimiento: fechaDeNacimiento,
				cCorreo: email,
				bDeseaPublicidad: notificacionesCheck,
				bEliminaCuenta: eliminaCuenta,
			};

			fetch(`${apiUrl}/APPWEB/ModificaUsuario`, {
				method: 'POST',
				headers: headersApi,
				body: JSON.stringify(body)
			}).then((res) => res.json()).then((res) => {
				if(res?.Result?.done === false){
					showError(res?.Result?.error ?? 'Algo ha salido mal, por favor verifique sus datos e intente de nuevo');
					setLoading(false);
					return;
				}

				if (eliminaCuenta) {
					return handleLogout(()=> {
						navigate('/');
						setLoading(false);
					});
				} else {
					setLoading(false);
					showSuccess('Información registrada exitosamente');

					if(token){
						handleTouchedEmail(false);
						handleGetUserInfo(() => handleNext?.());
					}
				}
			}).catch((error) => {
				console.error(error, 'error');
				setLoading(false);
				showError(errors.errorForm);
			});
		}
	};

	return {
		handleGetUserInfo,
		handleGoToMain,
		handleCloseAlert,
		dataGeneros,
		nombres,
		handleNombres,
		apellidoPaterno,
		handleApellidoPaterno,
		apellidoMaterno,
		handleApellidoMaterno,
		fechaDeNacimiento,
		handleFechaDeNacimiento,
		email,
		handleEmail,
		numeroCelular,
		handleNumeroCelular,
		errorNumeroCelular,
		validandoCelular,
		handleValidarNumero,
		handleModificarNumero,
		modificandoTelefono,
		genero,
		showError,
		handleGenero,
		password,
		handlePassword,
		passwordConfirm,
		handlePasswordConfirm,
		nombresCheck,
		apellidoPaternoCheck,
		apellidoMaternoCheck,
		fechaDeNacimientoCheck,
		emailCheck,
		generoCheck,
		passwordCheck,
		passwordConfirmCheck,
		numeroCelularCheck,
		disabledRegistro,
		disabledModificar,
		statusDialogProps,
		loading,
		setLoading,
		showSuccess,
		handleModificarInfoUsuario,
		userInfo,
		notificacionesCheck,
		handleNotificacionesCheck,
		setNombres,
		setApellidoPaterno,

		isEmailVerified,
		isSendingVerificationEmail,
		handleSendVerificationEmail,
		hasTouchedEmail,
	};
};

export default useRegistro;