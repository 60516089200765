import React from 'react';
import Grid from '../Grid';
import { IOrdenesActivas } from '~/interfaces';
import { OrdenCard } from './styled';
import TakeAway from '../../assets/images/banner_take_away.webp';
import ATag from '../A';
import Typography from '../Typography';
import { useNavigate } from 'react-router-dom';
import Title from '../Title';
import { IconCircleAlert } from '~/assets/icons';
import colors from '~/styles/colors';

type Props = {
  ordenesActivas: IOrdenesActivas[],
  xs: boolean
};
const OrdenesActivasCard : React.FC<Props> = ({ ordenesActivas, xs } : Props) => {
	const navigate = useNavigate();

	if(ordenesActivas && ordenesActivas?.length === 0){
		return <></>;
	}

	return (
		<>
			<Title padding='17px 0 15px 0' variant={xs ? 'h8' : 'h5'}>Pedidos en curso</Title>
			<Grid container spacing='8px'>
				{
					ordenesActivas.map((orden, i) =>(
						<OrdenCard className='noselect' key={i} onClick={()=>navigate(`/ver-detalles-pedido/${orden.nFolio}`)}>

						

							<Grid width='72px' container alignItems='center'>
								<img loading="lazy" draggable="false" alt='Tu pedido activo' width='60px'height='60px' src={TakeAway}></img>
							</Grid>
							<Grid justifyContent='center' padding='0 0 0 10px' item flex container direction='column'>
								
								<Typography variant='subtitle2bold'>{orden.cTipoPedido}</Typography>
								<Grid padding='0 0 4px 0' spacing='2px' item container alignItems='center'>
									<Typography variant='subtitle2bold'>ID:</Typography> <Typography variant='subtitle2regular'>{orden.cFolioMostrar}</Typography>
								</Grid>
								{
									orden.error && <Grid alignItems='center' container>
										<IconCircleAlert width='20px' height='20px' ordenColor={orden.cColor || colors.red_500} />
										<Grid item flex>
											<Typography padding='0 0 0 5px' variant='caption' style={{color: orden.cColor || colors.red_500}}>
												{orden.error}
											</Typography>
										</Grid>
									</Grid>
								}
								<ATag variant='subtitle1' to={`/historial-de-pedidos/${orden.nFolio}`}>Ver detalles del pedido</ATag>
							</Grid>
						</OrdenCard>
					))
				}
			</Grid>
		</>
	);
};
export default (OrdenesActivasCard);