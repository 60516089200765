import styled, { css } from 'styled-components';
import Grid from '../Grid';

export interface CategoriasAppBarContainerProps {
  padding?: string;
}

export const CategoriasAppBarContainer = styled.div<CategoriasAppBarContainerProps>`
    display: flex;
    
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid ${props => props.theme.colors.borderLightGrey};
    background-color: ${props =>props.theme.colors.white};
    max-width: 100vw;
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;
    z-index: 99;
    @media (max-width: 650px){
		padding: 0 4px 0 4px;
		top: 72px;
    }
`;

export interface CategoriaTabProps {
	active?: boolean;
}

export const CategoriaTab = styled.div<CategoriaTabProps>`
    &:hover {
		cursor: pointer;
    }

	font-size: ${props => props.theme.text.size.subtitle2};
	line-height: 20px;
	font-weight: ${props => props.theme.text.weight.medium};
	font-family: ${props =>props.theme.text.font.primary};
	text-overflow: ellipsis;
	white-space: nowrap;
	user-select: none;

    display: flex;
    align-items: center;
    padding: 6px 8px;
	height: 100%;
	max-height: 32px;

    cursor: pointer;
    border-radius: 12px;

    ${props => props.active ? css`
		background-color: ${props.theme.colors.orange_100};
		border: 1px solid ${props => props.theme.colors.orange_200};
		
    ` : css`
		background-color: ${props.theme.colors.gray_100};
    `}
`;

export const ScrollContainer = styled(Grid)`
	position: relative;
	height: 70px;

	.container > *:not(:last-child){
		margin-right: 8px;
	}

	.container {
		overflow: auto;
		padding: 8px 16px;
		position: absolute;
		left: 0;
		align-items: center;
		bottom: 0;

		top: 0;
		right: 0;
		display: inline-flexbox;
	}
`;