import React, { useContext, useEffect, useState } from 'react';
import { Button, CardMedia, Container, CookiesBannerModal, Grid, Typography } from '~/components';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useDialog, useMediaQuery, useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';
import PanaConfirmando from '~/assets/images/panaConfirmando.webp';
import { getCookiesPreferences, handleGoToAviso, handleGoToTyC } from '~/utils';
import { ConfiguracionUsuario, EscudoLlave, EscudoUsuario, ShoppingBag } from '~/assets/icons';
import CookiesContainer from './containers/CookiesContainer';

type defaultValues = {
	advertising: boolean;
	analytics: boolean;
	personalization: boolean;
} | null;

function CentroPrivacidad() {
	const { handleShowBack } = useContext(GeneralLayoutContext);

	useEffect(() => {
		handleShowBack('/', 'Volver a la tienda', 'Centro de Privacidad', true, true);
	}, []);
	useTitle(PAGINAS.CENTRO_PRIVACIDAD);

	const {
		open,
		handleShow,
		handleClose
	} = useDialog(false);

	const [defaultValues, setDefaultValues] = useState<defaultValues>(null);
	
	const handleConfigurar = () => {
		const cookiesPreferences = getCookiesPreferences();
		setDefaultValues(cookiesPreferences);

		handleShow();
	};

	const md = useMediaQuery(550);

	return (
		<>
			<Container maxWidth="665px" padding="0 2.5%" margin='0 0 8rem 0' center>
				<div className="responsiveContainer">
					<Grid container spacing="2rem">
						<Grid padding={md ? '1rem 0 1rem 0' : '1rem'} spacing={md ? '0px' : '1rem'} container alignItems="center" justifyContent="space-between" direction={md ? 'row' : 'column-reverse'} flex borderbottom>
							<Grid item container flex direction="row" spacing="8px" justifyContent={md ? undefined : 'center'}>
								<Typography variant="subtitle1">Bienvenido al Centro de Privacidad</Typography>
	
								<Typography variant="subtitle2regular" textAlign={md ? undefined : 'center'}>
									En Grupo Panamá, nos comprometemos a proteger tu privacidad y a garantizar que tus datos personales se manejen de manera segura y responsable. Nuestro Centro de Privacidad es tu recurso central para comprender cómo
									recopilamos, utilizamos y protegemos tu información en línea.
								</Typography>
							</Grid>
							<Grid item>
								<CardMedia staticImg maxwidth={md ? '200px' : '100px'} src={PanaConfirmando} />
							</Grid>
						</Grid>
	
						<Grid padding="1rem 13px 1rem 0" container spacing="2rem" direction={md ? 'row' : 'column'} alignItems={md ? undefined : 'center'} borderbottom>
							<Grid item>
								<EscudoUsuario reduced={!md} />
							</Grid>
							<Grid item container flex direction="column" spacing="8px">
								<Typography variant="subtitle1" textAlign={md ? undefined : 'center'}>Avisos de Privacidad</Typography>
	
								<Typography variant="subtitle2regular" textAlign={md ? undefined : 'center'}>
									Te invitamos a revisar nuestros Avisos de Privacidad para obtener información detallada sobre cómo recopilamos, utilizamos, compartimos y protegemos tus datos.
									<br />
									<br />
									Puedes acceder y revisar nuestros Avisos de Privacidad descargándolos a continuación:
									<br />
									<br />
									<Typography cursor="pointer" color="blueLink" variant="subtitle2regular" onClick={handleGoToAviso}>
										Aviso de privacidad
									</Typography>
									<br />
									<br />
									<Typography cursor="pointer" color="blueLink" variant="subtitle2regular" onClick={handleGoToTyC}>
										Términos y condiciones de uso
									</Typography>
								</Typography>
							</Grid>
						</Grid>
	
						<Grid container width="100%" justifyContent="center" direction="column" spacing="1rem">
							<Grid item container width="100%" justifyContent="center" spacing="0.5rem">
								<Typography variant="subtitle1" textAlign="center">
									Configuración de cookies
								</Typography>
	
								<Typography variant="subtitle2regular" textAlign="center">
									Las cookies nos permiten conocer cómo navegas nuestras páginas y mantener su funcionamiento. Con esta información, hacemos que sea más fácil, rápido y seguro usar tu cuenta. Si desactivas ciertas cookies, es posible
									que no puedas disfrutar de algunas funcionalidades de nuestros sitios.
									<br />
									<br />
									<br />
									Te contamos para qué sirven:
								</Typography>
							</Grid>
	
							<CookiesContainer columns={md ? 3 : 1}>
								<Grid item container width="100%" justifyContent="center" alignItems="center" direction="column" spacing="0.5rem">
									<ShoppingBag />
	
									<Typography variant="subtitle1" textAlign="center">
										Publicidad personalizada
									</Typography>
	
									<Typography variant="subtitle2regular" textAlign="center">
										Mejoramos nuestras ofertas mostrando anuncios de tu interés.
									</Typography>
								</Grid>
	
								<Grid item container width="100%" justifyContent="center" alignItems="center" direction="column" spacing="0.5rem">
									<ConfiguracionUsuario />
	
									<Typography variant="subtitle1" textAlign="center">
										Facilita tu experiencia
									</Typography>
	
									<Typography variant="subtitle2regular" textAlign="center">
										Recordamos tus datos para un ingreso fácil y rápido.
									</Typography>
								</Grid>
	
								<Grid item container width="100%" justifyContent="center" alignItems="center" direction="column" spacing="0.5rem">
									<EscudoLlave />
	
									<Typography variant="subtitle1" textAlign="center">
										Más seguridad
									</Typography>
	
									<Typography variant="subtitle2regular" textAlign="center">
										Refuerzan la seguridad de nuestro sitio y protegen a tu cuenta.
									</Typography>
								</Grid>
							</CookiesContainer>

							<Grid item container width="100%" justifyContent="center" spacing="0.5rem">
								<Button fontSize='subtitle2' padding='10px 16px' variant='solid' onClick={handleConfigurar}>
									Configurar Cookies
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Container>

			<CookiesBannerModal handleClose={handleClose} defaultValues={defaultValues} open={open} />
		</>
	);
}

export default CentroPrivacidad;
