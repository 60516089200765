import { useDialog, useMediaQuery } from '~/hooks';
import Button from '../Button';
import Grid from '../Grid';
import LinkSpanBlue from '../LinkSpanBlue';
import Typography from '../Typography';
import CookiesBannerModal from '../CookiesBannerModal';
import { updateConsent } from '~/utils';

type props = {
	shouldShowBanner: boolean;
	removeBanner: () => void;
	bannerRef?: React.RefObject<HTMLDivElement>;
}

const CookiesBanner = ({ shouldShowBanner, removeBanner, bannerRef }: props) => {
	const politicasCookies = import.meta.env.VITE_POLITICA_COOKIES_LINK;
	const lg = useMediaQuery(1150);
	const xs = useMediaQuery(450);

	const {
		open,
		handleShow,
		handleClose
	} = useDialog(false);

	const handleConfigurar = () => {
		handleShow();
	};

	const handleAceptarCookies = () => {
		updateConsent({
			advertising: true,
			analytics: true,
			personalization: true
		});
		removeBanner();
	};

	return (
		<>
			{
				shouldShowBanner && (
					<Grid ref={bannerRef} container zindex={100} direction={lg ? 'row' : 'column'} justifyContent='space-between' alignItems='center' spacing='1rem' position='fixed' bottom='0px' left='0px' padding='16px 32px' width='100%' bgcolor='white' boxShadow='0px -4px 22px 0px rgba(0, 0, 0, 0.25)'>
						<Typography variant='subtitle2' color='black1B'>
							Usamos cookies para mejorar tu experiencia. Consultar más en nuestras <LinkSpanBlue externalLink={politicasCookies} variant='subtitle2'>Políticas de uso de Cookies</LinkSpanBlue>
						</Typography>

						<Grid container direction={xs ? 'row' : 'column'} alignItems='center' justifyContent='center' spacing={xs ? '1rem' : '0.75rem'}>
							<Button padding='10px 16px' variant='solid' size='md' width={xs ? undefined : '100%'} onClick={handleAceptarCookies}>
								Aceptar Cookies
							</Button>
							<Button padding='10px 16px' variant='outline' size='md' onClick={handleConfigurar}>
								Configurar Cookies
							</Button>
						</Grid>
					</Grid>
				)
			}

			<CookiesBannerModal handleClose={handleClose} open={open} removeBanner={removeBanner} />
		</>
	);
};

export default (CookiesBanner);