import Modal from '../Modal';
import CloseButton from '../CloseButton';
import Title from '../Title';
import { RoundCardContainer } from '~/views/checkout/styled';
import Grid from '../Grid';
import Button from '../Button';
import Typography from '../Typography';
import LinkSpanBlue from '../LinkSpanBlue';
import { useDialog, useMediaQuery } from '~/hooks';
import { CookiesCard } from './CookiesCard';
import { updateConsent } from '~/utils';
import { useEffect } from 'react';

type props = {
	open: boolean
	handleClose: () => void
	removeBanner?: () => void
	defaultValues?: {
		advertising: boolean;
		analytics: boolean;
		personalization: boolean;
	} | null
}

const CookiesBannerModal = ({ open, handleClose, removeBanner, defaultValues }: props) => {
	const xs = useMediaQuery(450);
	const politicasCookies = import.meta.env.VITE_POLITICA_COOKIES_LINK;

	const {
		handleOpen: toggleAnaliticas,
		open: checkedAnaliticas,
		setOpen: setCheckedAnaliticas
	} = useDialog();

	const {
		handleOpen: togglePublicidad,
		open: checkedPublicidad,
		setOpen: setCheckedPublicidad
	} = useDialog();

	const {
		handleOpen: togglePersonalizacion,
		open: checkedPersonalizacion,
		setOpen: setCheckedPersonalizacion
	} = useDialog();

	const handleClick = () => {
		updateConsent({
			advertising: checkedPublicidad,
			analytics: checkedAnaliticas,
			personalization: checkedPersonalizacion
		});

		handleClose();
		removeBanner && removeBanner();
	};

	useEffect(() => {
		setCheckedAnaliticas(defaultValues ? defaultValues.analytics : true);
		setCheckedPersonalizacion(defaultValues ? defaultValues.personalization : true);
		setCheckedPublicidad(defaultValues ? defaultValues.advertising : true);
	}, [open]);

	return (
		<Modal
			responsiveFullSize
			width="100%"
			open={open}
			handleClose={handleClose}
			maxWidth='660px'
			action={
				<Grid container width="100%" padding="16px 32px" boxShadow="0px -4px 25px 0px #00000040">
					<Button padding="16px 48px" variant="solid" width={xs ? undefined : '100%'} onClick={handleClick}>
						Guardar
					</Button>
				</Grid>
			}
		>
			<Grid width="100%" padding="16px" container>
				<CloseButton onClick={handleClose} />
			</Grid>

			<Grid width="100%" container padding={xs ? '0px 37px' : '0px 22px'}>
				<Grid width="100%" container justifyContent="center">
					<Title textAlign="center" variant="h5" margin="0px 0px 22px 0px">
						Configurar Cookies
					</Title>
				</Grid>

				<Grid container direction="column" width="100%" spacing="8px" margin="0px 0px 3rem 0px">
					<RoundCardContainer titlePadding="0px 0px 8px 0px" padding="16px">
						<Typography variant="subtitle2">
							Las cookies son una tecnología que nos permite conocer cómo usas nuestro sitio. Con esta información, hacemos que sea más fácil usar tu cuenta y te mostramos publicidad relacionada a tus intereses.
						</Typography>

						<br />

						<Typography variant="subtitle2">
							Conoce más sobre nuestra <LinkSpanBlue variant="subtitle2" externalLink={politicasCookies} >Política de uso de Cookies</LinkSpanBlue>
						</Typography>
					</RoundCardContainer>

					<CookiesCard 
						title='Cookies esenciales'
						description='Sirven para reconocerte cuando ingresas, guardar tus preferencias de configuración y proteger tu cuenta. No pueden deshabilitarse porque son necesarias para el funcionamiento de nuestro sitio.'
					/>

					<CookiesCard 
						title='Cookies Análiticas'
						description='Nos permiten analizar tu navegación en el sitio para que podamos mejorar nuestros servicios.'
						checked={checkedAnaliticas}
						toggle={toggleAnaliticas}
					/>

					<CookiesCard 
						title='Cookies de Publicidad'
						description='Nos permiten enviar tus información a terceros con fines de publicidad en línea.'
						checked={checkedPublicidad}
						toggle={togglePublicidad}
					/>

					<CookiesCard 
						title='Cookies de Personalización'
						description='Nos permiten entender tus preferencias para mostrarte productos y anuncios interesantes para ti.'
						checked={checkedPersonalizacion}
						toggle={togglePersonalizacion}
					/>
				</Grid>
			</Grid>
		</Modal>
	);
};

export default (CookiesBannerModal);