import { SliderProps } from '.';

export const defaultProps: Required<SliderProps> = {
	children: [],
	className: '',
	gap: '5px',
	margin: '',
	triggerClickOn: 3,
	title: '',
	subTitle: '',
	arrowsLocation: 'inline',
};
