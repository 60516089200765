import React from 'react';
import { useTimer } from '~/hooks';
import Button from '../Button';
import Title from '../Title';
import Typography from '../Typography';

import EmptyOopss from '../../assets/images/EmptyOopss.webp';
import { EMPTY_TITLES } from '~/constants/contents';
import { useNavigate } from 'react-router-dom';
import { DoneContainer } from '../Done/styled';

type Props = {
	emptyTitle?: string,
	subtitle?: string,
 };

const EmptyCheckout : React.FC<Props>  = ({emptyTitle, subtitle } : Props) => {
	const navigate = useNavigate();

	const handleGoToMain = () => {
		navigate('/');
	};

	const {
		progress
	} = useTimer(10, handleGoToMain);

	return (
		<DoneContainer>
			<img 
				width={'200px'}
				src={EmptyOopss}
				loading="lazy"
				draggable="false"
				alt="Logo Panamá"
			/>

			<Title color='darkGrey' padding="15px 0 10px 0" variant='h5'>{emptyTitle || EMPTY_TITLES.title1}</Title>

			<Typography padding="0 0 25px 0" color='disabledFontGrey' variant='subtitle2'>{`Redireccionando en ${progress}`}</Typography>

			<Button onClick={handleGoToMain} variant='solid'>{subtitle || 'Volver al inicio'}</Button>
		</DoneContainer>
	);
};

export default (EmptyCheckout);
