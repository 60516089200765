import styled, { css } from 'styled-components';

export interface HeaderContainerProps {
  padding?: string;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`

    position: relative;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    margin-bottom: 15px;

`;

export const BreadCrumbContainer = styled.div<HeaderContainerProps>`
    padding: 5px 20px 5px 20px;
    border-radius: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: ${props => props.theme.colors.white};
    
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;

`;

export interface BreadCrumbSpanProps {
    active?: boolean;
}
export const BreadCrumbSpan = styled.span<BreadCrumbSpanProps>`
    cursor: pointer;
    text-transform: uppercase;
    ${props => css`
        font-weight: ${props.theme.text.weight.bold};
        font-family: ${props.theme.text.font.cursive};
        font-size: ${props.theme.text.size.subtitle2};
        color: ${props.theme.colors[props.active ? 'primary' : 'disabledFontGrey']};

        ${props.active ? css`

        
        ` : css`
            transition: all 0.3s ease;
            &:hover {
                color: ${props.theme.colors.primary};
            }
        `}
        

    `}
`;

export const TitleContainer = styled.div<HeaderContainerProps>`
    margin-top: 30px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    justify-content: center;
    width: 100%;

`;

export const Divider = styled.div<HeaderContainerProps>`
    height: 5px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: ${props => props.theme.colors.primary};
    
`;