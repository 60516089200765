import React from 'react';
import {CarrouselContainer, CarrouselItem} from './styled';



type Props = {
    items: (JSX.Element | null | any)[],
	activePosition?: number,
	padding?: string,
};
const CustomCarrousel : React.FC<Props> = ({ items, activePosition, padding } : Props) => {
	return (
		<CarrouselContainer padding={padding} >
			<div className='div' >
				<CarrouselItem >
					{items[activePosition || 0]}
				</CarrouselItem>

			</div>
    
		</CarrouselContainer>
	);
};
export default (CustomCarrousel);

/* 
import React from 'react';
import {CarrouselContainer, CarrouselItem} from './styled';



type Props = {
    items: (JSX.Element | null)[],
  activePosition?: number,
};
const CustomCarrousel : React.FC<Props> = ({ items, activePosition } : Props) => {
	return (
		<CarrouselContainer marginleft={(activePosition ? `-${activePosition}00%` : null)}>
			<div className='div' >
				{
					items.map((item, i) => (
						<CarrouselItem  key={i}>
							{activePosition === i ? item : null}
						</CarrouselItem>
					))
				}

			</div>
    
		</CarrouselContainer>
	);
};
export default (CustomCarrousel);
*/