import styled, { css } from 'styled-components';

export interface IbackButtonContainer {
    onlyTextfield?: boolean;
    padding?: string;
}
  
export const BackButtonContainer = styled.div<IbackButtonContainer>`
    display: flex;
    align-items: center;
    
    border: none;
    ${props => props.onlyTextfield ? css`
    // padding: 0 0 0 10px;
    
    ` : css`
        // padding: 0 0 0 3rem;
        flex: 1;
        // @media (max-width: 764px) {
            // padding: 0 0 0 22px;
        // }
    `};

    padding: ${props => props.padding}

`;
