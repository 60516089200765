import styled from 'styled-components';

export interface CategoriasCardContainerProps {
  padding?: string;
}

export const CategoriasCardContainer = styled.div<CategoriasCardContainerProps>`
    border-radius: 15px;
    border: 1px solid ${props => props.theme.colors.grey198};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 110px;
    height: 130px;
    cursor: pointer;

    overflow: hidden;

    .paragraphContainer {
      text-overflow:ellipsis;
      overflow:hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      width: 110px;
      word-break: break-word;
      padding: 0 5px 0 5px;
    }
`;

interface ContainerProps {
	margin?: string;
}

export const Container = styled.div<ContainerProps>`
	position: relative;
	margin: ${props => props.margin || '0'};

	.yellowContainer {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
	}
`;


export const YellowTag = styled.div`
    padding: 2px 5px 0px 5px;
    background-color: ${props => props.theme.colors.yellow80};
    font-family: ${props =>props.theme.text.font.primary};
    border-radius: 4px;
    font-size: ${props => props.theme.text.size.caption};
    font-weight: ${props => props.theme.text.weight.bold};
    height: 23px;
    z-index: 10;
    white-space:nowrap !important;
`;