import React from 'react';
import useWarningTimeOutModal from '~/hooks/useWarningTimeOutModal';
import { InivelesTienda } from '~/interfaces';
import { WarningModal } from '..';

type Props = {
    nivelTienda: InivelesTienda,
};

const WarningTimeOutModal : React.FC<Props> = ({ nivelTienda } : Props) => {
	const {
		handleClose,
		open,
		horaFin, 
		handleGoBack,
		horaInicio
	} = useWarningTimeOutModal(nivelTienda);

	return (
		<WarningModal
			open={open}
			title={'Fuera de horario'}
			description={`Los ${nivelTienda?.cDescripcion?.toLowerCase()} están disponibles en un horario entre ${horaInicio} y ${horaFin}
				
				Aún así, puedes programar una orden
			`}
			aceptLabel='Sí, quiero programar una orden'
			handleAcept={handleClose}
			cancelLabel='Volver al menú principal'
			handleClose={handleGoBack}
			buttonsDirection='column-reverse'
			buttonsSize='lg'
			acceptColor='black'
		/>
	);
};
export default (WarningTimeOutModal);