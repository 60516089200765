import styled from 'styled-components';
import { show } from '~/styles/keyframes';

export interface WarningContainerProps {
  padding?: string;
}

export const WarningContainer = styled.div<WarningContainerProps>`
    background: ${props => props.theme.colors.warningYellowBg};
    color: ${props => props.theme.colors.ocher};
    padding: ${props => props.padding};
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    align-items: center;
    display: flex;
    margin-top: 15px;
    
    font-family: ${props =>props.theme.text.font.primary};
    font-size: ${props => props.theme.text.size.caption};
    font-weight: ${props => props.theme.text.weight.regular};
    animation: ${show} 0.2s forwards;

`;