import React from 'react';
import { WarningModal } from '~/components';
type Props = {
    openDeleteDireccion: boolean,
    hadleCloseDeleteDireccion: VoidFunction,
    handleDeleteDireccion: VoidFunction,
	loading?: boolean
};
const DeleteDirecciones : React.FC<Props> = ({ 
	openDeleteDireccion,
	hadleCloseDeleteDireccion,
	handleDeleteDireccion,
	loading
} : Props) => {
	return (
		<WarningModal 
			loading={loading} 
			title='¿Eliminar dirección?'
			description='Se eliminará esta dirección permanentemente'
			open={openDeleteDireccion}
			aceptLabel='Eliminar'
			cancelLabel='Cancelar'
			height='auto'
			handleAcept={handleDeleteDireccion} 
			handleClose={hadleCloseDeleteDireccion} 
		/>
	);
};
export default (DeleteDirecciones);