import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';
import { IDataSucursales, ISucursal, TIPO_SUCURSALES } from '~/interfaces';
import _ from 'lodash';

const useSucursales = () => {
	const [activeTab, setActiveTab] = useState<IDataSucursales | undefined>(undefined);
	const [isShowing, setIsShowing] = useState(false);
	const [tipoSucursal, setTipoSucursal] = useState(TIPO_SUCURSALES.PASTELERIA);
	const navigate = useNavigate();

	const handleActiveTab = (value: IDataSucursales) => {
		setActiveTab(value);
		navigate(`/sucursales/${encodeURI(value.cMunicipio)}`);
	};

	const handleSendFBEvent = (item: ISucursal) => {
		window.fbq('track', 'FindLocation', {
			content_name: item.cSucursal,
		});
	};

	const [activeSucursal, setActiveSucursal] = useState<ISucursal | undefined>(undefined);

	const handleClose = () => {
		setIsShowing(false);
	};

	const handleClickPasteleria = (item: ISucursal) => {
		handleClickSucursal(item);
		setTipoSucursal(TIPO_SUCURSALES.PASTELERIA);
		handleSendFBEvent(item);
	};

	const handleClickRestaurante = (item: ISucursal) => {
		handleClickSucursal(item);
		setTipoSucursal(TIPO_SUCURSALES.RESTAURANTE);
		handleSendFBEvent(item);
	};

	const handleClickSucursal = (item: ISucursal) => {
		setActiveSucursal(item);
		setIsShowing(true);
	};

	const [dataSucursales, setDataSucursales] = useState<IDataSucursales[] | undefined>(undefined);
	const apiUrl = import.meta.env.VITE_API_URL;
	const {
		headersApi, 
		handleDoneInitialLoading,
	} = useContext(CookiesContext);
	const params = useParams();

	const handleGetSucursales = () => {
		fetch(`${apiUrl}/APPWEB/ConsultaSeccionSucursales`, {
			method: 'GET',
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {
			if (response && response.Result?.sucursales) {
				handleDoneInitialLoading();
				const data: IDataSucursales[] | undefined = response.Result?.sucursales;
				setDataSucursales(data);
				if(data && data.length > 0){
					if(params?.tipoMunicipio){
						setActiveTab( (_.find(data, (o: IDataSucursales)=>o.cMunicipio === decodeURIComponent(params?.tipoMunicipio || ''))) || data[0]);
					} else {
						setActiveTab(data[0]);
					}
				}
			}
		}).catch((error) => {
			console.error(error, 'error');
		});
	};

	useEffect(() => {
		handleGetSucursales();
	}, []);

	return {
		activeTab,
		handleActiveTab,
		isShowing,
		activeSucursal,
		handleClose,
		handleClickPasteleria,
		handleClickRestaurante,
		tipoSucursal,
		dataSucursales,
		apiUrl
	};
};

export default useSucursales;