import styled from 'styled-components';

export const Label = styled.label`
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
`;

export const SwitchComponent = styled.div`
	position: relative;
	width: 52px;
	height: 32px;
	background: ${props => props.theme.colors.grey240};
	border: 2px solid ${props => props.theme.colors.disabledGreyBorder};
	border-radius: 100px;
	padding: 8px;
	
	transition: 300ms all ease;

	&:before {
		transition: 300ms all ease;
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		border-radius: 35px;
		top: 50%;
		left: 8px;
		background: ${props => props.theme.colors.disabledGreyBorder};
		transform: translate(0, -50%);
	}
`;

export const Input = styled.input`
	display: none;

	&:checked + ${SwitchComponent} {
		background: ${props => props.theme.colors.primary};
		border: 2px solid ${props => props.theme.colors.primary};

		&:before {
			height: 24px;
			width: 24px;
			background: white;
			left: calc(100% - 4px - 24px);
		}
	}
`;
