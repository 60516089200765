import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '~/assets/icons';
import { ISubCategorias } from '~/interfaces';
import { RipplesButtons } from '..';
import CarrouselCard from '../CarrouselCard';
import Divider from '../Divider';
import Grid from '../Grid';
import Typography from '../Typography';
import { CategoriasAppBarContainer, CategoriaTab, ScrollContainer } from './styled';

type Props = {
    activeTab?: number;
    handleActiveTab: (value: number) => void;
	categorias: ISubCategorias[];
	nNivelTienda: string;
	xs?: boolean
};
const CategoriasAppBar : React.FC<Props> = ({ activeTab, handleActiveTab, categorias, nNivelTienda, xs } : Props) => {
	// Te lleva al menu principal
	const navigate = useNavigate();

	const handleGoToMain = () => {
		navigate(`/tienda/${nNivelTienda}`);
	};
	const handleGoTo = (categoriaId: number) => {
		navigate(`/tienda/${nNivelTienda}/categoria/${categoriaId}`);
		handleActiveTab(categoriaId);
	};

	const CategoriasItems = ({item}: {item: ISubCategorias}) => (
		<CategoriaTab active={activeTab === item.nCategoria} onClick={()=>handleGoTo(item.nCategoria)}>
			{item.cDescripcion}
		</CategoriaTab>
	);
    
	return (
		<CategoriasAppBarContainer>
			{
				xs ? (
					<Grid item padding='0 0 0 1.5rem'>
						<RipplesButtons  onClick={handleGoToMain}>
							<Grid container alignItems='center' padding='4px 0 0 0'>
								<ArrowBackIcon color='gray_900'/>

								<Grid item flex padding='0 0 0 5px'>
									<Typography className='noselect' pointer variant='subtitle1bold'>
										Volver
									</Typography>
								</Grid>
							</Grid>
						</RipplesButtons>
					</Grid>
				) : null
			}
			
			<Grid item flex container>
				{
					xs ? (
						<>
							<Grid width='1rem' item container justifyContent='flex-end' margin='0 1rem 0 0'>
								<Divider vertical/>
							</Grid>
	
							<Grid item flex container direction='column' width='400px'>
								<CarrouselCard margin='0'>
									{
										categorias?.map((item, i)=> (
											<CategoriasItems item={item} key={i} />
										))
									}
								</CarrouselCard>
							</Grid>
						</>
					) : (
						<ScrollContainer flex item>
							<div className='container'>
								{
									categorias?.map((item, i)=> (
										<CategoriasItems item={item} key={i} />
									))
								}
							</div>
						</ScrollContainer>
					)
				}
			</Grid>
		</CategoriasAppBarContainer>
	);
};
export default (CategoriasAppBar);