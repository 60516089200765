import React, { useContext, useState } from 'react';
import { Modal, ProfileGrid, SideBarContainer } from './styled';
import { useNavigate } from 'react-router-dom';
import { InivelesTienda, UserInfoType } from '~/interfaces';
import { LogOutIcon, ReciptLongIcon, SellIcon, StoreIcon, SupportIcon, TrophyIcon } from '~/assets/icons';
import { MenuItemChevron, ProfileCards, Typography, MenuItem, Divider, CloseButton, Button, WarningModal, CardMedia, Grid } from '..';
import _ from 'lodash';
import { PATHS } from '~/constants/paths';
import { useDialog } from '~/hooks';
import { AlertToastContext } from '~/context/alertToastContext';
import { handleGoToFacturacion } from '~/utils';

import HeartIconImg from '~/assets/icons/heart 64x64.png';
import PlaceholderImg from '~/assets/icons/placeholder 64x64.png';
import PedidosImg from '~/assets/icons/pedidos 64x64.png';
import EscudoExclamacion from '~/assets/icons/shieldExclamacion.png';
import { ProfileNotVerified } from '~/views/ajustesDeCuenta/styled';

type Props = {
	open: boolean;
	handleClose: VoidFunction;
	userInfo: UserInfoType | null;
	nivelesTienda: InivelesTienda[];
	handleLogout: (onFinish?: VoidFunction) => void;
};

type PropsSideBarContainer = {
	handleClose: VoidFunction;
	open: boolean;
	children: JSX.Element | JSX.Element[];
};

export const SideModalBarContainer: React.FC<PropsSideBarContainer> = ({ handleClose, open, children }: PropsSideBarContainer) => {
	return (
		<Modal onClick={handleClose} open={open}>
			{children}
		</Modal>
	);
};

const SideBar: React.FC<Props> = ({ userInfo, handleClose, open, nivelesTienda, handleLogout }: Props) => {
	const navigate = useNavigate();

	const SIDE_BAR_MENU = [
		{
			cNombre: 'Cupones y promociones',
			nValor: 1,
			path: '/cupones-promociones',
			invitado: false,
			icon: <SellIcon />,
		},
		{
			cNombre: 'Sucursales',
			nValor: 2,
			path: '/sucursales',
			icon: <StoreIcon />,
		},
		{
			cNombre: 'Ayuda',
			nValor: 3,
			path: '/ayuda',
			icon: <SupportIcon />,
		},
		{
			cNombre: 'Portal de facturación',
			nValor: 4,
			path: '/metodos-de-pago',
			icon: <ReciptLongIcon />,
			onclick: handleGoToFacturacion,
		},
		{
			cNombre: 'Dinámicas',
			nValor: 12,
			path: '/dinamicas',
			invitado: false,
			icon: <TrophyIcon />,
		},
		{
			cNombre: 'Dinámicas',
			nValor: 13,
			path: '/autenticate/Dinam',
			invitado: true,
			icon: <TrophyIcon />,
		},
		// {
		// 	cNombre: 'Empleados',
		// 	nValor: 15,
		// 	icon: <EmpleadoIcon />,
		// 	onclick: handleGoToPanaNews,
		// },
		{
			cNombre: 'Cerrar sesión',
			invitado: false,
			nValor: 5,
			onclick: () => {
				handleOpenLogoutModal();
				handleClose();
			},
			icon: <LogOutIcon />,
		},
	];

	const handleGoLogin = () => {
		navigate(PATHS.Auth);
	};

	const handleGoCrearCuenta = () => {
		navigate(PATHS.Auth);
	};

	const { open: openLogoutModal, handleOpen: handleOpenLogoutModal, handleClose: handleCloseLogoutModal } = useDialog();

	const [loading, setLoading] = useState(false);
	const { showBlackAlert } = useContext(AlertToastContext);

	return (
		<>
			<WarningModal
				width="341px"
				height='auto'
				loading={loading}
				title='Cerrar sesión'
				description='¿Seguro que desea cerrar sesión?'
				handleAcept={() => {
					setLoading(true);
					handleLogout(() => {
						handleCloseLogoutModal();
						setLoading(false);
						showBlackAlert('Se ha cerrado sesión correctamente. ¡Hasta pronto!');
						navigate('/');
					});
				}}
				open={openLogoutModal}
				handleClose={handleCloseLogoutModal}
			/>

			<SideModalBarContainer handleClose={handleClose} open={open}>
				<SideBarContainer showSideBar={open} onClick={(e) => e.stopPropagation()}>
					<div className="body">
						<Grid container padding="1rem 1rem 0 1rem">
							<CloseButton onClick={handleClose} />
						</Grid>

						<div className="body-side-bar">
							<Grid container direction="column">
								<Grid container direction="column" flexWrap='nowrap' spacing="8px" padding="1rem">
									{userInfo?.invitado ? (
										<>
											<Button onClick={handleGoLogin} variant="solid">
												Inicia sesión
											</Button>

											<Button onClick={handleGoCrearCuenta} variant="outline">
												Crear cuenta
											</Button>
										</>
									) : (
										<>
											<Grid spacing="8px" justifyContent="space-between" flexWrap='nowrap' container width="100%">
												<ProfileCards
													alignItems="start"
													onClick={() => navigate('/perfil')}
													titleVariant="subtitle1bold"
													title={userInfo ? `${userInfo.cNombre} ${userInfo.cApellido_Paterno} ${userInfo.cApellido_Materno}` : ''}
												>
													<Grid direction='row' container spacing='8px'>
														<Typography variant="subtitle2regular">Hola</Typography>
														
														{userInfo && 'bCorreoConfirmado' in userInfo && userInfo?.bCorreoConfirmado === false ? (
															<ProfileNotVerified>
																<CardMedia src={EscudoExclamacion} staticImg maxwidth='10px' />
		
																<Typography color='gray_600' variant='overline'>Verifica tu correo</Typography>
															</ProfileNotVerified>
														) : ''}
													</Grid>
												</ProfileCards>
											</Grid>

											<ProfileGrid container spacing='8px'>
												<ProfileCards onClick={() => navigate('/favoritos')} title="Favoritos">
													<CardMedia src={HeartIconImg} style={{ margin: '0 0 0.5rem 0' }} maxwidth="32px" staticImg />
												</ProfileCards>

												<ProfileCards onClick={() => navigate('/mis-direcciones')} title="Direcciones">
													<CardMedia src={PlaceholderImg} style={{ margin: '0 0 0.5rem 0' }} maxwidth="32px" staticImg />
												</ProfileCards>

												<ProfileCards onClick={() => navigate('/historial-de-pedidos')} title="Pedidos">
													<CardMedia src={PedidosImg} style={{ margin: '0 0 0.5rem 0' }} maxwidth="32px" staticImg />
												</ProfileCards>
											</ProfileGrid>
										</>
									)}
								</Grid>

								<Divider />

								<Grid container direction="column" spacing="8px" padding="1rem">
									<Typography variant="subtitle2regular">Tienda en Línea</Typography>

									<Grid container direction="column" spacing="24px">
										{(nivelesTienda || []).map((item, i) => {
											return (
												<MenuItemChevron key={i} onClick={() => navigate(`/tienda/${item.nNivelTienda}`)}>
													<>
														<Typography variant="subtitle1bold">{item.cDescripcion}</Typography>

														{item.bHorarioDisponible === 0 ? (
															<>
																<br></br>
																<Typography color="grey" variant="overline">
																	Fuera de horario - Programar para más tarde
																</Typography>
															</>
														) : (
															''
														)}
													</>
												</MenuItemChevron>
											);
										})}
									</Grid>
								</Grid>

								<Divider />

								<Grid container direction="column" spacing="8px" padding="1rem">
									<Typography variant="body2light">Información</Typography>

									<Grid container direction="column">
										{_.filter(SIDE_BAR_MENU, (o) => (o.invitado !== undefined ? o.invitado === userInfo?.invitado : true)).map((item, i) => {
											return (
												<MenuItem key={i} onClick={item.onclick ? () => item.onclick() : () => navigate(item.path || '')}>
													<Grid container alignItems="center" spacing="8px">
														{item.icon}

														<Grid item flex>
															<Typography className="noselect" pointer variant="subtitle1bold">
																{item.cNombre}
															</Typography>
														</Grid>
													</Grid>
												</MenuItem>
											);
										})}
									</Grid>
								</Grid>
							</Grid>
						</div>
					</div>
				</SideBarContainer>
			</SideModalBarContainer>
		</>
	);
};
export default SideBar;
