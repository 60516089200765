import React from 'react';
import { ArrowBackIcon } from '~/assets/icons';
import Grid from '../Grid';
import IconButton from '../IconButton';
import Typography from '../Typography';
import { BackButtonContainer } from './style';
type Props = {
	title: string,
	onClick: VoidFunction,
	xs?: boolean,
	padding?: string,
	onlyTextfield?: boolean
};
const BackButton : React.FC<Props> = ({ onClick, title, xs, onlyTextfield, padding } : Props) => {
	return (
		<BackButtonContainer padding={padding} onlyTextfield={onlyTextfield}>
			<Grid item>
				<IconButton onClick={onClick}>
					<ArrowBackIcon />
				</IconButton>
			</Grid>
			{
				xs !== undefined ? xs : true && <Grid item xs>
					<Typography className='noselect' onClick={onClick} pointer variant='subtitle1bold'>
						{title}
					</Typography>
				</Grid>
			}
			
		</BackButtonContainer>
	);
};
export default (BackButton);