import styled from 'styled-components';

export interface DividerProps {
    margin?: string;
  }
  
export const Divider = styled.div`
    height: 1px !important;
    background-color: ${props => props.theme.colors.gray_500};
    flex: 1;
`;

export const PanaDividerContainer = styled.div<DividerProps>`
    display: flex;
    margin: ${props => props.margin};
    position: relative;
    height: 16px;
    align-items: center;
    .imageContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .circularContainer {
      aspect-ratio: 1;
      display: grid;
      place-items: center;
      background-color: ${props => props.theme.colors.white};
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      width: 16px;
      height: 16px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 1px;
      text-align: center;
    }
`;
