import styled, { keyframes } from 'styled-components';

export interface OptionContainerProps {
    margin?: string;
    borderWarning?: boolean;
}

export const OptionContainer = styled.div<OptionContainerProps>`
    border: 1px solid ${props => props.theme.colors[props.borderWarning ? 'red_600' : 'gray_300']};
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: ${props => props.margin};
`;

const show = keyframes`
    from {
        opacity: 0;    
    }

    to {
        opacity: 1;    
    }
`;

export const ShowContainer = styled.div`
    animation: ${show} 0.2s forwards;
`;