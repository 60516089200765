import styled from 'styled-components';

export interface CarrouselProps {
    marginleft?: string | null;
    padding?: string | null;
}
export const CarrouselContainer = styled.div<CarrouselProps>`
    overflow-x: hidden;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    background-color: white;
    padding: ${props => props.padding || '5px'};
    z-index: 1;
    
    height: 100%;
    min-height: 300px;

    .div {
        width: 100%;
        display: flex;
        transition: margin-left 0.7s ease-in-out;
        margin-left: ${props => props.marginleft};
    }
`;

export const CarrouselItem = styled.div<CarrouselProps>`
    width: 100%;
    flex: 0 0 100%;
    
    margin-left: ${props => props.marginleft};
    transition: all 0.3 ease;
`;

/* 

<div className='div'>

        </div>
    .div {
        left: ${props => props.marginleft};
        position: absolute;
        display: flex;
        
        justify-content: flex-start;
        top: 0px;
        bottom: 0px;

    }
*/
