import styled, { css } from 'styled-components';

export const DetalleLayout = styled.div`
    
    flex: auto;
    display: flex;
    border-radius: 7px;
    background-color: ${props => props.theme.colors.white};
    flex-direction: column;
    padding: 0 0 15px 0;
    margin-bottom: 50px;
    
`;

export const DetalleContainer = styled.div`
    padding: 40px;
    display: flex;
    overflow: hidden;
    
    flex: auto;
    position: relative;

    gap: 80px;

    @media (max-width: 865px) {
        overflow: auto;
        flex-direction: column;
        padding: 0px 0px 75px;
        gap: 0px;
    }
`;

export const ImgContainer = styled.div`
    position: sticky;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 50%;
    background-color: 'red_500';

    img {
        border-radius: 22px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 865px) {
        max-width: 100%;
        padding: 0;
        .item {
            padding: 0 16px 8px 16px;
        }
        .lastItem {
            padding: 0 16px 0 16px;
        }


        width: 100%;
        img {
        width: 100%;

        }
    }
`;

export interface DividerProps {
    margin?: string;
    padding?: string;
    checked?: boolean;
}

export const Divider = styled.div<DividerProps>`
    height: 1px;
    background-color: ${props => props.theme.colors.borderLightGrey};
    margin: ${props => props.margin};
`;


export const ConfigProductoContainer = styled.div<DividerProps>`
    display: flex;
    flex-direction: column;
    position: relative;

    gap: 8px;
    margin: ${props => props.margin};
    padding: ${props => props.padding ? props.padding : '16px 16px 0 16px'};

    @media (max-width: 865px) {
        padding: 0px 16px;
    }
`;
export interface ItemRadioProps {
    checked?: boolean;
    checkbox?: boolean;
    disabled?: boolean
}
export const ItemRadio = styled.div<ItemRadioProps>`
    display: flex;
    align-items: center;
    padding: 16px 12px;
    gap: 5px;
    min-height: 60px;
    cursor: ${props => props.disabled ? null : 'pointer'};
    border-radius: 4px;

    ${props => props.disabled ? css`
        background-color: ${props.theme.colors.gray_50};
        border: 1px solid ${props.theme.colors.gray_500};
    ` : props.checked && !props.checkbox ? css`
        background-color: ${props.theme.colors.orange_200};
    ` : css`
        border: 1px solid ${props.theme.colors[props.checked && props.checkbox ? 'green_500' : 'gray_500']};
    `}
`;

export const Tag = styled.span`
    ${props => css`
        font-size: ${props.theme.text.size.caption};
        color: ${props.theme.colors.grey};
        font-family: ${props.theme.text.font.primary};
    `}
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    width: fit-content;

    border: 1px solid ${props => props.theme.colors.grey};
`;


export const Footer = styled.div`
    background-color: ${props => props.theme.colors.white};

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px 0 0 0;
    @media (max-width: 865px) {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 5;
        padding: 8px 0px;
    }
`;

export const ExtraContainer = styled.div`
    display: flex;
    position: relative;
    border-radius: 5px;
    align-items: center;
    
    border: 1px solid ${props => props.theme.colors.grey198};
    padding-right: 10px;

`;


export const ScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px 0px 5px 0;
    overflow-y: auto;
    flex: 1;
    
    border: 1px solid ${props => props.theme.colors.grey198};
    
    border-radius: 22px;
    @media (max-width: 865px) {
        border: none;
        padding: 16px 0px 0px 0px;
        gap: 16px;
        overflow-y: hidden;
    }

`;

export const CloseContainer = styled.div`
    display: flex;
    padding: 20px 15px 10px 15px;
    @media (max-width: 865px) {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 5;
    }
`;