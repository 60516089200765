import styled from 'styled-components';


export const ToggleButtonContainer = styled.div`
    
    position: relative;
    display: flex;
    justify-content: center;
    .itemText{
        cursor: pointer;
        text-align: center;
        z-index: 2;
        padding: 4px 0px 5px 0px;
    }
    .circularContainer {
        border-radius: 30px;
        position: relative;
        width: 342px;
        display: flex;
        border: 1px solid ${props => props.theme.colors.ocher};
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        
        background-color: ${props => props.theme.colors.greyF5};
        height: 40px;
        gap: 3px;
        
        align-items: center;
    }
`;

export interface ToggleItemContainerProps {
    width?: number;
}
  
export const ToggleItemContainer = styled.div<ToggleItemContainerProps>`
    text-align: center;
    position: absolute;
    top: -4px;
    bottom: -2px;
    left: -1px;
    z-index: 1;
    
    cursor: pointer;
    width: ${props => `${props.width}px`};
    border-radius: 30px;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: flex-end;
    .item {
        transition: all 0.2s ease-in-out;
        background-color: ${props => props.theme.colors.yellow};
        border-radius: 30px;
        border: 1px solid ${props => props.theme.colors.yellow};
        
        height: 46px;
        width: 171px;

    }

`;
