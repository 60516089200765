import styled, { css } from 'styled-components';

export interface SelectorContainer {
    checked?: boolean;
    disabled?: boolean;
    color?: 'default' | 'green';
}

export const SelectorContaier = styled.div<SelectorContainer>`
    display: flex;
    align-items: center;
	justify-content: space-between;
    padding: 0px 12px;
    gap: 5px;
    height: 56px;
    max-height: 56px;
    cursor: ${props => props.disabled ? 'auto' : 'pointer'};
    border-radius: 4px;
    user-select: none;

    ${props => props.disabled ? css`
        background-color: ${props.theme.colors.gray_50};
        border: 1px solid ${props.theme.colors.gray_500};
    ` : props.checked && props.color === 'default' ? css`
        background-color: ${props.theme.colors.orange_200};
    ` : props.checked && props.color === 'green' ? css`
        border: 1px solid ${props.theme.colors['green_500']};
    ` : css`
        border: 1px solid ${props.theme.colors['gray_500']};
    `}
`;