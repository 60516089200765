import styled, { css } from 'styled-components';

export interface Props {
    bold?: boolean;
    padding?: string;
    color?: string;
    family?: string;
    margin?: string;
    lineHeight?: string;
    variant?: string;
    numLines?: number;
  }

const Paragraph = styled.p<Props>`
padding: ${props =>props.padding || '0'};

  margin: ${props =>(props.margin || '0px')};
  font-family: ${props =>props.theme.text.font.primary};
  color: ${props => props.theme.colors[props.color ? props.color: 'black']};
  line-height: ${props =>props.lineHeight ? props.lineHeight : '1.2'};

  /* Configuración por defecto para todos los párrafos */
  ${props => props.numLines === undefined && css`
    white-space: pre-line;
  `}

  /* Limitar a número específico de líneas con puntos suspensivos (compatible con múltiples navegadores) */
  ${props => props.numLines !== undefined && css`
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-line-clamp: ${props.numLines};
    -webkit-box-orient: vertical;
    -ms-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: calc(${props.lineHeight || '1.2'} * ${props.numLines}em);
  `}

  ${props => {
		switch (props.variant) {
		case 'body1':
			return css`
          font-size: ${props.theme.text.size.subtitle1};
          font-weight: ${props.theme.text.weight.medium};
        `;
		case 'body2':
			return css`
          font-size: ${props.theme.text.size.subtitle2};
          font-weight: ${props.theme.text.weight.medium};
        `;
		case 'caption':
			return css`
          font-size: ${props.theme.text.size.caption};
          font-weight: ${props.theme.text.weight.regular};

        `;
		case 'overline':
			return css`
          font-size: ${props.theme.text.size.overline};
          font-weight: ${props.theme.text.weight.regular};

        `;
		default:
			return css`
          font-weight: ${props.theme.text.weight.regular};
          font-size: ${props.theme.text.size.subtitle2};
      `;
		}
	}}
`;
export default Paragraph;