import styled, { css } from 'styled-components';

export const FormContainer = styled.div`
    --error-color: ${props =>props.theme.text.font.red_500};

    display: flex;
    flex-direction: column;

    font-family: ${props =>props.theme.text.font.primary} !important;
    position: relative;

    form {
      padding: 0px !important;
    }

    form form {
      padding: var(--form-vertical-padding) var(--form-horizontal-padding) !important;
    }

    form > h1 {
      padding: 0 0 24px 0 !important;
      text-align: center !important;
    }

    @media (max-width: 952px) {
      /* Added styles for submit wrapper */
      [data-testid="submit-wrapper"] {
        position: fixed !important;
        bottom: 0 !important;
        left: 0 !important;
        width: 100% !important;
        margin-bottom: 0px !important;
        background-color: white !important;
        padding: 8px 16px !important;
        box-shadow: 0px -6px 34px 0px rgba(0, 0, 0, 0.16) !important;
        z-index: 1000 !important;
        display: flex !important;
        justify-content: center !important;
        flex-direction: row !important;
      }

      button {
        min-width: 374px !important;
      }
    }


    button {
      background-image: none !important;
      background-color: ${props =>props.theme.colors.gray_900} !important;
      border-radius: 16px !important;
      text-transform: capitalize !important;
      padding: 12px !important;
      color: ${props =>props.theme.colors.white} !important;
    }
    
    button svg {
      display: none;
    }
`;

export interface LoadingContainerProps {
  showGeneralLoading?: boolean;
}

export const LoadingContainer = styled.div<LoadingContainerProps>`
    position: absolute;
    display: flex;
    
    top: 0;
    bottom: 0;
    justify-content: center;
    width: 100%;
    min-height: 200px;
    z-index: 100000;
    background-color: ${props => props.theme.colors.white};
    .container {
      height: 200px;
    }
    ${props => props.showGeneralLoading ? css`
        display: flex;
        opacity: 1;

        ` : css`
        display: none;

    `}
`;
