import React, { Fragment } from 'react';
import { Button, CardMedia, Container, ControlLabel, ExpansionPanelBottom, Grid, Loading, TextField, Typography } from '~/components';
import { useDinamica, useTitle } from '~/hooks';
import { RedencionTitle } from './styled';
import { getCdnUrl, handleEnter } from '~/utils';
import { RoundCardContainer } from '../checkout/styled';
import RedencionTicket from './RedencionTicket';
import { DINAMICAS_TIPO } from '~/constants';

const Dinamica: React.FC = () => {
	const { 
		folio, 
		checked, 
		dinamica, 
		check: validFolio, 
		error: invalidFolio, 
		helperText, loading, 
		isOpenCanjeados, 
		setIsOpenCanjeados, 
		handleCheckBox, 
		handleFolio, 
		handleSubmit, 
		handleGoToBases 
	} = useDinamica();

	useTitle(dinamica?.cDescripcion && `Dinámica ${dinamica?.cDescripcion}`);

	return (
		dinamica ? (
			<Container padding="2rem 0 0 0" center>
				<Grid margin="0 auto" maxwidth="400px">
					<CardMedia 
						alt={dinamica?.cDescripcion && 'Dinamica de ' + dinamica.cDescripcion} 
						borderRadius="30px" 
						minHeight="200px" 
						height="100%" 
						src={getCdnUrl(dinamica.cUrlImagen)}
					/>

					<Container margin="0 0 3rem 0">
						{dinamica.cUrlBases && (
							<Grid item flex>
								<Button onClick={handleGoToBases} margin="0px 0px 11px 0px" fullWidth variant="underline">
									Consulta las bases y restricciones
								</Button>
							</Grid>
						)}

						{dinamica.nTipo === DINAMICAS_TIPO.REGISTRO_WEB.nTipo && (
							<>
								<Grid item flex>
									<TextField
										onKeyDown={(e) => handleEnter(e, handleSubmit, validFolio && checked)}
										check={validFolio}
										error={invalidFolio}
										maxlength={20}
										helperText={helperText || dinamica?.cHelpText || 'El folio aparece en la parte de arriba de tu ticket.'}
										helperTextAlign='justify'
										label="Folio"
										value={folio}
										onChange={handleFolio}
									/>
								</Grid>
								<Grid margin="22px 0px" item flex>
									<ControlLabel checked={checked} onClick={handleCheckBox}>
										Al marcar esta casilla, confirmo que soy residente del estado de Sinaloa, soy mayor de edad, y he leído y acepto las bases y restricciones de esta dinámica.
									</ControlLabel>
								</Grid>
								<Grid item flex>
									<Button loading={loading} fullWidth onClick={handleSubmit} disabled={!(validFolio && checked)} variant="solid">
										Registrar Ticket
									</Button>
								</Grid>
							</>
						)}
					</Container>

					{
						(dinamica.nTipo === DINAMICAS_TIPO.REGISTRO_WEB.nTipo || dinamica.nTipo === DINAMICAS_TIPO.SIN_REGISTRO.nTipo) && (
							Array.isArray(dinamica?.ticketsRedimidos) && dinamica.ticketsRedimidos.length !== 0 ? (
								<RoundCardContainer padding='8px 16px' margin="0 0 3rem 0">
									<ExpansionPanelBottom 
										title={
											<Grid container direction='column'>
												<Typography variant='subtitle1bold'>Tickets Canjeados</Typography>
												<Typography variant='captionRegular'>{dinamica.ticketsRedimidos.length} Ticket{dinamica.ticketsRedimidos.length === 1 ? '' : 's'}</Typography>
											</Grid>
										}
										open={isOpenCanjeados} 
										handleOpen={() => setIsOpenCanjeados(!isOpenCanjeados)} 
										bordertop={false}
									>
										{dinamica.ticketsRedimidos?.map((item, e) => (
											// e > 0 
											// ? <RedencionTicket cDescripcion={dinamica.cDescripcion} item={item} key={e} /> 
											<RedencionTicket cDescripcion={dinamica.cDescripcion} item={item} key={e} /> 
											// : <Fragment key={e}></Fragment>
										))}
									</ExpansionPanelBottom>
								</RoundCardContainer>
							) : (
								<RedencionTitle>No se ha canjeado ningún ticket</RedencionTitle>
							)
						)
					}
				</Grid>
			</Container>
		) : (
			<Loading />
		)
	);
};

export default Dinamica;
