import styled from 'styled-components';

export interface IconButtonStyledProps {
    bgColor?: string;
    width?: string;
    height?: string;
    disabled?: boolean;
    padding?: string;
}
  
export const IconButtonStyled = styled.div<IconButtonStyledProps>`
    aspect-ratio: 1;
    border-radius: 50%; 
    padding: ${props => props.padding || '2px'};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.bgColor ? props.theme.colors[props.bgColor] : 'transparent'};
    border: none;
    text-align: center;
    cursor: ${props => props.disabled ? '' : 'pointer'};
    overflow: hidden;
    width: ${props =>props.width};
    height: ${props =>props.height};
`;