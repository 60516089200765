import styled, { keyframes, css } from 'styled-components';
import { CheckboxContainerProps } from '../CheckBox/styled';
import { productoHide } from '~/styles/keyframes';

export interface ProductCardProps {
    disabled?: boolean;
}

export const ProductCardContainer = styled.div<ProductCardProps>`
    border-radius: 16px;
    border: 1px solid ${props => props.theme.colors.gray_300};
    position: relative;
    padding: 16px;
    
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-width: 341px;
    height: 293px;

    ${props => props.disabled ? css`
        background-color: ${props.theme.colors.disabledCardBg};
    ` : css`
        background-color: ${props.theme.colors.white};
    `}

`;
export interface ProductCardContainerProps {
    hide?: boolean;
    disabled?: boolean;
}

const check = keyframes`
    from {
        transform: scale(0.9);
        display: none;
        opacity: 0;
    }

    to {
        opacity: 1;
        display: block;
        transform: scale(1.0);
    }
`;
const unCheck = keyframes`
    from {
        opacity: 1;    
        transform: scale(1.0);
        display: block;
    }

    to {
        transform: scale(0.9);
        opacity: 0;    
        display: none;
    }
`;

export const ViewIconContainer = styled.div<CheckboxContainerProps>`
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    width:  100%;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    top: 8px;
    right: 8px;

    .icon{
        position: relative;
        width: 24px;
        height: 24px;
    }

    .check {
        position: absolute;
    }

    .uncheck {
        position: absolute;
    }

    ${props => props.checked === true ? css`
        .check {
            animation: ${check} 0.2s forwards;
        }

        .uncheck {
            animation: ${unCheck} 0.2s forwards;
        }
    ` : props.checked === false ? css`
        .check {
            animation: ${unCheck} 0.2s forwards;
        }

        .uncheck {
            animation: ${check} 0.2s forwards;
        }
    ` : css`
        .check {
            opacity: 0;
        }

        .uncheck {
            opacity: 1;
        }
    `}
`;

export const ProductCardContainerMain = styled.div<ProductCardContainerProps>`
    display: flex;
    width: 100%;
    max-width: 343px;
    margin-bottom: 15px;
    justify-content: center;
    ${props => props.hide ? css`
        animation-name: ${productoHide};
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
    ` : null}
    transition: display 0.3s ease;

    ${props => props.disabled ? null : css`
        &:hover{
            cursor: pointer;
        }
    `}
`;

export const ImgContainer = styled.img<ProductCardContainerProps>`

    min-height: 190px;
    width: 100%;
    border-radius: 22px 22px 0px 0px;
    
`;

export const StarContainer = styled.div`
    position: absolute;
    z-index: 10;
    left: 20px;
    top: 12px;
`;

export const ViewIcon = styled.div`
    border-radius: 0px 0px 30px 30px;
    border: 1px solid ${props => props.theme.colors.borderLightGrey};
`;

export interface PopularTagProps {
    disabled?: boolean;
    show?: boolean;
}

export const PopularTag = styled.div<PopularTagProps>`
    background-color: ${props => props.theme.colors[props.disabled ? 'disabledFontGrey' : 'red_500']};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    padding: 2px 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    // width: ${props => props.disabled ? '100px' : '58px'};
    display: flex;
    
    justify-content: center;
    align-items: center;
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    display: ${props => props.show ? 'block' : 'none'};
`;

export const DiscountTag = styled.div<PopularTagProps>`
    padding: 2px 5px 3px 5px;
    font-family: ${props =>props.theme.text.font.primary};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: ${props => props.theme.text.size.caption};
    
    height: 23px;
    z-index: 10;
    white-space:nowrap !important;
    color: ${props => props.theme.colors.white};

    ${props => props.disabled ? css`
        background-color: ${props.theme.colors.disabledFontGrey};
    ` : css`
        font-weight: ${props.theme.text.weight.bold};
        background-color: ${props.theme.colors.green_500};
    `}
`;

export const YellowTag = styled.div`
    padding: 2px 4px;
    background-color: ${props => props.theme.colors.yellow_200};
    font-family: ${props =>props.theme.text.font.primary};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: ${props => props.theme.text.size.caption};
    font-weight: ${props => props.theme.text.weight.bold};
    height: 23px;
    z-index: 10;
    
    white-space:nowrap !important;
`;

export interface TagsContainerProps {
    padding?: string;
}

export const TagsContainer = styled.div<TagsContainerProps>`
    position: absolute;
    bottom: 0;
    left: 0;
`;