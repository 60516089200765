import React from 'react';
import { CheckIcon, EditIcon, PriorityHighIcon } from '~/assets/icons';
import { useEdit } from '~/hooks';
import Grid from '../Grid';
import Typography from '../Typography';
import { TextFieldContainer, TextFieldLabel, TextFieldInput, TextFieldContainerInput, IconContainer } from './styled';
export type TextFieldProps = {
  label?: string,
  value?: string,
  padding?: string,
  type?: string,
  helperText?: string,
  helperTextAlign?: string,
  error?: boolean,
  width?: string,
  check?: boolean,
  disabled?: boolean,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>
  noIcon?: boolean
  placeholder?: string,
  autoCompleteOff?: boolean,
  name?: string,
  autoComplete?: string,
  id?: string,
  margin?: string,
  maxlength?:number,
  minlength?:number,
  max?:number,
  pattern?:string,
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>,
  fullWidth?: boolean,
  edit?: boolean,
  inputId?: string
  customBadge?: JSX.Element,
  onWheel?: React.WheelEventHandler<HTMLInputElement>
};

const TextField : React.FC<TextFieldProps> = ({ padding, customBadge, label, noIcon = false, value, onChange, onBlur, onPaste, error, check, name, helperText, helperTextAlign, type, disabled, onKeyDown, width, placeholder, autoCompleteOff, autoComplete, margin, id, maxlength,minlength,max, onKeyPress, pattern, edit, fullWidth, inputId, onWheel } : TextFieldProps) => {

	const {
		editValue,
		handleEditOnChange
	} = useEdit(onChange, edit);
	const icon = error || check || editValue;

	return (
		<TextFieldContainer fullWidth={fullWidth} id={id} margin={margin} width={width} padding={padding}>
			<Grid container spacing='10px' padding='0 0 4px 0'>
				<TextFieldLabel disabled={disabled}>{label}</TextFieldLabel>

				{customBadge}
			</Grid>

			<TextFieldContainerInput disabled={disabled} error={error} check={check && !editValue} >
				<TextFieldInput id={inputId} name={name} onKeyPress={onKeyPress} placeholder={placeholder} onPaste={onPaste} onBlur={onBlur} onKeyDown={onKeyDown} disabled={disabled} autoComplete={autoCompleteOff ? 'one-time-code' : autoComplete ? autoComplete : undefined} type={type || 'text'} icon={icon} onChange={editValue ? handleEditOnChange : onChange} onWheel={onWheel} value={value} maxLength={maxlength} minLength={minlength} max={max} pattern={pattern}/>
				{
					!noIcon && icon && <IconContainer >
						{error ? <PriorityHighIcon color='red_500' /> : editValue ? <EditIcon /> : <CheckIcon color='green' />}
					</IconContainer>
				}
			</TextFieldContainerInput>

			{helperText && <Typography padding='5px 0 0 0' variant='regular' textAlign={ helperTextAlign || 'start' } color={error ? 'red_500' : 'lightGrey'}>{helperText}</Typography>}
		</TextFieldContainer>
	);
};
export default (TextField);