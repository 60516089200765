import styled from 'styled-components';

interface BannerContainerProps {
	backgroundKey: string;
	cursor?: string;
}

export const BannerContainer = styled.div<BannerContainerProps>`
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: ${props => props.theme.colors[props.backgroundKey || 'red_500']};
	cursor: ${props => props.cursor ?? 'auto'};
	z-index: 90;
`;

export const BannerContent = styled.div`
	display: flex;
	width: 100%;
	gap: 8px;
	align-items: center;
`;

export const CloseButton = styled.button`
	background: none;
	border: none;
	color: #fff;
	font-size: 20px;
	cursor: pointer;
`;

