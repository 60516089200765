import React from 'react';
import Grid from '../Grid';
import { ShowContainer } from './styled';
import { ChevronIconButton } from '../';

type Props = {
	title: JSX.Element | JSX.Element[],
	children?: JSX.Element | JSX.Element[],
	preview?: JSX.Element | JSX.Element[],
	bordertop?: boolean,
	margin?: string,
	handleOpen: () => void,
	open: boolean
	hideChevron?: boolean
};

const ExpansionPanelBottom : React.FC<Props> = ({ children, handleOpen, open, title, hideChevron, preview } : Props) => {
	return (
		<>
			<Grid borderbottom={open ? 'gray_300' : false} padding={open ? '0 0 4px 0' : undefined} onClick={handleOpen} cursor='pointer' container justifyContent='space-between' alignItems='center'>
				{title}

				{hideChevron === true ? <></> : <ChevronIconButton onClick={handleOpen} orientation={open ? 'up' : 'down'} />}
			</Grid>

			{preview}

			<ShowContainer isShowing={open}>
				{children}
			</ShowContainer>
		</>
	);
};
export default (ExpansionPanelBottom);