import React from 'react';
import {Divider, PanaDividerContainer} from './styled';
type Props = {
  margin?: string,
};
const PanaDivider : React.FC<Props> = ({ margin } : Props) => {
	return (
		<PanaDividerContainer margin={margin}>
			<Divider />

			<div className='imageContainer'>
				<div className='circularContainer'>
					O
				</div>
			</div>
		</PanaDividerContainer>
	);
};
export default (PanaDivider);