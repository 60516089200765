import React from 'react';
import { Button, Grid, TextField, Title } from '~/components';
import { handleEnter } from '~/utils';
import { RegistroContainer } from '../auth/styled';
import { CheckProps } from '../auth/containers/AuthForm';

interface props {
	loading: boolean
	handlenewPassword: (e: React.ChangeEvent<HTMLInputElement>) => void
	newPassword: string
	handleRestablecerContrasenia: () => void
	disabled: boolean
	errorTextField: CheckProps | null
	passwordConfirm: string
	handlePasswordConfirm: (e: React.ChangeEvent<HTMLInputElement>) => void
	passwordCheck: CheckProps
	passwordConfirmCheck: CheckProps & { disabled: boolean }
}

const ReestablecerContrasenia = ({
	disabled,
	errorTextField,
	handlePasswordConfirm,
	handleRestablecerContrasenia,
	handlenewPassword,
	loading,
	newPassword,
	passwordCheck,
	passwordConfirm,
	passwordConfirmCheck,
}: props) => {
	const enterProps = {
		onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>)=>handleEnter(e, handleRestablecerContrasenia, !disabled)
	};

	return (
		<>
			{/* {xsCarrito && <PanaShow></PanaShow>} */}

			<form onSubmit={e => e.preventDefault()} autoComplete="off">
				<RegistroContainer>
					<Grid direction='column' justifyContent='center' container width='100%' maxwidth='345px' spacing='16px'>
						<Title variant='h5'>Restablecer contraseña</Title>

						<TextField 
							id="new_pass" 
							autoCompleteOff 
							{...passwordCheck} 
							{...enterProps} 
							{...errorTextField} 
							disabled={loading} 
							value={newPassword} 
							onChange={handlenewPassword} 
							label='Nueva contraseña' 
							type="password" 
							width='100%'
							fullWidth
						/>

						<TextField 
							{...passwordConfirmCheck} 
							{...errorTextField} 
							{...enterProps} 
							type="password" 
							disabled={loading} 
							value={passwordConfirm} 
							onChange={handlePasswordConfirm} 
							label='Confirmar contraseña' 
							width='100%'
							fullWidth
						/>

						<Grid container justifyContent='center'>
							<Button loading={loading} onClick={handleRestablecerContrasenia} disabled={loading || disabled} width="100%" variant='solid'>
								Continuar
							</Button>
						</Grid>
					</Grid>                       
				</RegistroContainer>
			</form>
		</>
	);
};
export default (ReestablecerContrasenia);