import { isValidElement } from 'react';
import CheckBox from '../CheckBox';
import { SelectorContaier } from './styled';
import Grid from '../Grid';
import Typography from '../Typography';

interface SelectorProps {
	isSelected: boolean;
	handleChange?: (isSelected: boolean) => void;
	disabled?: boolean;
	type: 'radio' | 'checkbox';
	color?: 'default' | 'green'
	children?: React.ReactNode | string;
}

export const Selector = ({ 
	handleChange, 
	isSelected, 
	type, 
	disabled,
	children,
	color = 'default',
} : SelectorProps) => {
	const handleClick = () => {
		if (disabled === true) return;
		handleChange?.(!isSelected);
	};

	return (
		<SelectorContaier color={color} checked={isSelected} disabled={disabled} onClick={handleClick}>
			{isValidElement(children) ? (
				children
			) : (
				<Grid container alignItems='center' padding='8px 0'>
					<Typography variant='subtitle1' color={disabled ? 'gray_300' : 'gray_900'}>{children}</Typography>
				</Grid>
			)}

			<CheckBox checkedColor={color === 'green' ? 'green_500' : undefined} disabled={disabled} radio={type === 'radio'} checked={isSelected} onClick={handleClick} />
		</SelectorContaier>
	);
};