import React from 'react';
import Button, { ButtonProps } from '../Button';
import { PayButtonContainer } from '../GeneralLayout/styled';
import Grid from '../Grid';
type Props = {
  children: JSX.Element | JSX.Element[] | string,
  onClick?: ()=>void,
  disabled?: boolean,
  loading?: boolean,
  minWidth?: string,
  variant?: ButtonProps['variant'],
  color?: ButtonProps['color'],
  size?: ButtonProps['size'],
  bottom?: string,
  finalHeight?: string
};
const FloatButton : React.FC<Props> = ({ onClick, disabled, loading, minWidth, children, bottom, finalHeight, variant = 'solid', color = 'black', size = 'lg' } : Props) => {
	return (
		<>
			<PayButtonContainer bottom={bottom}>
				<Button onClick={(!disabled && !loading && onClick) ? ()=>onClick() : undefined} minWidth={minWidth} variant={variant} color={color} size={size}>
					{children}
				</Button>
			</PayButtonContainer>
			{
				finalHeight && <Grid container height={finalHeight}></Grid>
			}
		</>
		
	);
};
export default (FloatButton);