import React from 'react';
import { Button, Grid, MenuItemChevron, Typography } from '~/components';
import { CarritoTypes } from '~/interfaces';
import { pricePrettify } from '~/utils';

type Props = {
    item: CarritoTypes,
	onClick: (value: CarritoTypes) => void
	disabled?: boolean
	handleRemove?: (item: CarritoTypes) => void
	loadingRemove?: boolean
};

const ProductCard : React.FC<Props> = ({ item, onClick, disabled, handleRemove, loadingRemove } : Props) => {
	return (
		<Grid container direction='column' borderbottom padding='8px 0' spacing='8px' onClick={disabled ? undefined : ()=>onClick(item)}>
			<MenuItemChevron disabled={disabled} onClick={disabled ? undefined : ()=>onClick(item)}>
				<>
					<Typography variant='subtitle2bold'>
						{`${item.cDescripcion} (x${item.nCantidad})`}
					</Typography>

					{
						Array.isArray(item.jExtras) ? item.jExtras.map((extra, i) => <Grid padding='0 5px 0 0' key={i}>
							<Typography variant='regular' className='noselect'>
								{`${extra.cDescripcion} ${extra.nCantidad? `(x${extra.nCantidad})` : ''}`}
							</Typography>
						</Grid>) : <></>
					}
				</>
			</MenuItemChevron>

			<Grid justifyContent={handleRemove && !disabled ? 'space-between' : 'flex-end'} alignItems='center' container>
				{
					handleRemove && !disabled && <Button onClick={() => handleRemove(item)} loading={loadingRemove} variant='underline' color='warning' size='sm' padding='0px'>Eliminar</Button>
				}

				<Typography variant='body3'>
					{pricePrettify(item.nTotal)}
				</Typography>
			</Grid>
		</Grid>
	);
};
export default (ProductCard);