import React from 'react';
import { LoadingRing, RipplesButtons } from '..';
import ButtonStyled from './styled';

export type ButtonProps = {
  children: JSX.Element | JSX.Element[] | string,
  leftIcon?: JSX.Element | JSX.Element[] | string,
  RightIcon?: JSX.Element | JSX.Element[] | string,
  onClick?: (ev?: React.MouseEvent<HTMLDivElement>) => void,
  margin?: string,
  minWidth?: string,
  width?: string,
  disabled?: boolean,
  circular?: boolean,
  padding?: string,
  spacing?: string,
  className?: string,
  tabIndex?: number,
  loading?: boolean,
  loadingColor?: string,
  fullWidth?: boolean,
  fontSize?: string,
  size?: 'lg' | 'md' | 'sm',
  height?: string,
  variant?: 'solid' | 'outline' | 'underline' | 'light',
  color?: 'black' | 'warning'
  type?: 'button' | 'submit' | 'reset' | undefined,
  ripplesButtonStyle?: React.CSSProperties,
};

const Button : React.FC<ButtonProps> = ({ onClick, ripplesButtonStyle, children, fontSize, minWidth, loadingColor, margin, width, height, fullWidth, variant = 'solid', color = 'black', disabled, circular, padding, leftIcon, RightIcon, spacing, className, loading, type, size = 'lg', tabIndex } : ButtonProps) => {

	return (
		<RipplesButtons style={{
			borderRadius: variant === 'underline' ? '0px' : size === 'sm' ? '12px' : '16px',
			...ripplesButtonStyle
		}} className={className} circular={circular} disabled={disabled || loading} minWidth={minWidth} margin={margin} width={fullWidth ? '100%' : width} onClick={(!disabled && !loading && onClick) ? (e)=>onClick(e) : undefined}>
			<ButtonStyled tabIndex={tabIndex} fontSize={fontSize} type={type} className={className} height={height} size={size} spacing={leftIcon && !spacing ? '5px': spacing} padding={loading ? '0px' : padding} circular={circular} disabled={disabled} color={color} variant={variant}>
				{
					loading ? <LoadingRing color={loadingColor ? loadingColor : variant?.includes('underline') ? 'primary' : 'white'} /> : <>
						{leftIcon}<span>{children}</span>{RightIcon}</>
				}
			</ButtonStyled>
		</RipplesButtons>
	);
};
export default (Button);