import React, { isValidElement } from 'react';
import { NewMenuIcon, ShoppingIcon } from '~/assets/icons';
import { useDialog } from '~/hooks';
import Grid from '../Grid';
import { AppbarContainer, CardMedia, CardMediaContainer, ContadorCarrito } from './styled';
import Logo from '../../assets/images/pana_panama.webp';

import IconButton from '../IconButton';
import { DataCarritoTypes, UserInfoType } from '~/interfaces';
import { NavigateFunction } from 'react-router-dom';
import { PATHS } from '~/constants/paths';
import TextFieldSearchMain from '../TextFieldSearchMain';
import Button from '../Button';
import BackButton from '../BackButton';
import Title from '../Title';
import { Modal } from '../TextFieldSearchMain/styled';
import Typography from '../Typography';

type Props = {
	userInfo?: UserInfoType | null,
	navigate: NavigateFunction,
	handleOpenSideBar: VoidFunction,
	dataCarrito: undefined | DataCarritoTypes[],
	handleOpenCarrito: () => void,
	handleCloseSideBar: VoidFunction,
	showBack: string | null,
	backButtonContent: string,
	currentCarrito: DataCarritoTypes | undefined,
	handleGoBack: VoidFunction,
	title: string | JSX.Element,
	showSideBarIcon: boolean,
	xs: boolean,
	xsCarrito: boolean,
	responsiveHeight: string | null | boolean,
	nivelesTiendaId: number,
	onlyTextfield: boolean,
	setMainValue: React.Dispatch<React.SetStateAction<string>>
};

const TopBar : React.FC<Props> = ({ 
	xs,
	xsCarrito, 
	navigate, 
	handleOpenSideBar, 
	dataCarrito, 
	handleOpenCarrito, 
	handleCloseSideBar, 
	showBack, 
	showSideBarIcon, 
	backButtonContent, 
	handleGoBack, 
	currentCarrito, 
	title, 
	responsiveHeight, 
	nivelesTiendaId, 
	onlyTextfield, 
	setMainValue, 
}) => {
	const {
		open: openSearch,
		handleShow: handleShowSearch,
		handleClose: handleCloseSearch
	} = useDialog();

	const cantidadCarrito = currentCarrito?.nTotalCantidad ? currentCarrito.nTotalCantidad : dataCarrito?.length === 1 ? dataCarrito[0].nTotalCantidad : dataCarrito?.length || 0;

	const searchComponent = (
		<Grid zindex={2} item flex >
			<TextFieldSearchMain nivelesTiendaId={nivelesTiendaId} onlyTextfield={onlyTextfield} xs={xs} handleCloseSearch={handleCloseSearch} open={openSearch} handleShow={handleShowSearch}></TextFieldSearchMain>
		</Grid>
	);

	// const showBack = true;
	// const showSideBarIcon = true;

	const handleClickMenu = ()=> {
		handleOpenSideBar();
		handleCloseSearch();
	};

	const hamburgerButton = (
		<Grid item height='32px'>
			<IconButton size='32px' onClick={handleClickMenu} padding='0px' containerPadding='0px' style={{ maxHeight: '32px' }}>
				<NewMenuIcon height='32px' width='32px' />
			</IconButton>
		</Grid>
	);

	const handleClickCarrito = () => {
		handleOpenCarrito();
		handleCloseSearch();
	};

	const miniCarrito = (
		<Grid className='noselect' position="relative" item height='32px' width='33px'>
			<IconButton onClick={handleClickCarrito} size='32px' padding='0px' containerPadding='0px' style={{ maxHeight: '32px' }}>
				<ShoppingIcon color='gray_900' />
			</IconButton>

			<ContadorCarrito>
				<Typography className='noselect' variant='mini'>
					{cantidadCarrito}
				</Typography>
			</ContadorCarrito>
		</Grid>
	);

	return (
		<nav>
			<Modal onClick={handleCloseSearch} open={openSearch} />
			<AppbarContainer openSearch={openSearch} responsiveHeight={responsiveHeight} showBack={showBack} showSideBarIcon={showSideBarIcon}>
				<Grid flexWrap="nowrap" width='100%' height={xs || showBack ? '100%' : '72px'} padding={xs ? '18px 32px' : '8px 16px'} spacing='24px' container alignItems='center' justifyContent={showBack ? 'space-between' : undefined} className='top'>
					{
						showBack ? (
							<>
								<Grid direction='row' flex item container spacing='16px'>
									{
										showSideBarIcon && xs && hamburgerButton
									}

									<BackButton xs={xs} onClick={() => handleGoBack()} title={backButtonContent}></BackButton>
								</Grid>

								<Grid container flex justifyContent="center" textAlign='center' alignItems={xs || showBack ? 'center' : 'flex-end'} >
									{isValidElement(title) ? title : (<Title variant={xs ? 'h10' : 'h7'}>{title}</Title>)}
								</Grid>

								<Grid item flex />
							</>
						) : xs ? (
							<>
								<Grid container spacing='24px'>
									{!onlyTextfield && (hamburgerButton)}

									{
										!onlyTextfield && (
											<CardMediaContainer 
												onClick={()=>{
													navigate(PATHS.main);
													handleCloseSearch();
													handleCloseSideBar();
												}}
											>
												<CardMedia 
													src={Logo}
													height="32"
													alt = "Logo Panamá"
												/>
											</CardMediaContainer>
										)
									}
								</Grid>

								{searchComponent}
								
								{
									xsCarrito ? (
										<Grid minwidth='127px' justifyContent='flex-end' height='32px' container item>
											<Button 
												onClick={handleClickCarrito}
												padding='0px'
												spacing='8px'
												color='black'
												height='auto'
												variant='light'
												leftIcon={<Grid container justifyContent='center' alignItems='center' padding='0px 4px'><ShoppingIcon height='24px' width='24px' color='gray_900' /></Grid>}
											>
												{`Carrito${dataCarrito && dataCarrito?.length > 1 ? 's' : ''} (${cantidadCarrito})`}
											</Button>
										</Grid>
									) : (onlyTextfield) ? null : (miniCarrito)
								}
							</>
						) : (
							<Grid container justifyContent='center' alignItems='center' spacing='16px' flexWrap='nowrap' item xs>
								{onlyTextfield ? (
									<BackButton onlyTextfield={onlyTextfield} xs={xs} onClick={()=>{navigate('/'); setMainValue('');}} title={backButtonContent}></BackButton>
								) : hamburgerButton}

								<Grid container flex item>
									{searchComponent}
								</Grid>

								{miniCarrito}
							</Grid>
						)
					}
				</Grid>
			</AppbarContainer>
		</nav>
	);
};
export default (TopBar);