import styled, { css } from 'styled-components';
import IconButtonMUI from '../../IconButton';
import Grid from '~/components/Grid';
const colorPrimario = 'red_500';
export const Avatar = styled.div`
    width: 34px !important;
    height: 34px !important;
    border: none !important;
    background-color: ${colorPrimario} !important; 
`;
export const IconButton = styled(IconButtonMUI)`
    padding: 0 !important;

`;

interface RowListProps {
    justifyContent?: string;
    spacing?: string;
    columns?: number;
    margin?: string;
}

export const RowList = styled.div<RowListProps>`
    display: grid;
    grid-gap: ${props => props.spacing || '0'};
    ${props => props.columns ? `grid-template-columns: repeat(${props.columns}, 1fr);` : ''}
    justify-content: ${props => props.justifyContent || 'center'};
    margin: ${props => props.margin || '0'};
`;
export const ColList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex: 1;
    min-height: 35px;
`;
export const Div = styled.div`
    width: 100%;
    border: none !important;
    padding-left: 9px;
    padding-right: 9px;

`;
export interface IButtonDay {
    disabled?: boolean;
    isSelected?: boolean;
}

export const ButtonDay = styled.button<IButtonDay>`
    background-color: transparent;
    font-weight: ${props =>props.theme.text.weight.medium};
    font-size: ${props =>props.theme.text.size.subtitle1};
    font-family: ${props =>props.theme.text.font.primary};
    height: 100%;
    border: none;
    justify-content: center;
    max-width: fit-content;
    min-height: 35px;
    min-width: 32px;
    justify-self: center;

    ${props => props.disabled ? css`
        color: ${props.theme.colors.disabledGreyColor};
    ` : props.isSelected ? css`
        cursor: pointer;
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.primary};
        border-radius: 20%;
    ` : css`    
        cursor: pointer;
        color: ${props.theme.colors.black};
    `}
`;

export const Circle = styled.div`
    border-radius: 50%;
    margin-left: 1px;
    margin-top: 17px;
    position: absolute !important;
    padding: 4px;
    border: none !important;
`;
export const ContainerCard = styled.div`
    background-color: ${props =>props.theme.colors.white};
    border: 1px solid #EDEDED;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
    min-width: 250px;
    padding: 10px;
`;
export const List = styled(Grid)`
    margin-top: 15px;
    margin-bottom: 5px;
`;


/* export const CalendarGrid = styled.div`
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`; */

//repeat(auto-fill, minmax(100px, 1fr))