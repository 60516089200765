import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';
import { ISubCategorias, InivelTiendaDetallesType } from '~/interfaces';
import _ from 'lodash';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';

type props = {
	handleClick: (id: number, nivelId?: number | undefined) => void
}

const useTienda = ({
	handleClick
}: props) => {

	const params = useParams();
	const nNivelTienda = params.nNivelTienda;
	const categoriaIdParams = params.categoriaId;
	const nVProducto = parseInt(params.nVProducto || '');

	const {
		handleDoneInitialLoading,
		headersApi
	} = useContext(CookiesContext);

	const {
		handleResetShowBack,
	} = useContext(GeneralLayoutContext);

	// Llena el carrousel de categorías en base al tipo pedido
	const [nivelTiendaDetalles, setNivelTiendaDetalles] = useState<InivelTiendaDetallesType | undefined>(undefined);

	const [activeTab, setActiveTab] = useState(categoriaIdParams ? parseInt(categoriaIdParams) : 0);
	const handleActiveTab = (value: number) => {
		setActiveTab(value);
	};

	const [loading, setLoading] = useState(true);
	const apiUrl = import.meta.env.VITE_API_URL;
	
	useEffect(() => {
		setLoading(true);
		const body = {
			nNivelTienda: nNivelTienda,
			nCategoria: activeTab
		};
		fetch(`${apiUrl}/APPWEB/ObtenerNivelTiendaDetalles`, {
			body: JSON.stringify(body),
			method: 'POST',
			headers: headersApi
		}).then((res) => res.json()).then((responseCat) => {

			if (responseCat?.Result) {
				setNivelTiendaDetalles(responseCat.Result);
				handleDoneInitialLoading();
				setLoading(false);
			}

		}).catch((error) => {
			setLoading(false);
			console.error(error, 'error');
		});
	}, [headersApi?.Authorization, activeTab]);

	useEffect(() => {
		setActiveTab(categoriaIdParams ? parseInt(categoriaIdParams) : 0);
	}, [categoriaIdParams]);

	const bAnticipacion = !!(_.find(nivelTiendaDetalles?.dataSubCategorias?.subCategorias || [], (o: ISubCategorias)=>o.nCategoria === activeTab)?.bAnticipacion);
	useEffect(() => {
		handleResetShowBack();

		if (!isNaN(nVProducto) && !isNaN(parseInt(nNivelTienda || ''))) {
			handleClick(nVProducto, parseInt(nNivelTienda || ''));
		}
	}, []);

	return {
		activeTab,
		handleActiveTab,
		nNivelTienda,
		loading,
		bAnticipacion,
		nivelTiendaDetalles,
		nVProducto
	};
};

export default useTienda;