import styled from 'styled-components';
import { TextFieldProps } from '../TextField/styled';

export interface ProductTextFieldProps {
  disabled?: boolean;
}

export const ProductTextFieldContainer = styled.div<ProductTextFieldProps>`
    display: flex;
    flex-direction: row;
    gap: 16px;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid ${props => props.theme.colors.gray_500};

    border-radius: 22px;
    input[type='number'] {
        -moz-appearance:textfield;
    }

    height: 48px;
    padding: 11px 16px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    img {
      width: 15px;
      height: 15px;
    }

    .hide {
      background-color: transparent !important;
      border: none !important;
      ${props => props.disabled ? '' : 'cursor: pointer;'}
    }
`;
export const TextFieldContainerInput = styled.div<TextFieldProps>`
    // display: flex;
    font-family: ${props => props.theme.text.font.primary};
    
    background-color: ${props => props.theme.colors.white};
    max-width: 50px;
    max-height: 24px;
    overflow: hidden;
`;

export const TextFieldInput = styled.input<TextFieldProps>`
    border: none !important;
    max-width: 3ch;
    padding-block: 0px;
    padding-inline: 0px;
    padding: 0px;
    overflow: hidden;
    
    font-family: ${props =>props.theme.text.font.primary};
    text-align: center;
    font-weight: ${props => props.theme.text.weight.semiBold};
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    vertical-align: middle;
    max-height: 24px;

    &:focus {
      outline: none;
    }
`;
